/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-03-25 17:35:01.

export class BankTransactionEventSearch {
    portfolioId: string;
    valueDateFrom: string;
    valueDateTo: string;
    eventDateFrom: string;
    eventDateTO: string;
    accountId: string;
    orderSide: OrderSide;
    status: BankTransactionEventStatus;
}

export class AbstractSearchRequest {
    assetClassList: AssetClass[];
    searchQuery: string;
}

export class ClientSearchRequest extends AbstractSearchRequest {
    clientTypeId: string;
    clientSubTypeId: string;
    department: Department;
    date: string;
}

export class DateRangeSearchRequest {
    startDate: string;
    endDate: string;
}

export class AccountDTO {
    id: string;
    name: string;
    generatedCode: string;
    description: string;
    subTypeId: string;
    subTypeName: string;
    typeId: string;
    typeName: string;
    contactPersonName: string;
    contactNumber: string;
    bankName: string;
    bankAccountName: string;
    bankAccountNumber: string;
    bankBranchCode: string;
    bankSwiftCode: string;
    currencyName: string;
    currencyCode: string;
    balance: string;
    glType: boolean;
    allowIntegration: boolean;
}

export class AnalyticsSourceConfigDTO {
    label: string;
    key: string;
    chartTypes: ChartType[];
    type: string;
}

export class AppInfoDTO {
    username: string;
    branchName: string;
    userId: string;
    roles: AuthRole[];
    appVersion: string;
    avatar: string;
    unreadCount: number;
}

export class AppliedTransactionCostDTO {
    transactionCost: string;
    transactionCostName: string;
    rateType: RateType;
    appliedValue: string;
}

export class BaseLedgerDTO {
    portfolioName: string;
    accountId: string;
    accountName: string;
    accountType: string;
    accountSubType: string;
    transactionType: string;
}

export class BenchmarkMetricsDTO {
    beta: string;
    alpha: string;
    annualizedAlpha: string;
    trackingError: string;
    treynorRatio: string;
    jensenAlpha: string;
    activePremium: string;
    informationRatio: string;
    upCapture: string;
    downCapture: string;
    upNumberRatio: string;
    downNumberRatio: string;
    upPercentageRatio: string;
    downPercentageRatio: string;
    percentGainRatio: string;
    valueAtRisk: string;
    expectedTailLoss: string;
}

export class BondWeightedAverages {
    yieldToMaturityHolding: string;
    yieldToMaturityMarket: string;
    profitLossRatio: string;
    duration: string;
    maturityDays: string;
    shermanRatio: string;
    mduration: string;
}

export class ChartOfAccountDTO {
    chartOfAccountKeyDTO: ChartOfAccountKeyDTO;
    dataList: ChartOfAccountDetailDTO[];
}

export class ChartOfAccountDetailDTO {
    accountName: string;
    date: string;
    description: string;
    debitAmount: string;
    creditAmount: string;
    openingBalance: string;
    closingBalance: string;
    currency: string;
    glType: boolean;
}

export class ChartOfAccountKeyDTO {
    chartOfAccountId: string;
    chartOfAccountType: string;
    currency: string;
    fromDate: string;
    toDate: string;
    openingBalance: string;
    closingBalance: string;
}

export class ClientUnitHolderDTO {
    portfolio: Portfolio;
    client: Client;
    totalInvested: string;
    totalWithdrawal: string;
    realisedPnL: string;
    openingPosition: Position;
    closingPosition: Position;
    transactions: Transaction[];
}

export class CouponScheduleChartResponse {
    nextCouponInterest: string;
    month: string;
    year: number;
}

export class DashboardConfigDTO {
    dashboardId: string;
    portfolioId: string;
    widgetList: WidgetDTO[];
}

export class EmailDTO {
    body: string;
    subject: string;
    header: string;
    ccEmailIdList: string[];
    bccEmailIdList: string[];
}

export class EmailLogSearchRequest extends DateRangeSearchRequest {
    searchQuery: string;
    emailStatus: EmailStatus;
}

export class FileAttachmentDTO {
    attachmentBytes?: any;
    fileName: string;
    fileContentType: string;
    inline: boolean;
}

export class LedgerBalanceDTO extends BaseLedgerDTO {
    balance: string;
}

export class LedgerEntryDTO extends BaseLedgerDTO {
    date: string;
    counterParty: string;
    description: string;
    debitAmount: string;
    creditAmount: string;
    openingBalance: string;
    closingBalance: string;
    glType: boolean;
}

export class MarketQuoteImportId {
    securityId: string;
    timestamp: string;
    source: string;
}

export class MissingMarketQuoteDTO {
    securityId: string;
    assetClass: AssetClass;
    marketQuoteDate: string;
}

export class ModifiedEmailDTO {
    emailDTO: EmailDTO;
    unitHolderReportSearchRequest: UnitHolderReportSearchRequest;
}

export class PerformanceMetricsDTO {
    ytdProfitLossPercentage: string;
    roi1monthPercentage: string;
    roi2MonthPercentage: string;
    roi3monthPercentage: string;
    roi6monthPercentage: string;
    roi1yearPercentage: string;
    roi2yearPercentage: string;
    averageReturn: string;
    averageGain: string;
    averageLoss: string;
    compoundAverageReturn: string;
    inception: string;
}

export class PortfolioIncomeDTO {
    id: string;
    portfolioId: string;
    name: string;
    accrueFrequency: Frequency;
    startDate: string;
    systemGenerated: boolean;
    enabled: boolean;
    archived: boolean;
}

export class PortfolioLiabilityDTO {
    id: string;
    portfolioId: string;
    name: string;
    liabilityType: RateType;
    value: string;
    liabilityColumn: LiabilityColumn;
    accrueFrequency: Frequency;
    accrueDate: string;
    paymentFrequency: Frequency;
    paymentDate: string;
    dayCountBasis: DayCountBasis;
    startDate: string;
    systemGenerated: boolean;
    enabled: boolean;
    archived: boolean;
}

export class PortfolioLockConfirmationDTO {
    lockable: boolean;
    errorMessage: string;
    transactionTypeWiseBalance: Pair<TransactionType, string>[];
    portfolioSummary: PortfolioSummary;
    affectingPortfolios: Portfolio[];
    missingMarketQuoteSecurity: MissingMarketQuoteDTO[];
}

export class PortfolioPayableDTO {
    paymentDate: string;
    date: string;
    portfolioLiabilityName: string;
    description: string;
    subType: string;
    portfolioLiabilityId: string;
    currency: Currency;
    accruedAmount: string;
    paidAmount: string;
    remainingAmount: string;
    liabilityArchived: boolean;
}

export class PortfolioReceivableDTO {
    paymentDate: string;
    date: string;
    portfolioIncomeName: string;
    subType: string;
    description: string;
    portfolioIncomeId: string;
    currency: Currency;
    accruedAmount: string;
    paidAmount: string;
    remainingAmount: string;
    archived: boolean;
}

export class PortfolioSummaryDTO {
    portfolioId: string;
    portfolioName: string;
    portfolioCode: number;
    date: string;
    aum: string;
    nav: string;
    profitLoss: string;
    positionBalance: string;
    ledgerBalance: string;
    portfolioStartLedgerBalance: string;
    ytdProfitLossPercentage: string;
    annualizedReturnPercentage: string;
    cumulativeAnnualizedReturnPercentage: string;
    cumulativeReturnPercentage: string;
    liabilities: string;
    receivables: string;
    totalCash: string;
    totalCost: string;
    units: string;
    unitPrice: string;
    portfolioSummaryStatus: PortfolioSummaryStatus;
    lockStatus: LockStatus;
    difference: PortfolioSummaryDTO;
    summaryFXQuoteValue: string;
}

export class PortfolioSummaryResponse {
    portfolioSummary: PortfolioSummary;
    assetDistribution: { [P in AssetClass]?: string };
}

export class PortfolioUnitHolderResultDTO {
    id: string;
    portfolioId: string;
    name: string;
    clientName: string;
    clientType: string;
    clientId: string;
    quantity: string;
    avgPrice: string;
    totalCost: string;
    marketPrice: string;
    currentValue: string;
    profitLoss: string;
    profitLossRatio: string;
    portfolioCode: number;
    portfolioName: string;
}

export class PositionSearchRequest extends AbstractSearchRequest {
    selectedDate: string;
    fromPrice: string;
    toPrice: string;
    fromYield: string;
    toYield: string;
    currencyCode: string;
}

export class ProductSearchRequest extends AbstractSearchRequest {
    maturityDate: string;
    issueDate: string;
}

export class REPOFieldCalculationDTO implements Serializable {
    maturityDate: string;
    issueDate: string;
    underlyingAsset: string;
    yield: string;
    sellYield: string;
    repoType: RepoType;
    returnRate: string;
    dayCountBasis: DayCountBasis;
}

export class BaseError {
    error: boolean;
    errorMessage: { [index: string]: string };
    errorRawString: { [index: string]: string };
}

export class RepoTradeDTO extends BaseError implements DefaultHandleBaseError {
    underlyingAsset: string;
    issueDate: string;
    maturityDate: string;
    quantity: string;
    purchaseYield: string;
    sellYield: string;
    counterParty: string;
    executingAccount: string;
    dayCountBasis: DayCountBasis;
    repoType: RepoType;
    transactionId: string;
    applyCSDFee: boolean;
    returnRate: string;
    purchaseConsideration: string;
    sellConsideration: string;
    purchaseDirtyPricePercentage: string;
    sellDirtyPricePercentage: string;
    purchaseCleanPricePercentage: string;
    sellCleanPricePercentage: string;
    underlyingAssetSecurityId: string;
    isin: string;
    name: string;
}

export class RiskMetricsDTO {
    standardDeviation: string;
    gainStandardDeviation: string;
    lossStandardDeviation: string;
    downsideDeviation: string;
    semiDeviation: string;
    sharpeRatio: string;
    sortinoRatio: string;
    calmarRatio: string;
    sterlingRatio: string;
    gainToLossRatio: string;
    skewness: string;
    kurtosis: string;
    profitToLossRatio: string;
    maxDrawdown: string;
    duration: string;
    shermanRatio: string;
}

export class SearchResult {
    id: string;
    name: string;
    subHeader: string;
    searchResultType: SearchResultType;
}

export class SecurityDTO extends BaseError implements Serializable, DefaultHandleBaseError {
    securityUUID: string;
    isin: string;
    name: string;
    securityId: string;
    securityLongId: string;
    assetClass: AssetClass;
    issuer: string;
    issueDate: string;
    maturityDate: string;
    coupon: string;
    baseCurrency: string;
    targetCurrency: string;
    fundManager: string;
    symbol: string;
    companyName: string;
    tenor: string;
    returnRate: string;
    underlyingAsset: string;
    underlyingAssetUUID: string;
    yield: string;
    country: string;
    market: string;
    fixedIncomeClass: string;
    issueType: string;
    issueAmount: string;
    issuerIndustry: string;
    announcementDate: string;
    couponType: string;
    annualCouponFrequency: number;
    firstCouponDate: string;
    sellYield: string;
    repoType: RepoType;
    dayCountBasis: DayCountBasis;
    couponDateBasis: CouponDateBasis;
    fundHouse: string;
    marketIssue: string;
    issueYield: string;
    calculationType: string;
    pricingDate: string;
    interestAccrualDate: string;
    firstSettleDate: string;
    series: string;
    issuePrice: string;
    exchange: string;
    bookRunner: string;
    idNumber: string;
    figi: string;
    issuerShortName: string;
    securitySmallName: string;
    marketCapitalCategory: MarketCapitalCategory;
    stockStyle: StockStyle;
    instrumentSector: InstrumentSector;
    bondSector: BondSector;
    creditRating: CreditRating;
    initialCapital: string;
    returnType: IndexReturnType;
    distributionType: IndexDistributionType;
}

export class SecurityDeleteResponse {
    deletedSecurities: Security[];
    securitiesAssignedToTransaction: Security[];
    securitiesAssignedToPosition: Security[];
    securitiesAssignedToOtherSecurity: Security[];
    securitiesAssignedAsBenchmark: Security[];
}

export class SettlementInstructionReportDTO {
    tradeType: TradeType;
    discountRate: string;
    tenor: string;
    portfolio: Portfolio;
    executingAccount: Account;
    toAccount: Account;
    orderSide: OrderSide;
    assetClass: AssetClass;
    refTransaction: Transaction;
    parentTransactionRef: Transaction;
    calculationBase: CalculationBase;
    csdTransaction: Transaction;
    entryExitLoadTransaction: Transaction;
    security: Security;
    portfolioLiability: PortfolioLiability;
    portfolioIncome: PortfolioIncome;
    quantity: string;
    price: string;
    considerationWithCurrency: string;
    consideration: string;
    fxRate: string;
    fxConversionBaseCurrency: Currency;
    yield: string;
    valueDate: string;
    counterParty: CounterParty;
    rootTransaction: boolean;
    glType: boolean;
    transactionType: TransactionType;
    systemGenerated: boolean;
    transactionStatus: ExecutionStatus;
    authStatus: TransactionAuthStatus;
    corporateAction: CorporateAction;
    oldTransactionId: string;
    adjustedYieldToMaturity: string;
    noOfDaysBetweenIssueAndMaturity: number;
    effectiveIssueDate: string;
    daysToMaturity: number;
    noOfHalfYearsBetweenDealDateAndEffectiveDate: number;
    nextInterestPaymentDate: string;
    noOfHalfYearsBetweenDealDateAndNextInterestPaymentDate: string;
    noOfHalfYearsBetweenNextInterestPaymentDateAndMaturityDate: string;
    onePlusU: string;
    dc: string;
    dp: string;
    accruedInterest: string;
    systemCleanPrice: string;
    systemDirtyPrice: string;
    cleanPrice: string;
    dirtyPricePercentage: string;
    cleanPricePercentage: string;
    csdFee: string;
    applyCSDFee: boolean;
    description: string;
    transactionExecuted: boolean;
    principalAmount: string;
    unitPayoutPortfolio: Portfolio;
    unitPayoutQuantity: string;
    unitPayoutPrice: string;
    entryExitLoadPercentage: string;
    addContributionInClient: boolean;
    appliedTransactionCost: AppliedTransactionCost;
    allAppliedTransactionCost: AppliedTransactionCost[];
    transactionCostTransactions: Transaction[];
    executionErrorMessage: string;
    securityChangeReprocessTransaction: boolean;
    error: boolean;
    errorMessage: { [index: string]: string };
    errorRawString: { [index: string]: string };
}

export class StructuredTradeDTO extends BaseError implements DefaultHandleBaseError {
    underlyingAsset: string;
    bookDate: string;
    purchaseDate: string;
    sellDate: string;
    quantity: string;
    purchaseYield: string;
    sellYield: string;
    fundingCostPercentage: string;
    profitSharingPercentage: string;
    purchaseCSDPayer: CSDPayer;
    sellCSDPayer: CSDPayer;
    counterParty: string;
    executingAccount: string;
    dayCountBasis: DayCountBasis;
    transactionId: string;
    portfolioId: string;
    counterPartyName: string;
    underlyingAssetSecurityId: string;
    underlyingAssetIsin: string;
    executingAccountName: string;
    portfolioName: string;
    baseCurrencyCode: string;
    purchaseConsideration: string;
    sellConsideration: string;
    couponPayment: string;
    purchaseCSDFee: string;
    sellCSDFee: string;
    grossSpread: string;
    fundingCost: string;
    netSpread: string;
    counterPartyShare: string;
    selfShare: string;
    counterPartyCSDFee: string;
    selfCSDFee: string;
    counterPartyPayout: string;
    selfPayout: string;
    profitLoss: string;
    annualizedPerformance: string;
    ytdPerformance: string;
}

export class TableSettingsDTO {
    columnSettingsList: ColumnSettings[];
}

export class TopHoldingSearchRequest {
    selectedDate: string;
    assetClass: AssetClass;
    limit: number;
}

export class TradeRequest implements DefaultHandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
    portfolioId: string;
    portfolioName: string;
    portfolioCode: number;
    securityId: string;
    assetClass: AssetClass;
    name: string;
    isin: string;
    counterPartyId: string;
    counterPartyAlias: string;
    orderSide: OrderSide;
    quantity: string;
    remainingQuantity: string;
    price: string;
    yield: string;
    cleanPricePercentage: string;
    calculationBase: CalculationBase;
    valueDate: string;
    applyCSDFee: boolean;
    executingAccountName: string;
    executingAccountId: string;
    toAccountName: string;
    toAccountId: string;
    fxRate: string;
    fxConversionBaseCurrency: string;
    principalAmount: string;
    transactionId: string;
    transactionTypeName: string;
    transactionTypeId: string;
    description: string;
    portfolioLiabilityId: string;
    portfolioLiabilityName: string;
    portfolioIncomeId: string;
    portfolioIncomeName: string;
    unitPayoutClientId: string;
    unitPayoutClientName: string;
    unitPayoutPortfolioId: string;
    unitPayoutPortfolioName: string;
    unitPayoutQuantity: string;
    allAppliedTransactionCost: AppliedTransactionCostDTO[];
    addContributionInClient: boolean;
    entryExitLoadPercentage: string;
    bankTransactionEventId: string;
}

export class TransactionCostDTO {
    id: string;
    name: string;
    enabled: boolean;
    rateType: RateType;
    description: string;
    applicableAssetClasses: AssetClass[];
    applicableAssetClassesStr: string;
    defaultValue: string;
    changeable: boolean;
    archived: boolean;
}

export class TransactionExportResponse {
    id: string;
    transactionStatus: ExecutionStatus;
    authStatus: TransactionAuthStatus;
    securityId: string;
    assetClass: AssetClass;
    name: string;
    isin: string;
    executingAccount: string;
    counterParty: string;
    orderSide: OrderSide;
    quantity: string;
    price: string;
    consideration: string;
    yield: string;
    valueDate: string;
    accruedInterest: string;
    cleanPrice: string;
    dirtyPricePercentage: string;
    cleanPricePercentage: string;
    csdFee: string;
    principalAmount: string;
    purchasePrice: string;
    purchaseYield: string;
    purchaseCleanPrice: string;
    realisedPnL: string;
}

export class TrialBalanceDTO {
    name: string;
    groupName: string;
    balance: string;
}

export class YearWiseBondResponse {
    purchaseYield: string;
    currentValue: string;
    marketYield: string;
    quantity: string;
    marketValue: string;
    year: number;
}

export class BankTransactionEventDTO {
    id: string;
    portfolioId: string;
    portfolioName: string;
    executingAccountId: string;
    executingAccountName: string;
    valueDate: string;
    eventDate: string;
    orderSide: OrderSide;
    source: string;
    status: BankTransactionEventStatus;
    quantity: string;
    remainingQuantity: string;
    internalMessage: string;
    transactionId: string;
    rawEvent: string;
    rawEventId: string;
}

export class ClientDTO extends BaseError implements DefaultHandleBaseError {
    id: string;
    name: string;
    description: string;
    clientTypeName: string;
    clientTypeId: string;
    clientSubTypeName: string;
    clientSubTypeId: string;
    clientCode: string;
    createPortfolio: boolean;
    createUser: boolean;
    clientAddress: string;
    clientTelephone: string;
    currency: string;
    department: string;
    benchmarkId: string;
    clientEmail: string;
    portfolioList: SearchResult[];
    jotformSubmissionId: string;
}

export class ClientSubTypeDTO {
    id: string;
    name: string;
    description: string;
    clientTypeName: string;
    clientTypeId: string;
}

export class ClientSummaryDTO extends BaseError implements DefaultHandleBaseError {
    id: string;
    name: string;
    nav: string;
    aum: string;
    units: string;
    unitPrice: string;
    description: string;
    clientTypeName: string;
    clientTypeId: string;
    clientSubTypeName: string;
    clientSubTypeId: string;
    clientCode: string;
    createPortfolio: boolean;
    clientAddress: string;
    clientTelephone: string;
    currency: string;
    department: string;
    benchmarkId: string;
    clientEmail: string;
    portfolioList: SearchResult[];
}

export class ClientKYCDTO implements DefaultHandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
    clientCode: string;
    name: string;
    clientDTO: ClientDTO;
    clientKYCAnswerList: ClientKYCAnswer[];
    kycFormDTO: KYCFormDTO;
}

export class KYCConfigDTO {
    id: string;
    name: string;
    description: string;
    version: number;
    assignedClientSubTypes: ClientSubTypeDTO[];
}

export class KYCFormDTO {
    id: string;
    name: string;
    description: string;
    version: number;
    kycFormSections: KYCFormSectionDTO[];
}

export class KYCFormQuestionDTO {
    id: string;
    question: string;
    questionType: QuestionType;
    description: string;
    visibilityOrder: number;
    identifier: string;
    version: number;
    required: boolean;
    kycValue: string;
    optionValues: KYCQuestionOptionDTO[];
}

export class KYCFormSectionDTO {
    id: string;
    name: string;
    description: string;
    visibilityOrder: number;
    repeat: boolean;
    identifier: string;
    version: number;
    maxRepetition: number;
    kycFormSubSections: KYCFormSubSectionDTO[];
}

export class KYCFormSubSectionDTO {
    id: string;
    name: string;
    description: string;
    visibilityOrder: number;
    identifier: string;
    version: number;
    kycFormQuestions: KYCFormQuestionDTO[];
}

export class KYCQuestionConfigDTO {
    id: string;
    question: string;
    questionType: QuestionType;
    description: string;
    visibilityOrder: number;
    visible: boolean;
    mandatory: boolean;
    version: number;
    optionValues: KYCQuestionOptionDTO[];
    kycSubSectionConfigId: string;
}

export class KYCQuestionOptionDTO {
    id: string;
    optionValue: string;
    visibilityOrder: number;
    visible: boolean;
    version: number;
}

export class KYCSampleRecordDTO {
    sectionList: string[];
    sectionIdentifierList: string[];
    subSectionList: string[];
    subSectionIdentifierList: string[];
    questionList: string[];
    questionIdentifierList: string[];
}

export class KYCSectionConfigDTO {
    id: string;
    name: string;
    description: string;
    visibilityOrder: number;
    visible: boolean;
    repeat: boolean;
    maxRepetition: number;
    version: number;
    kycConfigId: string;
}

export class KYCSubSectionConfigDTO {
    id: string;
    name: string;
    description: string;
    visibilityOrder: number;
    visible: boolean;
    version: number;
    kycSectionConfigId: string;
}

export class MarketQuoteDTO implements Serializable {
    marketQuoteId: string;
    isin: string;
    name: string;
    securityId: string;
    assetClass: AssetClass;
    timestamp: string;
    last: string;
    couponRate: string;
    close: string;
    open: string;
    yield: string;
    priceBase: CalculationBase;
    ask: string;
    bid: string;
    source: string;
    marketQuoteStatus: ExecutionStatus;
    systemGenerated: boolean;
    error: boolean;
    errorMessage: { [index: string]: string };
    errorRawString: { [index: string]: string };
}

export class PortfolioDTO {
    id: string;
    name: string;
    accounts: string[];
    currency: string;
    csdNo: string;
    csdFeePercentage: string;
    corporateActionAccounts: string[];
    custodian: string;
    financialYearDate: string;
    address: string;
    telephone: string;
    currencySymbol: string;
    email: string;
    portfolioCode: number;
    corporateTrustee: string;
    status: PortfolioStatus;
    unitPricingStrategy: UnitPricingStrategy;
    valuationStrategy: ValuationStrategy;
    bondCurrentPriceStrategy: BondCurrentPriceStrategy;
    portfolioStartDate: string;
    initialUnits: string;
    initialUnitPrice: string;
    clientId: string;
    instrumentId: string;
    benchmarkId: string;
    clientName: string;
    description: string;
    clientTypeName: string;
    clientTypeId: string;
    clientSubTypeName: string;
    clientSubTypeId: string;
    clientCode: string;
    logoKey: string;
    entryExitLoadPercentage: string;
}

export class ClientPositionResponse {
    securityUUID: string;
    securityId: string;
    portfolioUUID: string;
    portfolioName: string;
    quantity: string;
    marketValueOrCurrentValue: string;
    profitLoss: string;
    difference: ClientPositionResponse;
    children: ClientPositionResponse[];
}

export class PositionExposureResponse {
    exposure: string;
    currentValue: string;
    marketValueOrCurrentValue: string;
    marketValue: string;
    profitLoss: string;
    profitLossRatio: string;
    totalCost: string;
    children: PositionExposureResponse[];
}

export class PositionResponse {
    id: string;
    portfolioUUID: string;
    portfolioName: string;
    securityUUID: string;
    securityId: string;
    assetClass: AssetClass;
    isin: string;
    name: string;
    issuer: string;
    tenor: string;
    maturityDate: string;
    issueDate: string;
    returnRate: string;
    symbol: string;
    underlyingAsset: string;
    repoType: RepoType;
    sellYield: string;
    accountUUID: string;
    account: string;
    transactionTypeUUID: string;
    transactionType: string;
    date: string;
    quantity: string;
    totalCost: string;
    size: number;
    duration: string;
    weight: string;
    shermanRatio: string;
    nextCouponPaymentDate: string;
    nextCouponInterest: string;
    purchaseCleanPricePercentage: string;
    marketCleanPriceConsideration: string;
    avgPrice: string;
    purchaseYield: string;
    principal: string;
    interest: string;
    maturityValue: string;
    accruedInterest: string;
    couponMarketQuoteTimestamp: string;
    couponMarketQuoteId: string;
    marketCouponRate: string;
    fxMarketQuoteLast: string;
    marketCleanPricePercentage: string;
    marketQuoteTimestamp: string;
    fxMarketQuoteDate: string;
    marketYield: string;
    marketPrice: string;
    currentValue: string;
    marketValueOrCurrentValue: string;
    marketValueWithCurrency: string;
    currentValueWithCurrency: string;
    marketValueOrCurrentValueWithCurrency: string;
    marketValue: string;
    profitLoss: string;
    profitLossRatio: string;
    difference: PositionResponse;
    mduration: string;
}

export class SinglePositionSearchRequest {
    securityId: string;
    accountId: string;
    date: string;
}

export class FeeScheduleDTO {
    feeName: string;
    feeScheduleEntryList: FeeScheduleEntry[];
}

export class FeeScheduleEntry {
    transactionDate: string;
    totalNav: string;
    amount: string;
}

export class IncomeScheduleDTO {
    incomeName: string;
    currency: string;
    incomeScheduleEntryDTOList: IncomeScheduleEntryDTO[];
}

export class IncomeScheduleEntryDTO {
    valueDate: string;
    ledgerDebit: string;
    ledgerCredit: string;
    journalNarrative: string;
    reference: string;
    currency: string;
}

export class MemberFundScheduleDetailDTO {
    clientName: string;
    valueDate: string;
    consideration: string;
    portfolioName: string;
    orderSide: OrderSide;
}

export class MemberFundScheduleReportDTO {
    subscriptionList: MemberFundScheduleDetailDTO[];
    redemptionList: MemberFundScheduleDetailDTO[];
    subscription: string;
    redemption: string;
    openingBalance: string;
    closingBalance: string;
}

export class RegulatoryReportDTO {
    assetClass: AssetClass;
    name: string;
    issuer: string;
    isin: string;
    returnRate: string;
    maturityDate: string;
    security: Security;
    account: string;
    actualAllocation: string;
    investmentDate: string;
    reportingDate: string;
    accruedInterest: string;
    faceValue: string;
    principal: string;
    marketValue: string;
    daysToMaturity: number;
    bondType: string;
    permittedAllocation: number;
    accountName: string;
    quantity: string;
    avgPrice: string;
    oldMarketAvgPrice: string;
    marketAvgPrice: string;
    profitLoss: string;
    totalCost: string;
}

export class AssetWiseValuationDTO {
    assetClass: string;
    valuation: string;
    currentValue: string;
    marketValue: string;
    valuationWeightage: string;
}

export class PositionWiseValuationDTO {
    security: Security;
    account: string;
    assetClass: AssetClass;
    date: string;
    quantity: string;
    totalCost: string;
    isin: string;
    securityId: string;
    issuer: string;
    tenor: string;
    name: string;
    avgPrice: string;
    principal: string;
    interest: string;
    maturityValue: string;
    accruedInterest: string;
    maturityDate: string;
    purchaseYield: string;
    couponRate: string;
    marketQuoteTimestamp: string;
    marketPrice: string;
    marketYield: string;
    currentValue: string;
    marketValue: string;
    profitLoss: string;
    profitLossRatio: string;
    valuationWeightage: string;
}

export class RealisedUnRealisedScheduleEntry {
    securityId: string;
    quantity: string;
    assetClass: AssetClass;
    priceOnPurchaseDate: string;
    priceOnDate: string;
    currency: string;
    transactionDate: string;
}

export class RealisedUnRealisedScheduleResponse {
    assetClass: AssetClass;
    currency: string;
    realisedUnRealisedScheduleEntryList: RealisedUnRealisedScheduleEntry[];
}

export class RealisedUnrealisedPositionDto {
    security: Security;
    assetClass: AssetClass;
    quantity: string;
    purchaseCleanPrice: string;
    purchaseDirtyPrice: string;
    purchasePrice: string;
}

export class TransactionCostScheduleDTO {
    costName: string;
    totalValue: string;
    currency: string;
    transactionCostScheduleEntryList: TransactionCostScheduleEntry[];
}

export class TransactionCostScheduleEntry {
    valueDate: string;
    description: string;
    securityId: string;
    quantity: string;
    consideration: string;
    cost: string;
    currency: string;
    assetClass: AssetClass;
}

export class AccountIdentifier {
    accountId: string;
    bankCode: string;
}

export class SCBTransactionEventDTO {
    transactionIdentifier: TransactionIdentifier;
    accountIdentifier: AccountIdentifier;
    transactionAmount: TransactionAmount;
    transactionTimestamp: string;
    timestamp: string;
    adviceType: string;
}

export class TransactionAmount {
    currencyCode: string;
    amount: string;
}

export class TransactionIdentifier {
    type: string;
    identifier: string;
}

export class ShortSellTransactionStatus {
    netPositionValue: string;
    date: string;
    fieldName: string;
    fieldValue: string;
    valid: boolean;
}

export class TransactionDeleteResponse implements DefaultHandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
    id: string;
    securityId: string;
    assetClass: AssetClass;
    valueDate: string;
    quantity: string;
    executingAccount: string;
}

export class TransactionResponse {
    id: string;
    transactionStatus: ExecutionStatus;
    authStatus: TransactionAuthStatus;
    portfolioUUID: string;
    portfolioName: string;
    securityUUID: string;
    securityId: string;
    assetClass: AssetClass;
    name: string;
    isin: string;
    executingAccountUUID: string;
    executingAccount: string;
    counterParty: string;
    counterPartyUUID: string;
    oldTransactionId: string;
    orderSide: OrderSide;
    quantity: string;
    price: string;
    consideration: string;
    calculationBase: CalculationBase;
    yield: string;
    valueDate: string;
    adjustedYieldToMaturity: string;
    noOfDaysBetweenIssueAndMaturity: number;
    effectiveIssueDate: string;
    daysToMaturity: number;
    nextInterestPaymentDate: string;
    accruedInterest: string;
    cleanPrice: string;
    dirtyPricePercentage: string;
    cleanPricePercentage: string;
    csdFee: string;
    corporateAction: CorporateAction;
    systemGenerated: boolean;
    principalAmount: string;
    refSecurityId: string;
    rootTransaction: boolean;
    transactionTypeId: string;
    transactionTypeName: string;
    fxRate: string;
    unitPayoutClientId: string;
    unitPayoutPortfolioId: string;
    unitPayoutClientName: string;
    unitPayoutPortfolioName: string;
    unitPayoutQuantity: string;
    editUpdateDisabled: boolean;
    allAppliedTransactionCost: AppliedTransactionCost[];
    entryExitLoadPercentage: string;
    description: string;
    executionErrorMessage: string;
}

export class TwelveDataResponseDTO {
    values: TwelveDataResponseEntryDTO[];
}

export class TwelveDataResponseEntryDTO {
    datetime: string;
    open: string;
    high: string;
    low: string;
    close: string;
}

export class UserDTO {
    username: string;
    firstName: string;
    lastName: string;
    emailId: string;
    phone: string;
    systemUser: boolean;
}

export class WidgetDTO {
    id: string;
    width: number;
    height: number;
    dashboardId: string;
    widgetConfig: string;
    name: string;
    description: string;
    xLocation: number;
    yLocation: number;
}

export class BaseAuditable implements Serializable {
    createdOn: string;
    modifiedBy: string;
    createdBy: string;
    updatedOn: string;
}

export class BaseAuditableUUID extends BaseAuditable implements Serializable {
    id: string;
}

export class DashboardConfig extends BaseAuditableUUID {
    portfolio: Portfolio;
    userId: string;
}

export class DslExpression<T> implements Expression<T> {
    type: Class<T>;
}

export class SimpleExpression<T> extends DslExpression<T> {
}

export class BeanPath<T> extends SimpleExpression<T> implements Path<T> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export class EntityPathBase<T> extends BeanPath<T> implements EntityPath<T> {
}

export class QDashboardConfig extends EntityPathBase<DashboardConfig> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
    userId: StringPath;
}

export class QWithProduct extends EntityPathBase<WithProduct> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class User implements EmptyHandleBaseError {
    username: string;
    firstName: string;
    lastName: string;
    emailId: string;
    phone: string;
    systemUser: boolean;
}

export class WithProduct extends BaseAuditableUUID {
    security: Security;
}

export class Account extends BaseAuditableUUID implements EmptyHandleBaseError {
    name: string;
    generatedCode: string;
    description: string;
    accountType: AccountType;
    contactPersonName: string;
    contactNumber: string;
    bankDetail: BankDetail;
    currency: Currency;
    archived: boolean;
    glType: boolean;
    allowIntegration: boolean;
}

export class AccountInterestRate extends BaseAuditableUUID implements EmptyHandleBaseError {
    account: Account;
    fromDate: string;
    toDate: string;
    interestRate: string;
    interestRateType: InterestRateType;
    dayCountBasis: DayCountBasis;
}

export class AccountType extends BaseAuditableUUID {
    name: string;
    description: string;
    parent: AccountType;
    glType: boolean;
}

export class BankDetail extends BaseAuditableUUID implements EmptyHandleBaseError {
    bankName: string;
    bankAccountName: string;
    bankAccountNumber: string;
    bankBranchCode: string;
    bankSwiftCode: string;
}

export class CounterParty extends BaseAuditableUUID {
    name: string;
    alias: string;
    bankName: string;
    bankAccountName: string;
    bankAccountNumber: string;
    bankBranchName: string;
}

export class Country implements Serializable {
    code: string;
    name: string;
}

export class Currency implements Serializable {
    code: string;
    name: string;
}

export class Custodian extends BaseAuditableUUID {
    name: string;
    address: string;
    contactPerson: string;
}

export class QAccount extends EntityPathBase<Account> {
    _super: QBaseAuditableUUID;
    allowIntegration: BooleanPath;
    archived: BooleanPath;
    contactNumber: StringPath;
    contactPersonName: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    generatedCode: StringPath;
    glType: BooleanPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QAccountInterestRate extends EntityPathBase<AccountInterestRate> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    dayCountBasis: EnumPath<DayCountBasis>;
    fromDate: DateTimePath<string>;
    id: ComparablePath<string>;
    interestRate: NumberPath<string>;
    interestRateType: EnumPath<InterestRateType>;
    modifiedBy: StringPath;
    toDate: DateTimePath<string>;
    updatedOn: DateTimePath<string>;
}

export class QAccountType extends EntityPathBase<AccountType> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    glType: BooleanPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QBankDetail extends EntityPathBase<BankDetail> {
    _super: QBaseAuditableUUID;
    bankAccountName: StringPath;
    bankAccountNumber: StringPath;
    bankBranchCode: StringPath;
    bankName: StringPath;
    bankSwiftCode: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QCounterParty extends EntityPathBase<CounterParty> {
    _super: QBaseAuditableUUID;
    alias: StringPath;
    bankAccountName: StringPath;
    bankAccountNumber: StringPath;
    bankBranchName: StringPath;
    bankName: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QCountry extends EntityPathBase<Country> {
    code: StringPath;
    name: StringPath;
}

export class QCurrency extends EntityPathBase<Currency> {
    code: StringPath;
    name: StringPath;
}

export class QCustodian extends EntityPathBase<Custodian> {
    _super: QBaseAuditableUUID;
    address: StringPath;
    contactPerson: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QTransactionCost extends EntityPathBase<TransactionCost> {
    _super: QBaseAuditableUUID;
    applicableAssetClasses: ListPath<AssetClass, EnumPath<AssetClass>>;
    archived: BooleanPath;
    changeable: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    defaultValue: NumberPath<string>;
    description: StringPath;
    enabled: BooleanPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    rateType: EnumPath<RateType>;
    updatedOn: DateTimePath<string>;
}

export class QTransactionType extends EntityPathBase<TransactionType> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    changeUnits: BooleanPath;
    codeName: EnumPath<TransactionTypeCode>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    glType: BooleanPath;
    groupName: StringPath;
    id: ComparablePath<string>;
    liability: BooleanPath;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
}

export class TransactionCost extends BaseAuditableUUID implements EmptyHandleBaseError {
    name: string;
    enabled: boolean;
    description: string;
    rateType: RateType;
    applicableAssetClasses: AssetClass[];
    defaultValue: string;
    changeable: boolean;
    archived: boolean;
}

export class TransactionType extends BaseAuditableUUID implements EmptyHandleBaseError {
    name: string;
    description: string;
    groupName: string;
    glType: boolean;
    changeUnits: boolean;
    liability: boolean;
    codeName: TransactionTypeCode;
    archived: boolean;
}

export class AppConfig {
    key: AppConfigKey;
    rawValue: string;
}

export class QAppConfig extends EntityPathBase<AppConfig> {
    key: EnumPath<AppConfigKey>;
    rawValue: StringPath;
}

export class BankTransactionEvent extends BaseAuditableUUID {
    portfolio: Portfolio;
    messageId: string;
    valueDate: string;
    eventDate: string;
    account: Account;
    orderSide: OrderSide;
    source: BankSource;
    status: BankTransactionEventStatus;
    remainingQuantity: string;
    quantity: string;
    internalMessage: string;
    rawEvent: string;
    rawEventId: string;
}

export class QBankTransactionEvent extends EntityPathBase<BankTransactionEvent> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    eventDate: DateTimePath<string>;
    id: ComparablePath<string>;
    internalMessage: StringPath;
    messageId: StringPath;
    modifiedBy: StringPath;
    orderSide: EnumPath<OrderSide>;
    quantity: NumberPath<string>;
    rawEvent: StringPath;
    rawEventId: ComparablePath<string>;
    remainingQuantity: NumberPath<string>;
    source: EnumPath<BankSource>;
    status: EnumPath<BankTransactionEventStatus>;
    updatedOn: DateTimePath<string>;
    valueDate: DateTimePath<string>;
}

export class QRawBankTransaction extends EntityPathBase<RawBankTransaction> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    data: StringPath;
    id: ComparablePath<string>;
    identifier: StringPath;
    messageId: StringPath;
    modifiedBy: StringPath;
    processingError: StringPath;
    source: EnumPath<BankSource>;
    status: EnumPath<ProcessingStatus>;
    timestamp: DateTimePath<string>;
    updatedOn: DateTimePath<string>;
}

export class RawBankTransaction extends BaseAuditableUUID {
    identifier: string;
    messageId: string;
    data: string;
    timestamp: string;
    type: string;
    source: BankSource;
    status: ProcessingStatus;
    processingError: string;
}

export class BankTransactionEventConfig extends BaseAuditableUUID {
    queueName: string;
    queueRegion: string;
    eventType: string;
    eventSource: string;
    requestPrivateKey: string;
    signPrivateKey: string;
    requestCertificate: string;
    signCertificate: string;
    signPublicKey: string;
    enabled: boolean;
}

export class QBankTransactionEventConfig extends EntityPathBase<BankTransactionEventConfig> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    enabled: BooleanPath;
    eventSource: StringPath;
    eventType: StringPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    queueName: StringPath;
    queueRegion: StringPath;
    requestCertificate: StringPath;
    requestPrivateKey: StringPath;
    signCertificate: StringPath;
    signPrivateKey: StringPath;
    signPublicKey: StringPath;
    updatedOn: DateTimePath<string>;
}

export class BaseUUID implements Serializable {
    id: string;
}

export class QBaseAuditable extends EntityPathBase<BaseAuditable> {
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QBaseAuditableUUID extends EntityPathBase<BaseAuditableUUID> {
    _super: QBaseAuditable;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QBaseUUID extends EntityPathBase<BaseUUID> {
    id: ComparablePath<string>;
}

export class Client extends BaseAuditableUUID implements DefaultHandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
    name: string;
    description: string;
    archived: boolean;
    clientType: ClientType;
    clientSubType: ClientSubType;
    clientCode: string;
    clientAddress: string;
    clientTelephone: string;
    currency: Currency;
    clientEmail: string;
    clientKYC: ClientKYC;
    createPortfolio: boolean;
    executionPriority: number;
    snapshotStartDate: string;
    benchmarkId: string;
    taskQueueName: string;
    jotformSubmissionId: string;
    department: Department;
}

export class ClientSnapshot extends BaseAuditableUUID {
    clientId: string;
    date: string;
}

export class ClientSubType extends BaseAuditableUUID implements EmptyHandleBaseError {
    name: string;
    description: string;
    archived: boolean;
    clientType: ClientType;
    kycConfig: KYCConfig;
}

export class ClientType extends BaseAuditableUUID implements EmptyHandleBaseError {
    name: string;
    description: string;
    archived: boolean;
}

export class QClient extends EntityPathBase<Client> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    benchmarkId: ComparablePath<string>;
    clientAddress: StringPath;
    clientCode: StringPath;
    clientEmail: StringPath;
    clientTelephone: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    department: EnumPath<Department>;
    description: StringPath;
    executionPriority: NumberPath<number>;
    id: ComparablePath<string>;
    jotformSubmissionId: StringPath;
    modifiedBy: StringPath;
    name: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QClientSnapshot extends EntityPathBase<ClientSnapshot> {
    _super: QBaseAuditableUUID;
    clientId: ComparablePath<string>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    date: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QClientSubType extends EntityPathBase<ClientSubType> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QClientType extends EntityPathBase<ClientType> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
}

export class ClientSummary extends BaseAuditable {
    clientSummaryKey: ClientSummaryKey;
    client: Client;
    aum: string;
    nav: string;
    profitLoss: string;
    positionBalance: string;
    ledgerBalance: string;
    clientStartLedgerBalance: string;
    ytdProfitLossPercentage: string;
    annualizedReturnPercentage: string;
    cumulativeAnnualizedReturnPercentage: string;
    cumulativeReturnPercentage: string;
    liabilities: string;
    receivables: string;
    totalCash: string;
    totalCost: string;
    units: string;
    unitPrice: string;
    clientSummaryStatus: ClientSummaryStatus;
}

export class ClientSummaryFXDetail extends BaseAuditableUUID {
    fxMarketQuoteLast: string;
    fxMarketQuoteDate: string;
    currency: Currency;
}

export class ClientSummaryKey extends BaseUUID {
    date: string;
}

export class QClientSummary extends EntityPathBase<ClientSummary> {
    _super: QBaseAuditable;
    annualizedReturnPercentage: NumberPath<string>;
    aum: NumberPath<string>;
    clientStartLedgerBalance: NumberPath<string>;
    clientSummaryStatus: EnumPath<ClientSummaryStatus>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    cumulativeAnnualizedReturnPercentage: NumberPath<string>;
    cumulativeReturnPercentage: NumberPath<string>;
    ledgerBalance: NumberPath<string>;
    liabilities: NumberPath<string>;
    modifiedBy: StringPath;
    nav: NumberPath<string>;
    positionBalance: NumberPath<string>;
    profitLoss: NumberPath<string>;
    receivables: NumberPath<string>;
    totalCash: NumberPath<string>;
    totalCost: NumberPath<string>;
    unitPrice: NumberPath<string>;
    units: NumberPath<string>;
    updatedOn: DateTimePath<string>;
    ytdProfitLossPercentage: NumberPath<string>;
}

export class QClientSummaryFXDetail extends EntityPathBase<ClientSummaryFXDetail> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    fxMarketQuoteDate: DateTimePath<string>;
    fxMarketQuoteLast: NumberPath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QClientSummaryKey extends BeanPath<ClientSummaryKey> {
    _super: QBaseUUID;
    date: DateTimePath<string>;
    id: ComparablePath<string>;
}

export class EmailLog extends BaseAuditableUUID {
    emailSubject: string;
    emailAddress: string;
    message: string;
    status: EmailStatus;
    clientCode: string;
}

export class EmailRequest extends EmailDTO {
    fileAttachmentDTOList: FileAttachmentDTO[];
    emailId: string;
    clientCode: string;
}

export class EmailTemplate extends BaseAuditableUUID {
    emailType: EmailType;
    emailContentType: EmailContentType;
    content: string;
    emailSubject: string;
    emailHeader: string;
}

export class QEmailLog extends EntityPathBase<EmailLog> {
    _super: QBaseAuditableUUID;
    clientCode: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    emailAddress: StringPath;
    emailSubject: StringPath;
    id: ComparablePath<string>;
    message: StringPath;
    modifiedBy: StringPath;
    status: EnumPath<EmailStatus>;
    updatedOn: DateTimePath<string>;
}

export class QEmailTemplate extends EntityPathBase<EmailTemplate> {
    _super: QBaseAuditableUUID;
    content: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    emailContentType: EnumPath<EmailContentType>;
    emailHeader: StringPath;
    emailSubject: StringPath;
    emailType: EnumPath<EmailType>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class ClientKYC extends BaseAuditableUUID implements EmptyHandleBaseError {
    kycStatus: KYCStatus;
    clientId: string;
    kycConfig: KYCConfig;
    archived: boolean;
    rejectionReason: string;
}

export class ClientKYCAnswer extends BaseAuditableUUID implements EmptyHandleBaseError {
    kycValue: string;
    clientKYC: ClientKYC;
    groupId: string;
    questionIdentifier: string;
    question: string;
    sectionIdentifier: string;
    sectionName: string;
    subSectionIdentifier: string;
    subSectionName: string;
}

export class KYCConfig extends BaseAuditableUUID implements EmptyHandleBaseError {
    name: string;
    description: string;
    version: number;
    archived: boolean;
}

export class KYCQuestionConfig extends BaseAuditableUUID implements EmptyHandleBaseError {
    question: string;
    questionType: QuestionType;
    description: string;
    visibilityOrder: number;
    visible: boolean;
    mandatory: boolean;
    version: number;
    identifier: string;
    optionValues: KYCQuestionOption[];
    kycSubSectionConfig: KYCSubSectionConfig;
    archived: boolean;
}

export class KYCQuestionOption extends BaseAuditableUUID implements EmptyHandleBaseError {
    optionValue: string;
    visibilityOrder: number;
    visible: boolean;
    archived: boolean;
}

export class KYCSectionConfig extends BaseAuditableUUID implements EmptyHandleBaseError {
    name: string;
    description: string;
    visibilityOrder: number;
    visible: boolean;
    repeat: boolean;
    maxRepetition: number;
    version: number;
    identifier: string;
    kycConfig: KYCConfig;
    archived: boolean;
}

export class KYCSubSectionConfig extends BaseAuditableUUID implements EmptyHandleBaseError {
    name: string;
    description: string;
    visibilityOrder: number;
    version: number;
    identifier: string;
    visible: boolean;
    kycSectionConfig: KYCSectionConfig;
    archived: boolean;
}

export class QClientKYC extends EntityPathBase<ClientKYC> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    clientId: ComparablePath<string>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    kycStatus: EnumPath<KYCStatus>;
    modifiedBy: StringPath;
    rejectionReason: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QClientKYCAnswer extends EntityPathBase<ClientKYCAnswer> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    groupId: StringPath;
    id: ComparablePath<string>;
    kycValue: StringPath;
    modifiedBy: StringPath;
    question: StringPath;
    questionIdentifier: StringPath;
    sectionIdentifier: StringPath;
    sectionName: StringPath;
    subSectionIdentifier: StringPath;
    subSectionName: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QKYCConfig extends EntityPathBase<KYCConfig> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
    version: NumberPath<number>;
}

export class QKYCQuestionConfig extends EntityPathBase<KYCQuestionConfig> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    identifier: StringPath;
    mandatory: BooleanPath;
    modifiedBy: StringPath;
    optionValues: ListPath<KYCQuestionOption, QKYCQuestionOption>;
    question: StringPath;
    questionType: EnumPath<QuestionType>;
    updatedOn: DateTimePath<string>;
    version: NumberPath<number>;
    visibilityOrder: NumberPath<number>;
    visible: BooleanPath;
}

export class QKYCQuestionOption extends EntityPathBase<KYCQuestionOption> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    optionValue: StringPath;
    updatedOn: DateTimePath<string>;
    visibilityOrder: NumberPath<number>;
    visible: BooleanPath;
}

export class QKYCSectionConfig extends EntityPathBase<KYCSectionConfig> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    identifier: StringPath;
    maxRepetition: NumberPath<number>;
    modifiedBy: StringPath;
    name: StringPath;
    repeat: BooleanPath;
    updatedOn: DateTimePath<string>;
    version: NumberPath<number>;
    visibilityOrder: NumberPath<number>;
    visible: BooleanPath;
}

export class QKYCSubSectionConfig extends EntityPathBase<KYCSubSectionConfig> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    identifier: StringPath;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
    version: NumberPath<number>;
    visibilityOrder: NumberPath<number>;
    visible: BooleanPath;
}

export class Notification extends BaseAuditableUUID {
    description: string;
    messagePath: string;
    archived: boolean;
    title: string;
    type: NotificationType;
}

export class QNotification extends EntityPathBase<Notification> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    messagePath: StringPath;
    modifiedBy: StringPath;
    title: StringPath;
    updatedOn: DateTimePath<string>;
}

export class Portfolio extends BaseAuditableUUID implements DefaultHandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
    name: string;
    accounts: Account[];
    currency: Currency;
    csdNo: string;
    csdFeePercentage: string;
    corporateActionAccounts: Account[];
    custodian: Custodian;
    financialYearDate: string;
    snapshotStartDate: string;
    address: string;
    telephone: string;
    email: string;
    portfolioCode: number;
    corporateTrustee: string;
    status: PortfolioStatus;
    valuationStrategy: ValuationStrategy;
    bondCurrentPriceStrategy: BondCurrentPriceStrategy;
    unitPricingStrategy: UnitPricingStrategy;
    portfolioStartDate: string;
    initialUnits: string;
    initialUnitPrice: string;
    client: Client;
    instrumentId: string;
    benchmarkId: string;
    logoKey: string;
    executionPriority: number;
    entryExitLoadPercentage: string;
    taskQueueName: string;
}

export class PortfolioSnapshot extends BaseAuditableUUID {
    portfolioId: string;
    date: string;
}

export class QPortfolio extends EntityPathBase<Portfolio> {
    _super: QBaseAuditableUUID;
    accounts: ListPath<Account, QAccount>;
    address: StringPath;
    benchmarkId: ComparablePath<string>;
    bondCurrentPriceStrategy: EnumPath<BondCurrentPriceStrategy>;
    corporateActionAccounts: ListPath<Account, QAccount>;
    corporateTrustee: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    csdFeePercentage: NumberPath<string>;
    csdNo: StringPath;
    email: StringPath;
    entryExitLoadPercentage: NumberPath<string>;
    executionPriority: NumberPath<number>;
    financialYearDate: DateTimePath<string>;
    id: ComparablePath<string>;
    initialUnitPrice: NumberPath<string>;
    initialUnits: NumberPath<string>;
    instrumentId: ComparablePath<string>;
    logoKey: StringPath;
    modifiedBy: StringPath;
    name: StringPath;
    portfolioCode: NumberPath<number>;
    portfolioStartDate: DateTimePath<string>;
    snapshotStartDate: DateTimePath<string>;
    status: EnumPath<PortfolioStatus>;
    taskQueueName: StringPath;
    telephone: StringPath;
    unitPricingStrategy: EnumPath<UnitPricingStrategy>;
    updatedOn: DateTimePath<string>;
    valuationStrategy: EnumPath<ValuationStrategy>;
}

export class QPortfolioSnapshot extends EntityPathBase<PortfolioSnapshot> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    date: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    portfolioId: ComparablePath<string>;
    updatedOn: DateTimePath<string>;
}

export class PortfolioIncome extends BaseAuditableUUID implements EmptyHandleBaseError {
    portfolioId: string;
    name: string;
    accrueFrequency: Frequency;
    startDate: string;
    enabled: boolean;
    systemGenerated: boolean;
    archived: boolean;
}

export class QPortfolioIncome extends EntityPathBase<PortfolioIncome> {
    _super: QBaseAuditableUUID;
    accrueFrequency: EnumPath<Frequency>;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    enabled: BooleanPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    portfolioId: ComparablePath<string>;
    startDate: DateTimePath<string>;
    systemGenerated: BooleanPath;
    updatedOn: DateTimePath<string>;
}

export class PortfolioLiability extends BaseAuditableUUID implements EmptyHandleBaseError {
    portfolioId: string;
    name: string;
    liabilityType: RateType;
    value: string;
    liabilityColumn: LiabilityColumn;
    accrueFrequency: Frequency;
    accrueDate: string;
    paymentFrequency: Frequency;
    paymentDate: string;
    dayCountBasis: DayCountBasis;
    startDate: string;
    enabled: boolean;
    systemGenerated: boolean;
    archived: boolean;
}

export class QPortfolioLiability extends EntityPathBase<PortfolioLiability> {
    _super: QBaseAuditableUUID;
    accrueDate: DateTimePath<string>;
    accrueFrequency: EnumPath<Frequency>;
    archived: BooleanPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    dayCountBasis: EnumPath<DayCountBasis>;
    enabled: BooleanPath;
    id: ComparablePath<string>;
    liabilityColumn: EnumPath<LiabilityColumn>;
    liabilityType: EnumPath<RateType>;
    modifiedBy: StringPath;
    name: StringPath;
    paymentDate: DateTimePath<string>;
    paymentFrequency: EnumPath<Frequency>;
    portfolioId: ComparablePath<string>;
    startDate: DateTimePath<string>;
    systemGenerated: BooleanPath;
    updatedOn: DateTimePath<string>;
    value: NumberPath<string>;
}

export class PortfolioPayable extends BaseAuditableUUID implements EmptyHandleBaseError {
    portfolioId: string;
    portfolioLiability: PortfolioLiability;
    debitTransaction: Transaction;
    creditTransaction: Transaction;
    subType: string;
    description: string;
    currency: Currency;
    debitAmount: string;
    creditAmount: string;
    date: string;
    paid: boolean;
    paymentDate: string;
    systemGenerated: boolean;
}

export class QPortfolioPayable extends EntityPathBase<PortfolioPayable> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    creditAmount: NumberPath<string>;
    date: DateTimePath<string>;
    debitAmount: NumberPath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    paid: BooleanPath;
    paymentDate: DateTimePath<string>;
    portfolioId: ComparablePath<string>;
    subType: StringPath;
    systemGenerated: BooleanPath;
    updatedOn: DateTimePath<string>;
}

export class PortfolioReceivable extends BaseAuditableUUID implements EmptyHandleBaseError {
    portfolioId: string;
    portfolioIncome: PortfolioIncome;
    debitTransaction: Transaction;
    creditTransaction: Transaction;
    subType: string;
    description: string;
    currency: Currency;
    debitAmount: string;
    creditAmount: string;
    date: string;
    paid: boolean;
    paymentDate: string;
    systemGenerated: boolean;
}

export class QPortfolioReceivable extends EntityPathBase<PortfolioReceivable> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    creditAmount: NumberPath<string>;
    date: DateTimePath<string>;
    debitAmount: NumberPath<string>;
    description: StringPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    paid: BooleanPath;
    paymentDate: DateTimePath<string>;
    portfolioId: ComparablePath<string>;
    subType: StringPath;
    systemGenerated: BooleanPath;
    updatedOn: DateTimePath<string>;
}

export class CostReceivableLiabilityDetail extends BaseAuditableUUID {
    fxMarketQuoteLast: string;
    fxMarketQuoteDate: string;
    currency: Currency;
    amount: string;
    amountWithCurrency: string;
    valueType: CostReceivableLiabilityType;
    portfolio: Portfolio;
    date: string;
    portfolioSummaryId: string;
}

export class PortfolioSummary extends BaseAuditable {
    portfolio: Portfolio;
    portfolioSummaryKey: PortfolioSummaryKey;
    aum: string;
    nav: string;
    profitLoss: string;
    positionBalance: string;
    ledgerBalance: string;
    portfolioStartLedgerBalance: string;
    ytdProfitLossPercentage: string;
    annualizedReturnPercentage: string;
    cumulativeAnnualizedReturnPercentage: string;
    cumulativeReturnPercentage: string;
    liabilities: string;
    receivables: string;
    totalCash: string;
    totalCost: string;
    units: string;
    unitPrice: string;
    portfolioSummaryStatus: PortfolioSummaryStatus;
    lockStatus: LockStatus;
}

export class PortfolioSummaryKey extends BaseUUID {
    date: string;
}

export class QCostReceivableLiabilityDetail extends EntityPathBase<CostReceivableLiabilityDetail> {
    _super: QBaseAuditableUUID;
    amount: NumberPath<string>;
    amountWithCurrency: NumberPath<string>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    date: DateTimePath<string>;
    fxMarketQuoteDate: DateTimePath<string>;
    fxMarketQuoteLast: NumberPath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    portfolioSummaryId: ComparablePath<string>;
    updatedOn: DateTimePath<string>;
    valueType: EnumPath<CostReceivableLiabilityType>;
}

export class QPortfolioSummary extends EntityPathBase<PortfolioSummary> {
    _super: QBaseAuditable;
    annualizedReturnPercentage: NumberPath<string>;
    aum: NumberPath<string>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    cumulativeAnnualizedReturnPercentage: NumberPath<string>;
    cumulativeReturnPercentage: NumberPath<string>;
    ledgerBalance: NumberPath<string>;
    liabilities: NumberPath<string>;
    lockStatus: EnumPath<LockStatus>;
    modifiedBy: StringPath;
    nav: NumberPath<string>;
    portfolioStartLedgerBalance: NumberPath<string>;
    portfolioSummaryStatus: EnumPath<PortfolioSummaryStatus>;
    positionBalance: NumberPath<string>;
    profitLoss: NumberPath<string>;
    receivables: NumberPath<string>;
    totalCash: NumberPath<string>;
    totalCost: NumberPath<string>;
    unitPrice: NumberPath<string>;
    units: NumberPath<string>;
    updatedOn: DateTimePath<string>;
    ytdProfitLossPercentage: NumberPath<string>;
}

export class QPortfolioSummaryKey extends BeanPath<PortfolioSummaryKey> {
    _super: QBaseUUID;
    date: DateTimePath<string>;
    id: ComparablePath<string>;
}

export class Position extends BaseAuditable {
    positionKey: PositionKey;
    security: Security;
    portfolio: Portfolio;
    account: Account;
    glType: boolean;
    transactionType: TransactionType;
    assetClass: AssetClass;
    quantity: string;
    maturityQuantity: string;
    totalCost: string;
    size: number;
    duration: string;
    weight: string;
    shermanRatio: string;
    nextCouponPaymentDate: string;
    currentCouponPaymentDate: string;
    nextCouponInterest: string;
    purchaseCleanPricePercentage: string;
    avgPrice: string;
    principal: string;
    interest: string;
    maturityValue: string;
    accruedInterest: string;
    purchaseYield: string;
    maturityDate: string;
    marketQuoteCouponRate: string;
    marketQuoteYield: string;
    marketQuoteLast: string;
    marketQuoteDate: string;
    couponMarketQuoteDate: string;
    marketCleanPricePercentage: string;
    marketCleanPriceConsideration: string;
    marketDirtyPricePercentage: string;
    fxMarketQuoteLast: string;
    fxMarketQuoteDate: string;
    currentValueWithCurrency: string;
    marketValueWithCurrency: string;
    currentValue: string;
    marketValue: string;
    profitLoss: string;
    profitLossRatio: string;
    locked: boolean;
    recalculateCorporateActions: boolean;
    couponTransaction: Transaction;
    maturityTransaction: Transaction;
    mduration: string;
}

export class PositionKey extends BaseUUID implements Serializable {
    date: string;
}

export class QPosition extends EntityPathBase<Position> {
    _super: QBaseAuditable;
    accruedInterest: NumberPath<string>;
    assetClass: EnumPath<AssetClass>;
    avgPrice: NumberPath<string>;
    couponMarketQuoteDate: DateTimePath<string>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    currentCouponPaymentDate: DateTimePath<string>;
    currentValue: NumberPath<string>;
    currentValueWithCurrency: NumberPath<string>;
    duration: NumberPath<string>;
    fxMarketQuoteDate: DateTimePath<string>;
    fxMarketQuoteLast: NumberPath<string>;
    glType: BooleanPath;
    interest: NumberPath<string>;
    locked: BooleanPath;
    marketCleanPriceConsideration: NumberPath<string>;
    marketCleanPricePercentage: NumberPath<string>;
    marketDirtyPricePercentage: NumberPath<string>;
    marketQuoteCouponRate: NumberPath<string>;
    marketQuoteDate: DateTimePath<string>;
    marketQuoteLast: NumberPath<string>;
    marketQuoteYield: NumberPath<string>;
    marketValue: NumberPath<string>;
    marketValueWithCurrency: NumberPath<string>;
    maturityDate: DateTimePath<string>;
    maturityQuantity: NumberPath<string>;
    maturityValue: NumberPath<string>;
    mDuration: NumberPath<string>;
    modifiedBy: StringPath;
    nextCouponInterest: NumberPath<string>;
    nextCouponPaymentDate: DateTimePath<string>;
    principal: NumberPath<string>;
    profitLoss: NumberPath<string>;
    profitLossRatio: NumberPath<string>;
    purchaseCleanPricePercentage: NumberPath<string>;
    purchaseYield: NumberPath<string>;
    quantity: NumberPath<string>;
    recalculateCorporateActions: BooleanPath;
    shermanRatio: NumberPath<string>;
    size: NumberPath<number>;
    totalCost: NumberPath<string>;
    updatedOn: DateTimePath<string>;
    weight: NumberPath<string>;
}

export class QPositionKey extends BeanPath<PositionKey> {
    _super: QBaseUUID;
    date: DateTimePath<string>;
    id: ComparablePath<string>;
}

export class MarketQuote extends WithProduct implements DefaultHandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
    timestamp: string;
    last: string;
    close: string;
    open: string;
    yield: string;
    ask: string;
    bid: string;
    couponRate: string;
    priceBase: CalculationBase;
    source: string;
    marketQuoteStatus: ExecutionStatus;
    oldMarketQuoteId: string;
    systemGenerated: boolean;
    marketQuoteExecuted: boolean;
}

export class QMarketQuote extends EntityPathBase<MarketQuote> {
    _super: QWithProduct;
    ask: NumberPath<string>;
    bid: NumberPath<string>;
    close: NumberPath<string>;
    couponRate: NumberPath<string>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    last: NumberPath<string>;
    marketQuoteExecuted: BooleanPath;
    marketQuoteStatus: EnumPath<ExecutionStatus>;
    modifiedBy: StringPath;
    oldMarketQuoteId: ComparablePath<string>;
    open: NumberPath<string>;
    priceBase: EnumPath<CalculationBase>;
    source: StringPath;
    systemGenerated: BooleanPath;
    timestamp: DateTimePath<string>;
    updatedOn: DateTimePath<string>;
    yield: NumberPath<string>;
}

export class QRecalculateCouponPayment extends EntityPathBase<RecalculateCouponPayment> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    date: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class RecalculateCouponPayment extends BaseAuditableUUID {
    security: Security;
    portfolio: Portfolio;
    date: string;
}

export class Security extends BaseAuditableUUID implements DefaultHandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
    assetClass: AssetClass;
    securityId: string;
    securityLongId: string;
    baseCurrency: Currency;
    snapshotStartDate: string;
    taskQueueName: string;
    archived: boolean;
    productIdentifiers: ProductIdentifier[];
}

export class FixedIncomeSecurity extends Security {
    maturityDate: string;
    issueDate: string;
    dayCountBasis: DayCountBasis;
}

export class BillSecurity extends FixedIncomeSecurity {
    country: Country;
    issuer: string;
    tenor: string;
    name: string;
    isin: string;
    issueType: string;
    issueAmount: string;
    fixedIncomeClass: BillFixedIncomeAssetClass;
    marketIssue: string;
    issueYield: string;
    calculationType: string;
    pricingDate: string;
    interestAccrualDate: string;
    firstSettleDate: string;
    series: string;
    issuePrice: string;
    exchange: string;
    bookRunner: string;
    idNumber: string;
    figi: string;
    issuerShortName: string;
    securitySmallName: string;
    instrumentSector: InstrumentSector;
    marketCapitalCategory: MarketCapitalCategory;
    uniqueSecuritySmallName: string;
}

export class BondSecurity extends FixedIncomeSecurity {
    country: Country;
    issuer: string;
    tenor: string;
    name: string;
    coupon: string;
    isin: string;
    issueType: string;
    issueAmount: string;
    issuerIndustry: string;
    announcementDate: string;
    couponType: CouponType;
    annualCouponFrequency: number;
    firstCouponDate: string;
    fixedIncomeClass: BondFixedIncomeAssetClass;
    couponDateBasis: CouponDateBasis;
    marketIssue: string;
    issueYield: string;
    calculationType: string;
    pricingDate: string;
    interestAccrualDate: string;
    firstSettleDate: string;
    series: string;
    issuePrice: string;
    exchange: string;
    bookRunner: string;
    idNumber: string;
    figi: string;
    issuerShortName: string;
    securitySmallName: string;
    bondSector: BondSector;
    marketCapitalCategory: MarketCapitalCategory;
    creditRating: CreditRating;
    uniqueSecuritySmallName: string;
}

export class CISSecurity extends Security {
    name: string;
    companyName: string;
    fundManager: string;
    portfolio: Portfolio;
    instrumentSector: InstrumentSector;
    marketCapitalCategory: MarketCapitalCategory;
}

export class ETFSecurity extends Security {
    name: string;
    isin: string;
    symbol: string;
    fundManager: string;
    fundHouse: string;
    country: Country;
    market: string;
    instrumentSector: InstrumentSector;
    marketCapitalCategory: MarketCapitalCategory;
}

export class EquitySecurity extends Security {
    name: string;
    isin: string;
    symbol: string;
    companyName: string;
    country: Country;
    market: string;
    instrumentSector: InstrumentSector;
    marketCapitalCategory: MarketCapitalCategory;
    stockStyle: StockStyle;
}

export class FXSecurity extends Security {
    targetCurrency: Currency;
    instrumentSector: InstrumentSector;
    marketCapitalCategory: MarketCapitalCategory;
}

export class FixedDepositSecurity extends FixedIncomeSecurity {
    country: Country;
    issuer: string;
    tenor: string;
    returnRate: string;
    name: string;
    fixedIncomeClass: FDFixedIncomeAssetClass;
    marketIssue: string;
    issueYield: string;
    calculationType: string;
    pricingDate: string;
    interestAccrualDate: string;
    firstSettleDate: string;
    series: string;
    issuePrice: string;
    exchange: string;
    bookRunner: string;
    idNumber: string;
    figi: string;
    instrumentSector: InstrumentSector;
    marketCapitalCategory: MarketCapitalCategory;
}

export class IndexSecurity extends Security {
    name: string;
    initialCapital: string;
    returnType: IndexReturnType;
    distributionType: IndexDistributionType;
    portfolio: Portfolio;
}

export class ProductIdentifier extends BaseAuditableUUID {
    type: ProductIdentifierType;
    value: string;
}

export class QBillSecurity extends EntityPathBase<BillSecurity> {
    _super: QFixedIncomeSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    bookRunner: StringPath;
    calculationType: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    dayCountBasis: EnumPath<DayCountBasis>;
    exchange: StringPath;
    figi: StringPath;
    firstSettleDate: DateTimePath<string>;
    fixedIncomeClass: EnumPath<BillFixedIncomeAssetClass>;
    id: ComparablePath<string>;
    idNumber: StringPath;
    instrumentSector: EnumPath<InstrumentSector>;
    interestAccrualDate: DateTimePath<string>;
    isin: StringPath;
    issueAmount: NumberPath<string>;
    issueDate: DateTimePath<string>;
    issuePrice: NumberPath<string>;
    issuer: StringPath;
    issuerShortName: StringPath;
    issueType: StringPath;
    issueYield: NumberPath<string>;
    marketCapitalCategory: EnumPath<MarketCapitalCategory>;
    marketIssue: StringPath;
    maturityDate: DateTimePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    pricingDate: DateTimePath<string>;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    securityId: StringPath;
    securityLongId: StringPath;
    securitySmallName: StringPath;
    series: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    tenor: StringPath;
    uniqueSecurityLongId: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QBondSecurity extends EntityPathBase<BondSecurity> {
    _super: QFixedIncomeSecurity;
    announcementDate: DateTimePath<string>;
    annualCouponFrequency: NumberPath<number>;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    bondSector: EnumPath<BondSector>;
    bookRunner: StringPath;
    calculationType: StringPath;
    coupon: NumberPath<string>;
    couponDateBasis: EnumPath<CouponDateBasis>;
    couponType: EnumPath<CouponType>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    creditRating: EnumPath<CreditRating>;
    dayCountBasis: EnumPath<DayCountBasis>;
    exchange: StringPath;
    figi: StringPath;
    firstCouponDate: DateTimePath<string>;
    firstSettleDate: DateTimePath<string>;
    fixedIncomeClass: EnumPath<BondFixedIncomeAssetClass>;
    id: ComparablePath<string>;
    idNumber: StringPath;
    interestAccrualDate: DateTimePath<string>;
    isin: StringPath;
    issueAmount: NumberPath<string>;
    issueDate: DateTimePath<string>;
    issuePrice: NumberPath<string>;
    issuer: StringPath;
    issuerIndustry: StringPath;
    issuerShortName: StringPath;
    issueType: StringPath;
    issueYield: NumberPath<string>;
    marketCapitalCategory: EnumPath<MarketCapitalCategory>;
    marketIssue: StringPath;
    maturityDate: DateTimePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    pricingDate: DateTimePath<string>;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    securityId: StringPath;
    securityLongId: StringPath;
    securitySmallName: StringPath;
    series: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    tenor: StringPath;
    uniqueSecurityLongId: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QCISSecurity extends EntityPathBase<CISSecurity> {
    _super: QSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    companyName: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    fundManager: StringPath;
    id: ComparablePath<string>;
    instrumentSector: EnumPath<InstrumentSector>;
    marketCapitalCategory: EnumPath<MarketCapitalCategory>;
    modifiedBy: StringPath;
    name: StringPath;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    securityId: StringPath;
    securityLongId: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QETFSecurity extends EntityPathBase<ETFSecurity> {
    _super: QSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    fundHouse: StringPath;
    fundManager: StringPath;
    id: ComparablePath<string>;
    instrumentSector: EnumPath<InstrumentSector>;
    isin: StringPath;
    market: StringPath;
    marketCapitalCategory: EnumPath<MarketCapitalCategory>;
    modifiedBy: StringPath;
    name: StringPath;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    securityId: StringPath;
    securityLongId: StringPath;
    snapshotStartDate: DateTimePath<string>;
    symbol: StringPath;
    taskQueueName: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QEquitySecurity extends EntityPathBase<EquitySecurity> {
    _super: QSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    companyName: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    instrumentSector: EnumPath<InstrumentSector>;
    isin: StringPath;
    market: StringPath;
    marketCapitalCategory: EnumPath<MarketCapitalCategory>;
    modifiedBy: StringPath;
    name: StringPath;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    securityId: StringPath;
    securityLongId: StringPath;
    snapshotStartDate: DateTimePath<string>;
    stockStyle: EnumPath<StockStyle>;
    symbol: StringPath;
    taskQueueName: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QFXSecurity extends EntityPathBase<FXSecurity> {
    _super: QSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    instrumentSector: EnumPath<InstrumentSector>;
    marketCapitalCategory: EnumPath<MarketCapitalCategory>;
    modifiedBy: StringPath;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    securityId: StringPath;
    securityLongId: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QFixedDepositSecurity extends EntityPathBase<FixedDepositSecurity> {
    _super: QFixedIncomeSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    bookRunner: StringPath;
    calculationType: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    dayCountBasis: EnumPath<DayCountBasis>;
    exchange: StringPath;
    figi: StringPath;
    firstSettleDate: DateTimePath<string>;
    fixedIncomeClass: EnumPath<FDFixedIncomeAssetClass>;
    id: ComparablePath<string>;
    idNumber: StringPath;
    instrumentSector: EnumPath<InstrumentSector>;
    interestAccrualDate: DateTimePath<string>;
    issueDate: DateTimePath<string>;
    issuePrice: NumberPath<string>;
    issuer: StringPath;
    issueYield: NumberPath<string>;
    marketCapitalCategory: EnumPath<MarketCapitalCategory>;
    marketIssue: StringPath;
    maturityDate: DateTimePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    pricingDate: DateTimePath<string>;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    returnRate: NumberPath<string>;
    securityId: StringPath;
    securityLongId: StringPath;
    series: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    tenor: StringPath;
    uniqueSecurityLongId: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QFixedIncomeSecurity extends EntityPathBase<FixedIncomeSecurity> {
    _super: QSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    dayCountBasis: EnumPath<DayCountBasis>;
    id: ComparablePath<string>;
    issueDate: DateTimePath<string>;
    maturityDate: DateTimePath<string>;
    modifiedBy: StringPath;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    securityId: StringPath;
    securityLongId: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    uniqueSecurityLongId: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QIndexSecurity extends EntityPathBase<IndexSecurity> {
    _super: QSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    distributionType: EnumPath<IndexDistributionType>;
    id: ComparablePath<string>;
    initialCapital: NumberPath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    returnType: EnumPath<IndexReturnType>;
    securityId: StringPath;
    securityLongId: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QProductIdentifier extends EntityPathBase<ProductIdentifier> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
    value: StringPath;
}

export class QREPOSecurity extends EntityPathBase<REPOSecurity> {
    _super: QFixedIncomeSecurity;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    dayCountBasis: EnumPath<DayCountBasis>;
    id: ComparablePath<string>;
    instrumentSector: EnumPath<InstrumentSector>;
    issueDate: DateTimePath<string>;
    marketCapitalCategory: EnumPath<MarketCapitalCategory>;
    maturityDate: DateTimePath<string>;
    modifiedBy: StringPath;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    repoType: EnumPath<RepoType>;
    returnRate: NumberPath<string>;
    securityId: StringPath;
    securityLongId: StringPath;
    sellYield: NumberPath<string>;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    uniqueSecurityLongId: StringPath;
    updatedOn: DateTimePath<string>;
    yield: NumberPath<string>;
}

export class QSecurity extends EntityPathBase<Security> {
    _super: QBaseAuditableUUID;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    securityId: StringPath;
    securityLongId: StringPath;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QSecurityAggQuoteSnapshot extends EntityPathBase<SecurityAggQuoteSnapshot> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    date: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    securityId: ComparablePath<string>;
    updatedOn: DateTimePath<string>;
}

export class QSecuritySearchAttribute extends EntityPathBase<SecuritySearchAttribute> {
    assetClass: EnumPath<AssetClass>;
    attributeType: StringPath;
    attributeValue: StringPath;
    id: StringPath;
    securityId: ComparablePath<string>;
}

export class QStructuredTrade extends EntityPathBase<StructuredTrade> {
    _super: QFixedIncomeSecurity;
    annualizedPerformance: NumberPath<string>;
    archived: BooleanPath;
    assetClass: EnumPath<AssetClass>;
    bookDate: DateTimePath<string>;
    counterPartyCSDFee: NumberPath<string>;
    counterPartyPayout: NumberPath<string>;
    counterPartyShare: NumberPath<string>;
    couponPayment: NumberPath<string>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    dayCountBasis: EnumPath<DayCountBasis>;
    fundingCost: NumberPath<string>;
    fundingCostPercentage: NumberPath<string>;
    grossSpread: NumberPath<string>;
    id: ComparablePath<string>;
    issueDate: DateTimePath<string>;
    maturityDate: DateTimePath<string>;
    modifiedBy: StringPath;
    netSpread: NumberPath<string>;
    productIdentifiers: ListPath<ProductIdentifier, QProductIdentifier>;
    profitLoss: NumberPath<string>;
    profitSharingPercentage: NumberPath<string>;
    purchaseCleanPrice: NumberPath<string>;
    purchaseCleanPricePercentage: NumberPath<string>;
    purchaseConsideration: NumberPath<string>;
    purchaseCSDFee: NumberPath<string>;
    purchaseCSDPayer: EnumPath<CSDPayer>;
    purchaseDirtyPricePercentage: NumberPath<string>;
    purchaseSystemCleanPrice: NumberPath<string>;
    purchaseSystemDirtyPrice: NumberPath<string>;
    purchaseYield: NumberPath<string>;
    quantity: NumberPath<string>;
    securityId: StringPath;
    securityLongId: StringPath;
    selfCSDFee: NumberPath<string>;
    selfPayout: NumberPath<string>;
    selfShare: NumberPath<string>;
    sellCleanPrice: NumberPath<string>;
    sellCleanPricePercentage: NumberPath<string>;
    sellConsideration: NumberPath<string>;
    sellCSDFee: NumberPath<string>;
    sellCSDPayer: EnumPath<CSDPayer>;
    sellDirtyPricePercentage: NumberPath<string>;
    sellSystemCleanPrice: NumberPath<string>;
    sellSystemDirtyPrice: NumberPath<string>;
    sellYield: NumberPath<string>;
    snapshotStartDate: DateTimePath<string>;
    taskQueueName: StringPath;
    uniqueSecurityLongId: StringPath;
    updatedOn: DateTimePath<string>;
    ytdPerformance: NumberPath<string>;
}

export class REPOSecurity extends FixedIncomeSecurity {
    underlyingAsset: Security;
    yield: string;
    sellYield: string;
    repoType: RepoType;
    returnRate: string;
    instrumentSector: InstrumentSector;
    marketCapitalCategory: MarketCapitalCategory;
}

export class SecurityAggQuoteSnapshot extends BaseAuditableUUID {
    securityId: string;
    date: string;
}

export class SecuritySearchAttribute {
    id: string;
    securityId: string;
    assetClass: AssetClass;
    attributeValue: string;
    attributeType: string;
}

export class StructuredTrade extends FixedIncomeSecurity {
    underlyingAsset: Security;
    bookDate: string;
    quantity: string;
    purchaseYield: string;
    sellYield: string;
    fundingCostPercentage: string;
    profitSharingPercentage: string;
    purchaseCSDPayer: CSDPayer;
    sellCSDPayer: CSDPayer;
    counterParty: CounterParty;
    executingAccount: Account;
    purchaseConsideration: string;
    couponPayment: string;
    sellConsideration: string;
    purchaseCSDFee: string;
    sellCSDFee: string;
    grossSpread: string;
    fundingCost: string;
    netSpread: string;
    counterPartyShare: string;
    selfShare: string;
    counterPartyCSDFee: string;
    selfCSDFee: string;
    counterPartyPayout: string;
    selfPayout: string;
    profitLoss: string;
    ytdPerformance: string;
    annualizedPerformance: string;
    purchaseCleanPrice: string;
    purchaseDirtyPricePercentage: string;
    purchaseCleanPricePercentage: string;
    purchaseSystemCleanPrice: string;
    purchaseSystemDirtyPrice: string;
    sellCleanPrice: string;
    sellDirtyPricePercentage: string;
    sellCleanPricePercentage: string;
    sellSystemCleanPrice: string;
    sellSystemDirtyPrice: string;
}

export class QSecurityAggQuote extends EntityPathBase<SecurityAggQuote> {
    _super: QBaseAuditableUUID;
    accruedInterest: NumberPath<string>;
    assetClass: EnumPath<AssetClass>;
    avgAsk: NumberPath<string>;
    avgBid: NumberPath<string>;
    avgCleanPricePercentage: NumberPath<string>;
    avgClose: NumberPath<string>;
    avgCouponRate: NumberPath<string>;
    avgDirtyPricePercentage: NumberPath<string>;
    avgLast: NumberPath<string>;
    avgOpen: NumberPath<string>;
    avgYield: NumberPath<string>;
    baseQuantity: NumberPath<string>;
    couponMarketQuoteDate: DateTimePath<string>;
    couponMarketQuoteSources: ListPath<string, StringPath>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    currentCouponPaymentDate: DateTimePath<string>;
    date: DateTimePath<string>;
    duration: NumberPath<string>;
    id: ComparablePath<string>;
    marketQuoteDate: DateTimePath<string>;
    marketQuoteSources: ListPath<string, StringPath>;
    maxAsk: NumberPath<string>;
    maxBid: NumberPath<string>;
    maxCleanPricePercentage: NumberPath<string>;
    maxClose: NumberPath<string>;
    maxCouponRate: NumberPath<string>;
    maxDirtyPricePercentage: NumberPath<string>;
    maxLast: NumberPath<string>;
    maxOpen: NumberPath<string>;
    maxYield: NumberPath<string>;
    mDuration: NumberPath<string>;
    minAsk: NumberPath<string>;
    minBid: NumberPath<string>;
    minCleanPricePercentage: NumberPath<string>;
    minClose: NumberPath<string>;
    minCouponRate: NumberPath<string>;
    minDirtyPricePercentage: NumberPath<string>;
    minLast: NumberPath<string>;
    minOpen: NumberPath<string>;
    minYield: NumberPath<string>;
    modifiedBy: StringPath;
    nextCouponInterest: NumberPath<string>;
    nextCouponPaymentDate: DateTimePath<string>;
    shermanRatio: NumberPath<string>;
    updatedOn: DateTimePath<string>;
}

export class SecurityAggQuote extends BaseAuditableUUID {
    security: Security;
    assetClass: AssetClass;
    date: string;
    marketQuoteDate: string;
    marketQuoteSources: string[];
    couponMarketQuoteDate: string;
    couponMarketQuoteSources: string[];
    baseQuantity: string;
    duration: string;
    shermanRatio: string;
    nextCouponPaymentDate: string;
    currentCouponPaymentDate: string;
    nextCouponInterest: string;
    accruedInterest: string;
    avgLast: string;
    maxLast: string;
    minLast: string;
    avgClose: string;
    minClose: string;
    maxClose: string;
    avgOpen: string;
    minOpen: string;
    maxOpen: string;
    avgYield: string;
    minYield: string;
    maxYield: string;
    avgCleanPricePercentage: string;
    minCleanPricePercentage: string;
    maxCleanPricePercentage: string;
    avgDirtyPricePercentage: string;
    minDirtyPricePercentage: string;
    maxDirtyPricePercentage: string;
    avgAsk: string;
    minAsk: string;
    maxAsk: string;
    avgBid: string;
    minBid: string;
    maxBid: string;
    avgCouponRate: string;
    minCouponRate: string;
    maxCouponRate: string;
    mduration: string;
}

export class ColumnSettings extends BaseAuditableUUID {
    columnId: string;
    width: number;
    order: number;
    fixed: string;
    visible: boolean;
}

export class QSettingsNode extends EntityPathBase<SettingsNode> {
    _super: QBaseAuditableUUID;
    content: StringPath;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    key: StringPath;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class SettingsNode extends BaseAuditableUUID {
    key: string;
    content: string;
}

export class SSEventDetail implements Serializable {
    ssEvent: SSEvent;
    portfolioId: string;
}

export class AppliedTransactionCost extends BaseAuditableUUID implements EmptyHandleBaseError {
    transactionCost: TransactionCost;
    appliedValue: string;
}

export class QAppliedTransactionCost extends EntityPathBase<AppliedTransactionCost> {
    _super: QBaseAuditableUUID;
    appliedValue: NumberPath<string>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class QTransaction extends EntityPathBase<Transaction> {
    _super: QWithProduct;
    accruedInterest: NumberPath<string>;
    addContributionInClient: BooleanPath;
    adjustedYieldToMaturity: NumberPath<string>;
    allAppliedTransactionCost: ListPath<AppliedTransactionCost, QAppliedTransactionCost>;
    assetClass: EnumPath<AssetClass>;
    authStatus: EnumPath<TransactionAuthStatus>;
    bankTransactionEventId: ComparablePath<string>;
    calculationBase: EnumPath<CalculationBase>;
    cleanPrice: NumberPath<string>;
    cleanPricePercentage: NumberPath<string>;
    consideration: NumberPath<string>;
    considerationWithCurrency: NumberPath<string>;
    corporateAction: EnumPath<CorporateAction>;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    csdFee: NumberPath<string>;
    daysToMaturity: NumberPath<number>;
    dc: NumberPath<string>;
    description: StringPath;
    dirtyPricePercentage: NumberPath<string>;
    dp: NumberPath<string>;
    effectiveIssueDate: DateTimePath<string>;
    entryExitLoadPercentage: NumberPath<string>;
    executionErrorMessage: StringPath;
    fxRate: NumberPath<string>;
    glType: BooleanPath;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    nextInterestPaymentDate: DateTimePath<string>;
    noOfDaysBetweenIssueAndMaturity: NumberPath<number>;
    noOfHalfYearsBetweenDealDateAndEffectiveDate: NumberPath<number>;
    noOfHalfYearsBetweenDealDateAndNextInterestPaymentDate: NumberPath<string>;
    noOfHalfYearsBetweenNextInterestPaymentDateAndMaturityDate: NumberPath<string>;
    oldTransactionId: ComparablePath<string>;
    onePlusU: NumberPath<string>;
    orderSide: EnumPath<OrderSide>;
    price: NumberPath<string>;
    principalAmount: NumberPath<string>;
    quantity: NumberPath<string>;
    rootTransaction: BooleanPath;
    securityChangeReprocessTransaction: BooleanPath;
    systemCleanPrice: NumberPath<string>;
    systemDirtyPrice: NumberPath<string>;
    systemGenerated: BooleanPath;
    transactionCostTransactions: ListPath<Transaction, QTransaction>;
    transactionExecuted: BooleanPath;
    transactionStatus: EnumPath<ExecutionStatus>;
    unitPayoutPrice: NumberPath<string>;
    unitPayoutQuantity: NumberPath<string>;
    updatedOn: DateTimePath<string>;
    valueDate: DateTimePath<string>;
    yield: NumberPath<string>;
}

export class QTransactionAudit extends EntityPathBase<TransactionAudit> {
    authStatus: EnumPath<TransactionAuthStatus>;
    createdBy: StringPath;
    createdByUserId: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    executionStatus: EnumPath<ExecutionStatus>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
}

export class Transaction extends WithProduct implements DefaultHandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
    orderSide: OrderSide;
    assetClass: AssetClass;
    calculationBase: CalculationBase;
    quantity: string;
    price: string;
    considerationWithCurrency: string;
    consideration: string;
    fxRate: string;
    fxConversionBaseCurrency: Currency;
    yield: string;
    valueDate: string;
    counterParty: CounterParty;
    rootTransaction: boolean;
    glType: boolean;
    transactionType: TransactionType;
    systemGenerated: boolean;
    transactionStatus: ExecutionStatus;
    authStatus: TransactionAuthStatus;
    corporateAction: CorporateAction;
    oldTransactionId: string;
    adjustedYieldToMaturity: string;
    noOfDaysBetweenIssueAndMaturity: number;
    effectiveIssueDate: string;
    daysToMaturity: number;
    noOfHalfYearsBetweenDealDateAndEffectiveDate: number;
    nextInterestPaymentDate: string;
    noOfHalfYearsBetweenDealDateAndNextInterestPaymentDate: string;
    noOfHalfYearsBetweenNextInterestPaymentDateAndMaturityDate: string;
    onePlusU: string;
    dc: string;
    dp: string;
    accruedInterest: string;
    systemCleanPrice: string;
    systemDirtyPrice: string;
    cleanPrice: string;
    dirtyPricePercentage: string;
    cleanPricePercentage: string;
    csdFee: string;
    applyCSDFee: boolean;
    description: string;
    transactionExecuted: boolean;
    principalAmount: string;
    unitPayoutPortfolio: Portfolio;
    unitPayoutQuantity: string;
    unitPayoutPrice: string;
    entryExitLoadPercentage: string;
    addContributionInClient: boolean;
    bankTransactionEventId: string;
    executionErrorMessage: string;
    securityChangeReprocessTransaction: boolean;
}

export class TransactionAudit implements Serializable {
    id: string;
    transaction: Transaction;
    executionStatus: ExecutionStatus;
    authStatus: TransactionAuthStatus;
    description: string;
    createdByUserId: string;
    createdOn: string;
    modifiedBy: string;
    createdBy: string;
    updatedOn: string;
}

export class QUserFavourite extends EntityPathBase<UserFavourite> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    favouriteId: ComparablePath<string>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    updatedOn: DateTimePath<string>;
    userFavouriteType: EnumPath<UserFavouriteType>;
    userId: StringPath;
}

export class UserFavourite extends BaseAuditableUUID {
    favouriteId: string;
    userId: string;
    userFavouriteType: UserFavouriteType;
}

export class QWidget extends EntityPathBase<Widget> {
    _super: QBaseAuditableUUID;
    createdBy: StringPath;
    createdOn: DateTimePath<string>;
    description: StringPath;
    height: NumberPath<number>;
    id: ComparablePath<string>;
    modifiedBy: StringPath;
    name: StringPath;
    updatedOn: DateTimePath<string>;
    widgetConfig: StringPath;
    width: NumberPath<number>;
    xLocation: NumberPath<number>;
    yLocation: NumberPath<number>;
}

export class Widget extends BaseAuditableUUID {
    dashboardConfig: DashboardConfig;
    width: number;
    height: number;
    widgetConfig: string;
    name: string;
    description: string;
    xLocation: number;
    yLocation: number;
}

export class QWorkflowQueueConfig extends EntityPathBase<WorkflowQueueConfig> {
    defaultQueue: BooleanPath;
    queueName: StringPath;
    workerCount: NumberPath<number>;
}

export class WorkflowQueueConfig {
    queueName: string;
    workerCount: number;
    defaultQueue: boolean;
}

export class CashTransactionImportCsv extends BaseError implements DefaultHandleBaseError {
    portfolioCode: number;
    transactionType: string;
    credit: string;
    debit: string;
    fxRate: string;
    executingAccount: string;
    toAccount: string;
    valueDate: string;
    description: string;
    liabilityName: string;
    incomeName: string;
    unitPayoutPortfolioCode: number;
    unitPayoutQuantity: string;
    unitPayoutPrice: string;
    fxConversionBaseCurrency: string;
    addContributionInClient: boolean;
    entryExitLoad: string;
}

export class ClientImportCSV extends BaseError implements DefaultHandleBaseError {
    name: string;
    description: string;
    subType: string;
    type: string;
    createPortfolio: boolean;
    clientAddress: string;
    clientTelephone: string;
    currency: string;
    clientEmail: string;
}

export interface DefaultHandleBaseError extends HandleBaseError {
    errorRawString: { [index: string]: string };
    errorMessage: { [index: string]: string };
    error: boolean;
}

export interface EmptyHandleBaseError extends HandleBaseError {
}

export interface HandleAdditionalFields {
}

export interface HandleBaseError {
}

export class ProductCSV extends BaseError implements DefaultHandleBaseError {
    isin: string;
    name: string;
    securityId: string;
}

export class MarketQuoteImportCsv extends ProductCSV {
    timestamp: string;
    last: string;
    close: string;
    open: string;
    yield: string;
    ask: string;
    bid: string;
    cleanPricePercentage: string;
    couponRate: string;
    source: string;
    priceBase: CalculationBase;
}

export class MarketQuoteImportResponse {
    marketQuotes: MarketQuoteDTO[];
}

export class SecurityImportCsv extends ProductCSV {
    assetClass: AssetClass;
    issuer: string;
    issueDate: string;
    maturityDate: string;
    coupon: string;
    baseCurrency: string;
    targetCurrency: string;
    fundManager: string;
    symbol: string;
    companyName: string;
    fundHouse: string;
    tenor: string;
    returnRate: string;
    underlyingAsset: string;
    yield: string;
    country: string;
    market: string;
    fixedIncomeClass: string;
    issueType: string;
    issueAmount: string;
    issuerIndustry: string;
    announcementDate: string;
    couponType: CouponType;
    annualCouponFrequency: number;
    firstCouponDate: string;
    sellYield: string;
    repoType: RepoType;
    dayCountBasis: DayCountBasis;
    couponDateBasis: CouponDateBasis;
    marketIssue: string;
    issueYield: string;
    calculationType: string;
    pricingDate: string;
    interestAccrualDate: string;
    firstSettleDate: string;
    series: string;
    issuePrice: string;
    exchange: string;
    bookRunner: string;
    idNumber: string;
    figi: string;
    issuerShortName: string;
    securitySmallName: string;
    marketCapitalization: MarketCapitalCategory;
    stockStyle: StockStyle;
    creditRating: CreditRating;
    instrumentSector: InstrumentSector;
    bondSector: BondSector;
}

export class SecurityImportResponse {
    securities: { [P in AssetClass]?: SecurityDTO[] };
}

export class StructuredTradeImportCsv extends ProductCSV {
    portfolioCode: number;
    bookDate: string;
    purchaseDate: string;
    sellDate: string;
    quantity: string;
    purchaseYield: string;
    sellYield: string;
    fundingCostPercentage: string;
    profitSharingPercentage: string;
    purchaseCSDPayer: CSDPayer;
    sellCSDPayer: CSDPayer;
    counterParty: string;
    executingAccount: string;
    dayCountBasis: DayCountBasis;
}

export class TransactionImportCsv extends ProductCSV implements HandleAdditionalFields {
    portfolioCode: number;
    executingAccount: string;
    side: OrderSide;
    quantity: string;
    price: string;
    yield: string;
    cleanPricePercentage: string;
    valueDate: string;
    counterParty: string;
    applyCSDFee: boolean;
    principalAmount: string;
    additionalFieldMap: { [index: string]: string };
}

export class TransactionImportResponse<T> {
    transactionType: TransactionImportType;
    transactions: T[];
}

export class LedgerSearchRequest extends AbstractSearchRequest {
    portfolioId: string;
    accountTypeId: string;
    accountSubTypeId: string;
    transactionTypeIds: string[];
    accountIds: string[];
    startDate: string;
    endDate: string;
    glSearch: boolean;
    currencyCode: string;
}

export class AccountMapper {
}

export class AccountMapperImpl extends AccountMapper {
}

export class AttachProduct {
}

export class BankTransactionEventConfigMapper {
}

export class BankTransactionEventMapper {
}

export class BankTransactionEventMapperImpl extends BankTransactionEventMapper {
}

export class ClientMapper {
}

export class ClientMapperImpl extends ClientMapper {
    portfolios: ClientDTO;
}

export class ClientMapperResolver {
}

export class DashboardConfigResolver {
}

export class DashboardMapper {
}

export class DashboardMapperImpl extends DashboardMapper {
}

export interface EmailMapper {
}

export class EmailMapperImpl implements EmailMapper {
}

export class KYCMapper {
}

export class KYCMapperImpl extends KYCMapper {
}

export class KYCMapperResolver {
}

export class MarketQuoteMapper extends AttachProduct {
}

export class MarketQuoteMapperImpl extends MarketQuoteMapper {
}

export interface PortfolioIncomeMapper {
}

export class PortfolioIncomeMapperImpl implements PortfolioIncomeMapper {
}

export class PortfolioIncomeMapperResolver {
}

export interface PortfolioLiabilityMapper {
}

export class PortfolioLiabilityMapperImpl implements PortfolioLiabilityMapper {
}

export class PortfolioLiabilityMapperResolver {
}

export class PortfolioMapper {
}

export class PortfolioMapperImpl extends PortfolioMapper {
}

export class PortfolioMapperResolver {
}

export class PortfolioPayableMapper {
}

export class PortfolioPayableMapperImpl extends PortfolioPayableMapper {
}

export class PortfolioReceivableMapper {
}

export class PortfolioReceivableMapperImpl extends PortfolioReceivableMapper {
}

export class PositionMapper {
}

export class PositionMapperImpl extends PositionMapper {
}

export class RepoTradeMapper extends AttachProduct {
}

export class RepoTradeMapperImpl extends RepoTradeMapper {
    repotradeBaseCurrency: REPOSecurity;
}

export class SecurityMapper {
}

export class SecurityMapperImpl extends SecurityMapper {
    structuredTradeBaseCurrency: StructuredTrade;
}

export class SecurityMapperResolver {
}

export class StructuredTradeMapper extends AttachProduct {
}

export class StructuredTradeMapperImpl extends StructuredTradeMapper {
    structuredTradeBaseCurrency: StructuredTrade;
}

export interface TransactionAuditMapper {
}

export class TransactionAuditMapperImpl implements TransactionAuditMapper {
    id: TransactionAudit;
}

export class TransactionMapper extends AttachProduct {
}

export class TransactionMapperImpl extends TransactionMapper {
}

export class UserMapper {
}

export class UserMapperImpl extends UserMapper {
}

export class WidgetMapper {
}

export class WidgetMapperImpl extends WidgetMapper {
}

export class AccountMapperResolver {
}

export class AccountTypeMapperResolver {
}

export interface ClientSubTypeMapper {
}

export class ClientSubTypeMapperImpl implements ClientSubTypeMapper {
}

export class ClientSubTypeMapperResolver {
}

export class ClientTypeMapperResolver {
}

export class CounterPartyMapperResolver {
}

export class CountryMapperResolver {
}

export class CurrencyMapperResolver {
}

export class CustodianMapperResolver {
}

export interface TransactionCostMapper {
}

export class TransactionCostMapperImpl implements TransactionCostMapper {
    transactionCostAssetClass: TransactionCostDTO;
}

export class TransactionCostMapperResolver {
}

export class TransactionTypeMapperResolver {
}

export class MarketQuoteSearchRequest extends AbstractSearchRequest {
    startDate: string;
    endDate: string;
    status: ExecutionStatus;
}

export class PayableReceivableSearchRequest extends DateRangeSearchRequest {
    all: boolean;
    subType: string;
}

export class PortfolioSummarySearchRequest {
    startDate: string;
    endDate: string;
}

export class TransactionSearchRequest extends AbstractSearchRequest {
    startDate: string;
    endDate: string;
    orderSide: OrderSide;
    corporateAction: CorporateAction;
    accountId: string;
    authStatuses: TransactionAuthStatus[];
    executionStatuses: ExecutionStatus[];
    showSystemGeneratedTransaction: boolean;
    transactionTypeIds: string[];
}


export class OrderSearchRequest extends AbstractSearchRequest {
    startDate: string;
    endDate: string;
    orderStatuses: any;
}


export class UnitHolderReportSearchRequest {
    startDate: string;
    endDate: string;
    clientPortfolioIds: string[];
}

export class Pair<S, T> {
    first: S;
    second: T;
}

export interface Serializable {
}

export class ComparableExpressionBase<T> extends SimpleExpression<T> {
}

export class ComparableExpression<T> extends ComparableExpressionBase<T> {
}

export class LiteralExpression<T> extends ComparableExpression<T> {
}

export class StringExpression extends LiteralExpression<string> {
}

export class StringPath extends StringExpression implements Path<string> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export class TemporalExpression<T> extends LiteralExpression<T> {
}

export class DateTimeExpression<T> extends TemporalExpression<T> {
}

export class DateTimePath<T> extends DateTimeExpression<T> implements Path<T> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export class ComparablePath<T> extends ComparableExpression<T> implements Path<T> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export interface AnnotatedElement {
    annotations: Annotation[];
    declaredAnnotations: Annotation[];
}

export interface Path<T> extends Expression<T> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export class PathMetadata implements Serializable {
    element: any;
    parent: Path<any>;
    rootPath: Path<any>;
    pathType: PathType;
    root: boolean;
    name: string;
}

export class Class<T> implements Serializable, GenericDeclaration, Type, AnnotatedElement {
    typeParameters: TypeVariable<any>[];
    typeName: string;
    annotations: Annotation[];
    declaredAnnotations: Annotation[];
}

export class BooleanExpression extends LiteralExpression<boolean> implements Predicate {
}

export class BooleanPath extends BooleanExpression implements Path<boolean> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export class EnumExpression<T> extends LiteralExpression<T> {
}

export class EnumPath<T> extends EnumExpression<T> implements Path<T> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export class NumberExpression<T> extends ComparableExpressionBase<T> {
}

export class NumberPath<T> extends NumberExpression<T> implements Path<T> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export class CollectionExpressionBase<T, E> extends DslExpression<T> implements CollectionExpression<T, E> {
    elementType: Class<E>;
}

export class CollectionPathBase<C, E, Q> extends CollectionExpressionBase<C, E> implements Path<C> {
    annotatedElement: AnnotatedElement;
    root: Path<any>;
    metadata: PathMetadata;
}

export class ListPath<E, Q> extends CollectionPathBase<E[], E, Q> implements ListExpression<E, Q> {
}

export interface Annotation {
}

export interface GenericDeclaration extends AnnotatedElement {
    typeParameters: TypeVariable<any>[];
}

export interface Type {
    typeName: string;
}

export interface Expression<T> extends Serializable {
    type: Class<T>;
}

export interface TypeVariable<D> extends Type, AnnotatedElement {
    bounds: Type[];
    genericDeclaration: D;
    annotatedBounds: AnnotatedType[];
    name: string;
}

export interface Predicate extends Expression<boolean> {
}

export interface ListExpression<E, Q> extends CollectionExpression<E[], E> {
}

export interface AnnotatedType extends AnnotatedElement {
    type: Type;
}

export interface EntityPath<T> extends Path<T> {
}

export interface CollectionExpression<T, E> extends ParameterizedExpression<T> {
}

export interface ParameterizedExpression<T> extends Expression<T> {
}

export enum AnalyticsSource {
    POSITION = "POSITION",
    SUMMARY = "SUMMARY",
}

export enum ChartType {
    BARCHART = "BARCHART",
    LINECHART = "LINECHART",
}

export enum FieldType {
    NUMERIC = "NUMERIC",
    STRING = "STRING",
    DATE = "DATE",
    COMPLEX = "COMPLEX",
}

export enum PositionExposureBy {
    MATURITY_TERM = "MATURITY_TERM",
    MATURITY_DAYS = "MATURITY_DAYS",
    BOND_SECTOR = "BOND_SECTOR",
    STOCK_SECTOR = "STOCK_SECTOR",
    MARKET_CAPITAL_CATEGORY = "MARKET_CAPITAL_CATEGORY",
    CREDIT_RATING = "CREDIT_RATING",
    STOCK_STYLE = "STOCK_STYLE",
}

export enum SearchResultType {
    CLIENT = "CLIENT",
    PORTFOLIO = "PORTFOLIO",
}

export enum UserFavouriteType {
    PORTFOLIO = "PORTFOLIO",
}

export enum PositionType {
    ASSET = "ASSET",
    CASH = "CASH",
    PAYABLE = "PAYABLE",
    RECEIVABLE = "RECEIVABLE",
}

export enum AppConstants {
    MAX_FAVOURITE_PORTFOLIO = 15,
    FUND_WITHDRAWAL_PAYOUT_LIABILITY_NAME = "Fund Withdrawal Payout",
    CONTRIBUTION_RECEIVABLE_NAME = "Contribution Received",
}

export enum ExecutionStatus {
    AMENDED = "AMENDED",
    AMENDING = "AMENDING",
    ARCHIVED = "ARCHIVED",
    COMPLETED = "COMPLETED",
    DELETED = "DELETED",
    DELETING = "DELETING",
    SUBMITTED = "SUBMITTED",
    WORKING = "WORKING",
}


export enum OrderStatus {
    FILLED = "FILLED",
    SUBMITTED = "SUBMITTED",
    PENDING = "PENDING",
    EXPIRED = "EXPIRED",
    PARTIALLY_FILLED = "PARTIALLY_FILLED",
    REJECTED = "REJECTED",
    CANCELLED = "CANCELLED",
}



export enum RateType {
    FIXED = "FIXED",
    PERCENTAGE = "PERCENTAGE",
}

export enum SSEvent {
    UPDATE_MARKET_QUOTES = "UPDATE_MARKET_QUOTES",
    UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS",
    UPDATE_PORTFOLIO_SUMMARY = "UPDATE_PORTFOLIO_SUMMARY",
    UPDATE_POSITIONS = "UPDATE_POSITIONS",
}

export enum InterestRateType {
    BANK = "BANK",
    OVERDRAFT = "OVERDRAFT",
}

export enum TransactionTypeCode {
    ACCOUNT_TO_ACCOUNT = "ACCOUNT_TO_ACCOUNT",
    CSD_FEE = "CSD_FEE",
    COUPON_PAYMENT = "COUPON_PAYMENT",
    CONTRIBUTION = "CONTRIBUTION",
    CONTRIBUTION_RECEIVABLE = "CONTRIBUTION_RECEIVABLE",
    COUNTER_PARTY_PAYOUT = "COUNTER_PARTY_PAYOUT",
    ENTRY_EXIT_LOAD_COST = "ENTRY_EXIT_LOAD_COST",
    ENTRY_EXIT_LOAD_INCOME = "ENTRY_EXIT_LOAD_INCOME",
    FUND_WITHDRAWAL = "FUND_WITHDRAWAL",
    FUND_WITHDRAWAL_LIABILITY = "FUND_WITHDRAWAL_LIABILITY",
    LIABILITY = "LIABILITY",
    RECEIVABLE = "RECEIVABLE",
    INCOME_RECEIVED_BY_CASH = "INCOME_RECEIVED_BY_CASH",
    LIABILITY_PAYOUT_BY_CASH = "LIABILITY_PAYOUT_BY_CASH",
    LIABILITY_PAYOUT_BY_SHARE = "LIABILITY_PAYOUT_BY_SHARE",
    SECURITY_PURCHASE = "SECURITY_PURCHASE",
    SECURITY_SELL = "SECURITY_SELL",
    SECURITY_MATURITY = "SECURITY_MATURITY",
    TRANSACTION_COST = "TRANSACTION_COST",
    USER_GENERATED = "USER_GENERATED",
}

export enum AppConfigKey {
    BANK_TRANSACTION_EVENT_POLL_WAIT_TIME = "BANK_TRANSACTION_EVENT_POLL_WAIT_TIME",
    MIGRATION_REPO_CSD_FEE = "MIGRATION_REPO_CSD_FEE",
    MIN_RETURN_PERCENTAGE = "MIN_RETURN_PERCENTAGE",
    BENCHMARK_CONFIDENCE_LEVEL = "BENCHMARK_CONFIDENCE_LEVEL",
    MAX_SUMMARY_HISTORY = "MAX_SUMMARY_HISTORY",
    MIGRATION_FAULTY_TRANSACTION = "MIGRATION_FAULTY_TRANSACTION",
    MIGRATION_MATURITY_TRANSACTION_TYPE = "MIGRATION_MATURITY_TRANSACTION_TYPE",
    MIGRATION_CLIENT_PORTFOLIO_LEDGER_ENTRY = "MIGRATION_CLIENT_PORTFOLIO_LEDGER_ENTRY",
    MIGRATION_LIABILITY_PAYOUT_SHARE_LEDGER_ENTRY = "MIGRATION_LIABILITY_PAYOUT_SHARE_LEDGER_ENTRY",
    MIGRATION_SECURITY_SNAPSHOT_DATE = "MIGRATION_SECURITY_SNAPSHOT_DATE",
    MIGRATION_POSITION_LOCK = "MIGRATION_POSITION_LOCK",
    MIGRATION_SECURITY_ID = "MIGRATION_SECURITY_ID",
    DEFAULT_ACCOUNT_TYPE_ID = "DEFAULT_ACCOUNT_TYPE_ID",
    DEFAULT_ACCOUNT_GL_TYPE = "DEFAULT_ACCOUNT_GL_TYPE",
    DEFAULT_CUSTODIAN_ID = "DEFAULT_CUSTODIAN_ID",
    DEFAULT_ENTRY_LOAD_PERCENTAGE = "DEFAULT_ENTRY_LOAD_PERCENTAGE",
    DEFAULT_CSD_FEE_PERCENTAGE = "DEFAULT_CSD_FEE_PERCENTAGE",
    DEFAULT_FINANCIAL_START_DATE = "DEFAULT_FINANCIAL_START_DATE",
    DEFAULT_PORTFOLIO_START_DATE = "DEFAULT_PORTFOLIO_START_DATE",
    DEFAULT_UNIT_PRICING_STRATEGY = "DEFAULT_UNIT_PRICING_STRATEGY",
    DEFAULT_VALUATION_STRATEGY = "DEFAULT_VALUATION_STRATEGY",
    DEFAULT_BOND_CURRENT_PRICE_STRATEGY = "DEFAULT_BOND_CURRENT_PRICE_STRATEGY",
    DEFAULT_INITIAL_UNIT_PRICE = "DEFAULT_INITIAL_UNIT_PRICE",
    DEFAULT_CURRENCY_CODE = "DEFAULT_CURRENCY_CODE",
    DEFAULT_BENCHMARK_ID = "DEFAULT_BENCHMARK_ID",
    MARKET_QUOTE_VALID_UPTO_DAYS = "MARKET_QUOTE_VALID_UPTO_DAYS",
    MAX_ALLOWED_TASKS = "MAX_ALLOWED_TASKS",
    PENDING_ACTION_SCHEDULER_MANDATORY_RUN_AFTER_MINUTES = "PENDING_ACTION_SCHEDULER_MANDATORY_RUN_AFTER_MINUTES",
    RUN_ONCE_A_DAY_SCHEDULER = "RUN_ONCE_A_DAY_SCHEDULER",
    FX_MARKET_QUOTE_SYNC_SCHEDULER = "FX_MARKET_QUOTE_SYNC_SCHEDULER",
    RUN_MULTIPLE_TIMES_A_DAY_SCHEDULER = "RUN_MULTIPLE_TIMES_A_DAY_SCHEDULER",
    DEFAULT_EMAIL_SUBJECT = "DEFAULT_EMAIL_SUBJECT",
    DEFAULT_EMAIL_HEADER = "DEFAULT_EMAIL_HEADER",
    DEFAULT_EMAIL_TYPE = "DEFAULT_EMAIL_TYPE",
}

export enum BankSource {
    SCB = "SCB",
}

export enum BankTransactionEventStatus {
    PENDING = "PENDING",
    SKIPPED = "SKIPPED",
    PARTIALLY_PROCESSED = "PARTIALLY_PROCESSED",
    PROCESSED = "PROCESSED",
}

export enum ProcessingStatus {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export enum Department {
    ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
    BROKERAGE = "BROKERAGE",
}

export enum ClientSummaryStatus {
    AMENDING = "AMENDING",
    COMPLETED = "COMPLETED",
}

export enum EmailContentType {
    HTML = "HTML",
    TEXT = "TEXT",
}

export enum EmailStatus {
    SENT = "SENT",
    FAILED = "FAILED",
}

export enum EmailType {
    DEFAULT = "DEFAULT",
    UNIT_HOLDER_REPORT = "UNIT_HOLDER_REPORT",
}

export enum KYCStatus {
    VERIFIED = "VERIFIED",
    REJECTED = "REJECTED",
    SUBMITTED = "SUBMITTED",
}

export enum QuestionType {
    DATE = "DATE",
    MULTI_LINE = "MULTI_LINE",
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    NUMERIC = "NUMERIC",
    SINGLE_LINE = "SINGLE_LINE",
}

export enum NotificationType {
    SUCCESS = "SUCCESS",
    INFO = "INFO",
    WARNING = "WARNING",
    ERROR = "ERROR",
}

export enum BondCurrentPriceStrategy {
    NORMAL = "NORMAL",
    AMORTIZED = "AMORTIZED",
}

export enum PortfolioStatus {
    ARCHIVED = "ARCHIVED",
    BETA = "BETA",
    LIVE = "LIVE",
}

export enum UnitPricingStrategy {
    BACKWARD = "BACKWARD",
    FORWARD = "FORWARD",
}

export enum ValuationStrategy {
    CURRENT_VALUE = "CURRENT_VALUE",
    MARKET_VALUE = "MARKET_VALUE",
}

export enum Frequency {
    ANNUAL = "ANNUAL",
    DAILY = "DAILY",
    MANUAL = "MANUAL",
    MONTHLY = "MONTHLY",
    SEMI_ANNUAL = "SEMI_ANNUAL",
    TRANSACTIONAL = "TRANSACTIONAL",
    WEEKLY = "WEEKLY",
    QUARTERLY = "QUARTERLY",
}

export enum LiabilityColumn {
    AUM = "AUM",
    NAV = "NAV",
}

export enum CostReceivableLiabilityType {
    COST = "COST",
    CASH = "CASH",
    LIABILITY = "LIABILITY",
    RECEIVABLE = "RECEIVABLE",
}

export enum LockStatus {
    UNLOCKED = "UNLOCKED",
    LOCKED = "LOCKED",
    LOCKING = "LOCKING",
}

export enum PortfolioSummaryStatus {
    AMENDING = "AMENDING",
    COMPLETED = "COMPLETED",
}

export enum AssetClass {
    BILL = "BILL",
    BOND = "BOND",
    CASH = "CASH",
    FX = "FX",
    INDEX = "INDEX",
    CIS = "CIS",
    EQUITY = "EQUITY",
    ETF = "ETF",
    FD = "FD",
    REPO = "REPO",
    STRUCTURED_TRADE = "STRUCTURED_TRADE",
    VENTURE_CAPITAL = "VENTURE_CAPITAL",
}

export enum BillFixedIncomeAssetClass {
    LTB = "LTB",
    TB = "TB",
}

export enum BondFixedIncomeAssetClass {
    CB = "CB",
    GB = "GB",
    LGB = "LGB",
}

export enum BondSector {
    MUNICIPAL = "MUNICIPAL",
    CORPORATE = "CORPORATE",
    GOVERNMENT = "GOVERNMENT",
}

export enum CSDPayer {
    NONE = "NONE",
    SELF = "SELF",
    COUNTER_PARTY = "COUNTER_PARTY",
}

export enum CouponDateBasis {
    DAY = "DAY",
    MONTH = "MONTH",
}

export enum CouponType {
    FIXED = "FIXED",
    FLOATING = "FLOATING",
}

export enum CreditRating {
    AAA = "AAA",
    AA = "AA",
    A = "A",
    BBB = "BBB",
    BB = "BB",
}

export enum FDFixedIncomeAssetClass {
    FD = "FD",
}

export enum IndexDistributionType {
    WEIGHT = "WEIGHT",
    QUANTITY = "QUANTITY",
}

export enum IndexReturnType {
    PRICE_RETURN = "PRICE_RETURN",
    TOTAL_RETURN = "TOTAL_RETURN",
}

export enum InstrumentSector {
    ENERGY = "ENERGY",
    MATERIALS = "MATERIALS",
    INDUSTRIALS = "INDUSTRIALS",
    UTILITIES = "UTILITIES",
    HEALTHCARE = "HEALTHCARE",
    FINANCIALS = "FINANCIALS",
    CONSUMER_DISCRETIONARY = "CONSUMER_DISCRETIONARY",
    CONSUMER_STAPLES = "CONSUMER_STAPLES",
    INFORMATION_TECHNOLOGY = "INFORMATION_TECHNOLOGY",
    COMMUNICATION_SERVICES = "COMMUNICATION_SERVICES",
}

export enum MarketCapitalCategory {
    GIANT = "GIANT",
    LARGE = "LARGE",
    MID = "MID",
    SMALL = "SMALL",
    MICRO = "MICRO",
}

export enum ProductIdentifierType {
    ISIN = "ISIN",
    NAME = "NAME",
}

export enum REPOFixedIncomeAssetClass {
    RP = "RP",
}

export enum StockStyle {
    SMALL_CAP_GROWTH = "SMALL_CAP_GROWTH",
    SMALL_CAP_BLEND = "SMALL_CAP_BLEND",
    SMALL_CAP_VALUE = "SMALL_CAP_VALUE",
    MID_CAP_GROWTH = "MID_CAP_GROWTH",
    MID_CAP_BLEND = "MID_CAP_BLEND",
}

export enum CalculationBase {
    PRICE = "PRICE",
    YIELD = "YIELD",
}

export enum CorporateAction {
    COUNTER_PARTY_PAYOUT = "COUNTER_PARTY_PAYOUT",
    COUPON = "COUPON",
    ENTRY_EXIT_LOAD_INCOME = "ENTRY_EXIT_LOAD_INCOME",
    MATURITY = "MATURITY",
    UNIT_ALLOCATION = "UNIT_ALLOCATION",
    SETTLE_CORPORATE_ACTION = "SETTLE_CORPORATE_ACTION",
}

export enum OrderSide {
    BUY = "BUY",
    SELL = "SELL",
}

export enum RepoType {
    REPO = "REPO",
    REVERSE_REPO = "REVERSE_REPO",
}

export enum TradeType {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
}

export enum TransactionAuthStatus {
    APPROVED = "APPROVED",
    PENDING_APPROVAL = "PENDING_APPROVAL",
    REJECTED = "REJECTED",
}

export enum ReportExportType {
    PDF = "PDF",
    ZIP = "ZIP",
    XLSX = "XLSX",
}

export enum SampleFileType {
    BANK_TRANSACTIONS = "BANK_TRANSACTIONS",
    CLIENTS = "CLIENTS",
    MARKET_QUOTES = "MARKET_QUOTES",
    REGULAR_TRANSACTIONS = "REGULAR_TRANSACTIONS",
    SECURITIES = "SECURITIES",
    STRUCTURED_TRADE_TRANSACTIONS = "STRUCTURED_TRADE_TRANSACTIONS",
}

export enum AuthRole {
    CREATE_MARKET_QUOTE = "CREATE_MARKET_QUOTE",
    READ_MARKET_QUOTE = "READ_MARKET_QUOTE",
    DELETE_MARKET_QUOTE = "DELETE_MARKET_QUOTE",
    UPDATE_MARKET_QUOTE = "UPDATE_MARKET_QUOTE",
    CREATE_INSTRUMENT = "CREATE_INSTRUMENT",
    READ_INSTRUMENT = "READ_INSTRUMENT",
    DELETE_INSTRUMENT = "DELETE_INSTRUMENT",
    UPDATE_INSTRUMENT = "UPDATE_INSTRUMENT",
    ADD_PORTFOLIO = "ADD_PORTFOLIO",
    EDIT_PORTFOLIO = "EDIT_PORTFOLIO",
    DELETE_PORTFOLIO = "DELETE_PORTFOLIO",
    ADD_USER = "ADD_USER",
    EDIT_USER = "EDIT_USER",
    DELETE_USER = "DELETE_USER",
    RESET_USER_PASSWORD = "RESET_USER_PASSWORD",
    USER_JOIN_CLIENT = "USER_JOIN_CLIENT",
    USER_LEAVE_CLIENT = "USER_LEAVE_CLIENT",
    ADD_CLIENT = "ADD_CLIENT",
    EDIT_CLIENT = "EDIT_CLIENT",
    DELETE_CLIENT = "DELETE_CLIENT",
    LOCK_PORTFOLIO_DAY = "LOCK_PORTFOLIO_DAY",
    TRANSACTION_AUTHORIZE = "TRANSACTION_AUTHORIZE",
    SYSTEM_DATA = "SYSTEM_DATA",
    UNLOCK_PORTFOLIO_DAY = "UNLOCK_PORTFOLIO_DAY",
}

export enum DayCountBasis {
    ACTUAL_360 = "ACTUAL_360",
    ACTUAL_364 = "ACTUAL_364",
    ACTUAL_365 = "ACTUAL_365",
    ACTUAL_ACTUAL = "ACTUAL_ACTUAL",
    EUROPE30_360 = "EUROPE30_360",
    US_PSA30_360 = "US_PSA30_360",
}

export enum TransactionImportType {
    NORMAL = "NORMAL",
    BANK_TRANSACTION = "BANK_TRANSACTION",
    STRUCTURED_TRADE = "STRUCTURED_TRADE",
}

export enum PathType {
    ARRAYVALUE = "ARRAYVALUE",
    ARRAYVALUE_CONSTANT = "ARRAYVALUE_CONSTANT",
    COLLECTION_ANY = "COLLECTION_ANY",
    DELEGATE = "DELEGATE",
    LISTVALUE = "LISTVALUE",
    LISTVALUE_CONSTANT = "LISTVALUE_CONSTANT",
    MAPVALUE = "MAPVALUE",
    MAPVALUE_CONSTANT = "MAPVALUE_CONSTANT",
    PROPERTY = "PROPERTY",
    VARIABLE = "VARIABLE",
    TREATED_PATH = "TREATED_PATH",
}
