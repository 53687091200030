export const requestHeaders = {
    'Content-Type': 'application/json',
}

// let API_END_POINT_VAL = `api.${window.location.host}`;
// switch (process.env.REACT_APP_ENV) {
//     case 'uat':
//         API_END_POINT_VAL='https://7mi1fwlx7a.execute-api.us-east-1.amazonaws.com';
//         break;
// }
// export const API_END_POINT = API_END_POINT_VAL;

export const getAPIURL = () => {
    if (process.env.NODE_ENV === 'development') {
        return "https://api.gnii.ai";
        // return "https://api.uatdev.gnii.ai";
    }

    const apiEndPoint = `${window.location.protocol}//api.${window.location.host}`;
    return apiEndPoint;
}

