import HeaderButton1 from "../assets/minified-svg/search.svg";
import HeaderButton3 from "../assets/minified-svg/ring.svg";
import HeaderButton4 from "../assets/minified-svg/user-settings.svg";
import "../styles/_menuButtons.scss";
import React, {useEffect, useRef} from "react";
import {useClient} from "./clientselection/useClient";
import {useOutsideClick} from "../utils/helper";

const MenuButtons = () => {
    const wrapperRef = useRef(null);

    const {clients, setSelectedClient} = useClient();
    const [showUserMenu, setShowUserMenu] = React.useState(false);

    const handleClick = (client: any) => {
        setShowUserMenu(false);
        setSelectedClient?.(client);
    }

    useOutsideClick(wrapperRef, () => {
        if (showUserMenu) setShowUserMenu(!showUserMenu);
    });

    return (
        <div className="menuButtons">
            {clients.length > 1 &&
                <div ref={wrapperRef} className="portfolio-wrap__header-buttons__menu" onClick={() => setShowUserMenu(!showUserMenu)}>
                    <HeaderButton4 className="menuButtons__btn"/>
                    <div className={`menu ${showUserMenu ? "active" : ""}`}>
                        {clients.map(client => {
                            return <div className="menu-item" key={client.id} onClick={() => {
                                handleClick(client)
                            }
                            }>{client.name}</div>;
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

export default MenuButtons
