import React, {useEffect, useRef} from "react";
import '../styles/_selectComponent.scss';
import { useOutsideClick } from "../utils/helper";
interface SelectComponentProps {
    options: { key: string, value: string }[];
    onChange?: (value?: string) => void;
    emptyText?: string;
    allowClear?: boolean;
    defaultSelected?: string;
}
const SelectComponent: React.FC<SelectComponentProps> = (props: SelectComponentProps) => {
    const [optionVisible, setOptionVisible] = React.useState(false);
    const [selected, setSelected] = React.useState<{ key: string, value: string } | undefined>(undefined);
    const { options, onChange, emptyText, allowClear, defaultSelected } = props;
    const [searchString, setSearchString] = React.useState<string | undefined>(undefined);
    const ref = useRef()

    useEffect(() => {
        if (defaultSelected) {
            setSelected(options?.find(e => e.key === defaultSelected));
        }
    }, [defaultSelected]);

    useEffect(() => {
        onChange?.(selected?.key);
        setSearchString(selected?.value || '');
    }, [selected, onChange]);

    const renderPopupList = () => {
        let renderOptions = options;
        if (selected) {
            if (searchString && searchString != selected.value) {
                renderOptions = renderOptions.filter(e => e.value?.toLowerCase().indexOf(searchString.toLowerCase()) >= 0);
            }
        } else if (searchString) {
            renderOptions = renderOptions.filter(e => e.value?.toLowerCase().indexOf(searchString.toLowerCase()) >= 0);
        }
        if (emptyText && (!renderOptions || renderOptions.length == 0)) {
            return <div className="empty">{emptyText}</div>
        }
        return (
            <div>
                {
                    renderOptions.map((option, i) => {
                        return (
                            <div className="bselect-list-popup__item" onClick={() => {
                                setSelected(option);
                                setOptionVisible(false);
                            }} key={i}>{option.value}</div>
                        )
                    })
                }
            </div>
        )
    }
    const renderTextBoxIcon = () => {
        if (selected && allowClear !== false) {
            return <div className="add" onClick={() => {
                setSelected(undefined);
                setOptionVisible(false);
            }}><i className="bi bi-x-circle-fill"/></div>;
        } else if (searchString && allowClear !== false) {
            return <div className="add" onClick={() => {
                setSelected(undefined);
                setOptionVisible(true);
            }}><i className="bi bi-x-circle-fill"/></div>;
        } else {
            return <div className="add" onClick={() => setOptionVisible(!optionVisible)}><i className="bi bi-caret-down-fill"/></div>;
        }
    }

 
    useOutsideClick(ref, () => {       
        if(optionVisible)
            setOptionVisible(false);
    });
    return <>
        <div className="bselect-list">
            <div className="bselect-choose">
                <input 
                    placeholder="Search" 
                    value={searchString} 
                    onChange={(e) => {
                        setSearchString(e.target.value);
                        setOptionVisible(true);
                    }
                } />
                {renderTextBoxIcon()}
            </div>
            <div ref={ref} className="bselect-list-popup" style={{ display: optionVisible ? "block" : "none" }}>{renderPopupList()}</div>
        </div>
    </>
}
export default SelectComponent
