import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router'
import SidebarIcon1 from "../assets/minified-svg/dashboard.svg";
import SidebarIcon2 from "../assets/minified-svg/portfolio.svg";
import SidebarIcon3 from "../assets/minified-svg/japcandles.svg";
import SidebarIcon9 from "../assets/minified-svg/question.svg";
import SidebarIcon6 from "../assets/minified-svg/wallettransfer.svg";
import SidebarIcon10 from "../assets/logo.svg";
import StocksList from './StocksList';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/_sidebar.scss";
import SelectComponent from "./SelectComponent";
import {PortfolioDTO} from "../types/service";
import {fetchAllPortfolios} from "../services/portfolioService";
import {useClient} from "./clientselection/useClient";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AboutUsPopup from "../components/common_compoenent/AboutUsPopup";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import moment from "moment";
import {nFormatter, numberWithCommasAndDecimal} from "../utils/helper";
import ReactTooltip from "react-tooltip";
import Loader from "../layout/loader";

type sidebarProps = {
    activeSidebar: any,
    handleActiveSidebar: any,
    portfolio?: any,
    setPortfolio: any,
    setSelectedDate: any,
    theme: any,
    selectedDate: string,
    selectedPortfolio: any,
    summaryData: any,
    refresh: any
}

const Sidebar = ({
                     activeSidebar,
                     handleActiveSidebar,
                     setPortfolio,
                     selectedDate,
                     setSelectedDate,
                     theme,
                     portfolio,
                     refresh,
                     summaryData,
                 }: sidebarProps) => {

    const router = useRouter();
    const [availablePortfolios, setAvailablePortfolios] = React.useState<PortfolioDTO[]>([]);
    const [dropdownPortfolioList, setDropdownPortfolioList] = React.useState<any>([]);
    const [currencySymbol, setCurrencySymbol] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [loading2, setLoading2] = useState(true);
    const [currentPath, setCurrentPath] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(()=>{
        if(currentPath && !router?.query?.type){
            if(currentPath === router.pathname){
                setLoader(false)
            }else {
                setLoader(true);
            }
        }
    },[currentPath, router.pathname])

    const {selectedClient} = useClient();

    useEffect(() => {
        fetchAllPortfolios().then((res: PortfolioDTO[]) => {
            setAvailablePortfolios(res);
            if (portfolio) {
                setPortfolio(res.find((p: PortfolioDTO) => p.id === [portfolio]?.id));
            }
        })
    }, [selectedClient]);

    useEffect(() => {
        if (portfolio) {
            setCurrencySymbol(portfolio?.currencySymbol);
        } else if (selectedClient) {
            setCurrencySymbol(selectedClient?.currencySymbol);
        }
    }, [selectedClient, portfolio])

    const handlePortfolioSelect = (pid?: string) => {
        if (pid && availablePortfolios) {
            availablePortfolios && setPortfolio(availablePortfolios?.find(p => p.id === pid));
        } else {
            setPortfolio(undefined);
        }
    }

    useEffect(() => {
        setPortfolio(portfolio);
    }, [portfolio]);

    useEffect(() => {
        const portfolioOverViewkey = {key: 'Portfolio Overview', value: 'Portfolio Overview'};
        const list = availablePortfolios?.length === 1 ? [] : [portfolioOverViewkey];
        availablePortfolios?.length > 0 && availablePortfolios?.forEach(e => {
            list.push({key: e.id, value: e.name});
        });
        setDropdownPortfolioList(list);
    }, [availablePortfolios])

    const [expanded, setExpanded] = React.useState<string | false>('');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const handleDate = (date: any) => {
        setSelectedDate(moment(date).format('YYYY-MM-DD'));
    };

    const removeDate = () => {
        setSelectedDate(moment().format("YYYY-MM-DD"));
    };

    const RangePickerCustomInput = React.forwardRef(function RangePickerCustomInput({onClick}, ref) {
        const content = (selectedDate ? moment(selectedDate).format('DD-MM-yyyy') : moment().format('DD-MM-yyyy'));
        return <div onClick={onClick} ref={ref} className='select2'
                    id="orders__content-select-date-picker"><i
            className="bi bi-calendar4-week date-picker-icon"/>{content}</div>;
    });

    return (
        <>
            {loader && <Loader/>}
            <div className={`sidebar ${activeSidebar && "active"}`}>
                <div className="opacity"/>
                <div className={`sidebar-wrap`}>
                    <div className="sidebar-wrap__logo">
                        <SidebarIcon10 style={{marginRight: "10px"}}/>
                        Gnii
                        {activeSidebar &&
                            <i className="sidebar-wrap__close bi bi-x delete" onClick={handleActiveSidebar}/>}
                    </div>

                    <div className="sidebar-wrap__info">
                        <div className="sidebar-wrap__sidebar-main">
                            <div className="sidebar-wrap__sidebar-label">Portfolio</div>
                            <SelectComponent emptyText='No portfolio found' onChange={handlePortfolioSelect}
                                             options={dropdownPortfolioList} allowClear={false}
                                             defaultSelected={portfolio?.id || dropdownPortfolioList[0]?.key}/>

                        </div>
                        <div className="sidebar-wrap__sidebar-main2">
                            <div className="sidebar-wrap__sidebar-label">Date</div>
                            <div className="sidebar-wrap__sidebar-picker">
                                <DatePicker className="sidebar-wrap__sidebar-picker" selected={new Date(selectedDate)}
                                            yearDropdownItemNumber={100}
                                            scrollableYearDropdown={true}
                                            showYearDropdown
                                            showMonthDropdown
                                            onChange={(date: Date) => handleDate(date)}
                                            customInput={<RangePickerCustomInput/>}/>
                                <i className={`bi bi-x-circle ${selectedDate ? "cursor" : ""}`} onClick={removeDate}
                                   style={{color: `${theme.toString() === '1' ? selectedDate ? "#00003e" : "#6c6c6e" : selectedDate ? "#ffd45d" : "#b6ab92"}`}}/>
                            </div>
                        </div>
                        {summaryData && (
                            <div className="sidebar-wrap__info-wrap">
                                <Accordion className="sidebar-wrap__info-wrap-main" expanded={expanded === 'panel1'}
                                           onChange={summaryData?.currencyWiseBalance?.length > 0 ? handleChange('panel1') : undefined}>
                                    <AccordionSummary
                                        expandIcon={summaryData?.currencyWiseBalance?.length > 0  && <ExpandMoreIcon style={{color: theme === 1 ? "#00003e" : theme === 2 ? "#ffd45d" : "#ffd45d"}}/>}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{
                                            backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",
                                        }}
                                    >
                                        <Typography className="sidebar-wrap__info-wrap-main__label">
                                            <span>Cash Balance</span>
                                            <>
                                                <div
                                                    className="sidebar-wrap__info-wrap-main__label-value"
                                                    data-tip={`${currencySymbol} ${summaryData?.cashBalance ? numberWithCommasAndDecimal(summaryData?.cashBalance) : numberWithCommasAndDecimal(0)}`}
                                                    data-for="customTool"
                                                    data-iscapture="true"
                                                >
                                                    {currencySymbol} {summaryData?.cashBalance < 0 ? "-" : ""}{nFormatter(summaryData?.cashBalance ? summaryData?.cashBalance < 0 ? Math.abs(summaryData?.cashBalance) : summaryData?.cashBalance : 0)}
                                                </div>
                                                <ReactTooltip
                                                    className="customTooltip"
                                                    id="customTool"
                                                    type="dark"
                                                    place="right"
                                                    effect="solid"
                                                    multiline={true}
                                                />
                                            </>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{
                                        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",
                                    }} className="sidebar-wrap__info-wrap-main__details">
                                        <Typography>
                                            {summaryData?.currencyWiseBalance?.map((d, i) => {
                                                return (
                                                    <Accordion key={i} className="sidebar-wrap__info-wrap-main"
                                                               expanded={false}>
                                                        <AccordionSummary
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                            sx={{
                                                                backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",
                                                            }}
                                                        >
                                                            <Typography className="sidebar-wrap__info-wrap-main__label">
                                                                <span>{d?.currency?.code}</span>
                                                                <>
                                                                    <div
                                                                        className="sidebar-wrap__info-wrap-main__label-value"
                                                                        data-tip={`${d?.currency?.symbol} ${d?.quantity ? numberWithCommasAndDecimal(d?.quantity) : numberWithCommasAndDecimal(0)}`}
                                                                        data-for="customTool"
                                                                        data-iscapture="true"
                                                                    >
                                                                        {d?.currency?.symbol} {d?.quantity < 0 ? "-" : ""}{nFormatter(d?.quantity ? d?.quantity < 0 ? Math.abs(d?.quantity) : d?.quantity : 0)}
                                                                    </div>
                                                                    <ReactTooltip
                                                                        className="customTooltip"
                                                                        id="customTool"
                                                                        type="dark"
                                                                        place="right"
                                                                        effect="solid"
                                                                        multiline={true}
                                                                    />
                                                                </>
                                                            </Typography>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                )
                                            })}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                {/*<Accordion className="sidebar-wrap__info-wrap-main" expanded={expanded === 'panel2'}*/}
                                {/*           onChange={handleChange('panel2')}>*/}
                                {/*    <AccordionSummary*/}
                                {/*        expandIcon={<ExpandMoreIcon style={{color: theme === 1 ? "#00003e" : theme === 2 ? "#ffd45d" : "#ffd45d"}}/>}*/}
                                {/*        aria-controls="panel1bh-content"*/}
                                {/*        id="panel1bh-header"*/}
                                {/*        sx={{*/}
                                {/*            backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <Typography className="sidebar-wrap__info-wrap-main__label">*/}
                                {/*            <span>Investment</span>*/}
                                {/*            <>*/}
                                {/*                <div*/}
                                {/*                    className="sidebar-wrap__info-wrap-main__label-value"*/}
                                {/*                    data-tip={`${currencySymbol} ${summaryData?.totalInvestment ? numberWithCommasAndDecimal(summaryData?.totalInvestment) : numberWithCommasAndDecimal(0)}`}*/}
                                {/*                    data-for="customTool"*/}
                                {/*                    data-iscapture="true"*/}
                                {/*                >*/}
                                {/*                    {currencySymbol} {summaryData?.totalInvestment < 0 ? "-" : ""}{nFormatter(summaryData?.totalInvestment ? summaryData?.totalInvestment < 0 ? Math.abs(summaryData?.totalInvestment) : summaryData?.totalInvestment : 0)}*/}
                                {/*                </div>*/}
                                {/*                <ReactTooltip*/}
                                {/*                    className="customTooltip"*/}
                                {/*                    id="customTool"*/}
                                {/*                    type="dark"*/}
                                {/*                    place="right"*/}
                                {/*                    effect="solid"*/}
                                {/*                    multiline={true}*/}
                                {/*                />*/}
                                {/*            </>*/}
                                {/*        </Typography>*/}
                                {/*    </AccordionSummary>*/}
                                {/*    <AccordionDetails sx={{*/}
                                {/*        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*    }} className="sidebar-wrap__info-wrap-main__details">*/}
                                {/*        <Typography>*/}
                                {/*            <Accordion className="sidebar-wrap__info-wrap-main" expanded={false}>*/}
                                {/*                <AccordionSummary*/}
                                {/*                    aria-controls="panel1bh-content"*/}
                                {/*                    id="panel1bh-header"*/}
                                {/*                    sx={{*/}
                                {/*                        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <Typography className="sidebar-wrap__info-wrap-main__label">*/}
                                {/*                        <span>Deposits</span>*/}
                                {/*                        <>*/}
                                {/*                            <div*/}
                                {/*                                className="sidebar-wrap__info-wrap-main__label-value deposit"*/}
                                {/*                                data-tip={`${currencySymbol} ${summaryData?.deposits ? numberWithCommasAndDecimal(summaryData?.deposits) : numberWithCommasAndDecimal(0)}`}*/}
                                {/*                                data-for="customTool"*/}
                                {/*                                data-iscapture="true"*/}
                                {/*                            >*/}
                                {/*                                {currencySymbol} {summaryData?.deposits < 0 ? "-" : ""}{nFormatter(summaryData?.deposits ? summaryData?.deposits < 0 ? Math.abs(summaryData?.deposits) : summaryData?.deposits : 0)}*/}
                                {/*                            </div>*/}
                                {/*                            <ReactTooltip*/}
                                {/*                                className="customTooltip"*/}
                                {/*                                id="customTool"*/}
                                {/*                                type="dark"*/}
                                {/*                                place="right"*/}
                                {/*                                effect="solid"*/}
                                {/*                                multiline={true}*/}
                                {/*                            />*/}
                                {/*                        </>*/}
                                {/*                    </Typography>*/}
                                {/*                </AccordionSummary>*/}
                                {/*            </Accordion>*/}
                                {/*            <Accordion className="sidebar-wrap__info-wrap-main" expanded={false}>*/}
                                {/*                <AccordionSummary*/}
                                {/*                    aria-controls="panel1bh-content"*/}
                                {/*                    id="panel1bh-header"*/}
                                {/*                    sx={{*/}
                                {/*                        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <Typography className="sidebar-wrap__info-wrap-main__label">*/}
                                {/*                        <span>Fund Withdrawal</span>*/}
                                {/*                        <>*/}
                                {/*                            <div*/}
                                {/*                                className="sidebar-wrap__info-wrap-main__label-value"*/}
                                {/*                                data-tip={`${currencySymbol} ${summaryData?.fundWithdrawal ? numberWithCommasAndDecimal(summaryData?.fundWithdrawal) : numberWithCommasAndDecimal(0)}`}*/}
                                {/*                                data-for="customTool"*/}
                                {/*                                data-iscapture="true"*/}
                                {/*                            >*/}
                                {/*                                {currencySymbol} {summaryData?.fundWithdrawal < 0 ? "-" : ""}{nFormatter(summaryData?.fundWithdrawal ? summaryData?.fundWithdrawal < 0 ? Math.abs(summaryData?.fundWithdrawal) : summaryData?.fundWithdrawal : 0)}*/}
                                {/*                            </div>*/}
                                {/*                            <ReactTooltip*/}
                                {/*                                className="customTooltip"*/}
                                {/*                                id="customTool"*/}
                                {/*                                type="dark"*/}
                                {/*                                place="right"*/}
                                {/*                                effect="solid"*/}
                                {/*                                multiline={true}*/}
                                {/*                            />*/}
                                {/*                        </>*/}
                                {/*                    </Typography>*/}
                                {/*                </AccordionSummary>*/}
                                {/*            </Accordion>*/}
                                {/*            <Accordion className="sidebar-wrap__info-wrap-main" expanded={false}>*/}
                                {/*                <AccordionSummary*/}
                                {/*                    aria-controls="panel1bh-content"*/}
                                {/*                    id="panel1bh-header"*/}
                                {/*                    sx={{*/}
                                {/*                        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <Typography className="sidebar-wrap__info-wrap-main__label">*/}
                                {/*                        <span>Realised Income</span>*/}
                                {/*                        <>*/}
                                {/*                            <div*/}
                                {/*                                className="sidebar-wrap__info-wrap-main__label-value"*/}
                                {/*                                data-tip={`${currencySymbol} ${summaryData?.realisedIncome ? numberWithCommasAndDecimal(summaryData?.realisedIncome) : numberWithCommasAndDecimal(0)}`}*/}
                                {/*                                data-for="customTool"*/}
                                {/*                                data-iscapture="true"*/}
                                {/*                            >*/}
                                {/*                                {currencySymbol} {summaryData?.realisedIncome < 0 ? "-" : ""}{nFormatter(summaryData?.realisedIncome ? summaryData?.realisedIncome < 0 ? Math.abs(summaryData?.realisedIncome) : summaryData?.realisedIncome : 0)}*/}
                                {/*                            </div>*/}
                                {/*                            <ReactTooltip*/}
                                {/*                                className="customTooltip"*/}
                                {/*                                id="customTool"*/}
                                {/*                                type="dark"*/}
                                {/*                                place="right"*/}
                                {/*                                effect="solid"*/}
                                {/*                                multiline={true}*/}
                                {/*                            />*/}
                                {/*                        </>*/}
                                {/*                    </Typography>*/}
                                {/*                </AccordionSummary>*/}
                                {/*            </Accordion>*/}
                                {/*            <Accordion className="sidebar-wrap__info-wrap-main" expanded={false}>*/}
                                {/*                <AccordionSummary*/}
                                {/*                    aria-controls="panel1bh-content"*/}
                                {/*                    id="panel1bh-header"*/}
                                {/*                    sx={{*/}
                                {/*                        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <Typography className="sidebar-wrap__info-wrap-main__label">*/}
                                {/*                        <span>Unrealised Income</span>*/}
                                {/*                        <>*/}
                                {/*                            <div*/}
                                {/*                                className="sidebar-wrap__info-wrap-main__label-value"*/}
                                {/*                                data-tip={`${currencySymbol} ${summaryData?.unRealisedIncome ? numberWithCommasAndDecimal(summaryData?.unRealisedIncome) : numberWithCommasAndDecimal(0)}`}*/}
                                {/*                                data-for="customTool"*/}
                                {/*                                data-iscapture="true"*/}
                                {/*                            >*/}
                                {/*                                {currencySymbol} {summaryData?.unRealisedIncome < 0 ? "-" : ""}{nFormatter(summaryData?.unRealisedIncome ? summaryData?.unRealisedIncome < 0 ? Math.abs(summaryData?.unRealisedIncome) : summaryData?.unRealisedIncome : 0)}*/}
                                {/*                            </div>*/}
                                {/*                            <ReactTooltip*/}
                                {/*                                className="customTooltip"*/}
                                {/*                                id="customTool"*/}
                                {/*                                type="dark"*/}
                                {/*                                place="right"*/}
                                {/*                                effect="solid"*/}
                                {/*                                multiline={true}*/}
                                {/*                            />*/}
                                {/*                        </>*/}
                                {/*                    </Typography>*/}
                                {/*                </AccordionSummary>*/}
                                {/*            </Accordion>*/}
                                {/*            <Accordion className="sidebar-wrap__info-wrap-main" expanded={false}>*/}
                                {/*                <AccordionSummary*/}
                                {/*                    aria-controls="panel1bh-content"*/}
                                {/*                    id="panel1bh-header"*/}
                                {/*                    sx={{*/}
                                {/*                        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <Typography className="sidebar-wrap__info-wrap-main__label">*/}
                                {/*                        <span>Realized PL</span>*/}
                                {/*                        <>*/}
                                {/*                            <div*/}
                                {/*                                className="sidebar-wrap__info-wrap-main__label-value"*/}
                                {/*                                data-tip={`${currencySymbol} ${summaryData?.realizedPL ? numberWithCommasAndDecimal(summaryData?.realizedPL) : numberWithCommasAndDecimal(0)}`}*/}
                                {/*                                data-for="customTool"*/}
                                {/*                                data-iscapture="true"*/}
                                {/*                            >*/}
                                {/*                                {currencySymbol} {summaryData?.realizedPL < 0 ? "-" : ""}{nFormatter(summaryData?.realizedPL ? summaryData?.realizedPL < 0 ? Math.abs(summaryData?.realizedPL) : summaryData?.realizedPL : 0)}*/}
                                {/*                            </div>*/}
                                {/*                            <ReactTooltip*/}
                                {/*                                className="customTooltip"*/}
                                {/*                                id="customTool"*/}
                                {/*                                type="dark"*/}
                                {/*                                place="right"*/}
                                {/*                                effect="solid"*/}
                                {/*                                multiline={true}*/}
                                {/*                            />*/}
                                {/*                        </>*/}
                                {/*                    </Typography>*/}
                                {/*                </AccordionSummary>*/}
                                {/*            </Accordion>*/}
                                {/*            <Accordion className="sidebar-wrap__info-wrap-main" expanded={false}>*/}
                                {/*                <AccordionSummary*/}
                                {/*                    aria-controls="panel1bh-content"*/}
                                {/*                    id="panel1bh-header"*/}
                                {/*                    sx={{*/}
                                {/*                        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <Typography className="sidebar-wrap__info-wrap-main__label">*/}
                                {/*                        <span>Unrealized PL</span>*/}
                                {/*                        <>*/}
                                {/*                            <div*/}
                                {/*                                className="sidebar-wrap__info-wrap-main__label-value"*/}
                                {/*                                data-tip={`${currencySymbol} ${summaryData?.unRealizedGL ? numberWithCommasAndDecimal(summaryData?.unRealizedGL) : numberWithCommasAndDecimal(0)}`}*/}
                                {/*                                data-for="customTool"*/}
                                {/*                                data-iscapture="true"*/}
                                {/*                            >*/}
                                {/*                                {currencySymbol} {summaryData?.unRealizedGL < 0 ? "-" : ""}{nFormatter(summaryData?.unRealizedGL ? summaryData?.unRealizedGL < 0 ? Math.abs(summaryData?.unRealizedGL) : summaryData?.unRealizedGL : 0)}*/}
                                {/*                            </div>*/}
                                {/*                            <ReactTooltip*/}
                                {/*                                className="customTooltip"*/}
                                {/*                                id="customTool"*/}
                                {/*                                type="dark"*/}
                                {/*                                place="right"*/}
                                {/*                                effect="solid"*/}
                                {/*                                multiline={true}*/}
                                {/*                            />*/}
                                {/*                        </>*/}
                                {/*                    </Typography>*/}
                                {/*                </AccordionSummary>*/}
                                {/*            </Accordion>*/}
                                {/*            <Accordion className="sidebar-wrap__info-wrap-main" expanded={false}>*/}
                                {/*                <AccordionSummary*/}
                                {/*                    aria-controls="panel1bh-content"*/}
                                {/*                    id="panel1bh-header"*/}
                                {/*                    sx={{*/}
                                {/*                        backgroundColor: theme === 1 ? "#ebebf2" : theme === 2 ? "#0d0d0d" : "#181750",*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <Typography className="sidebar-wrap__info-wrap-main__label">*/}
                                {/*                        <span>{summaryData?.ledgerBalance < 0 ? "Payables" : "Receivables"}</span>*/}
                                {/*                        <>*/}
                                {/*                            <div*/}
                                {/*                                className="sidebar-wrap__info-wrap-main__label-value"*/}
                                {/*                                data-tip={`${currencySymbol} ${summaryData?.ledgerBalance ? numberWithCommasAndDecimal(summaryData?.ledgerBalance) : numberWithCommasAndDecimal(0)}`}*/}
                                {/*                                data-for="customTool"*/}
                                {/*                                data-iscapture="true"*/}
                                {/*                            >*/}
                                {/*                                {currencySymbol} {summaryData?.ledgerBalance < 0 ? "-" : ""}{nFormatter(summaryData?.ledgerBalance ? summaryData?.ledgerBalance < 0 ? Math.abs(summaryData?.ledgerBalance) : summaryData?.ledgerBalance : 0)}*/}
                                {/*                            </div>*/}
                                {/*                            <ReactTooltip*/}
                                {/*                                className="customTooltip"*/}
                                {/*                                id="customTool"*/}
                                {/*                                type="dark"*/}
                                {/*                                place="right"*/}
                                {/*                                effect="solid"*/}
                                {/*                                multiline={true}*/}
                                {/*                            />*/}
                                {/*                        </>*/}
                                {/*                    </Typography>*/}
                                {/*                </AccordionSummary>*/}
                                {/*            </Accordion>*/}
                                {/*        </Typography>*/}
                                {/*    </AccordionDetails>*/}
                                {/*</Accordion>*/}
                            </div>
                        )}
                    </div>

                    {/*{activeSidebar && <MenuButtons/>}*/}
                    {activeSidebar && <StocksList selectedDate={selectedDate} refresh={refresh} />}
                    <div className="sidebar-wrap__userPanel">
                        <div className="sidebar-wrap__userPanel-wrap">
                            <p className="sidebar-wrap__userPanel-wrap__label">User Panel</p>
                            <div className="sidebar-wrap__userPanel-wrap__itemsList">
                                <>
                                    <div className={`sidebar-wrap__userPanel-wrap__itemsList-item ${router.pathname === "/" ? "active" : ""}`}
                                         onClick={() => {
                                             router.push('/')
                                             setCurrentPath("/")
                                         }}>
                                        <SidebarIcon1 className="sidebar-wrap__userPanel-wrap__itemsList-item__img"/>
                                        <div className="sidebar-wrap__userPanel-wrap__itemsList-item__label">Dashboard</div>
                                    </div>

                                    <div
                                        className={`sidebar-wrap__userPanel-wrap__itemsList-item ${router.pathname === "/portfolio" ? "active" : ""}`}
                                        onClick={() => {
                                            router.push('/portfolio')
                                            setCurrentPath("/portfolio")
                                        }}>
                                        <SidebarIcon2 className="sidebar-wrap__userPanel-wrap__itemsList-item__img"/>
                                        <div className="sidebar-wrap__userPanel-wrap__itemsList-item__label">Portfolio</div>
                                    </div>

                                    <div
                                        className={`sidebar-wrap__userPanel-wrap__itemsList-item ${router.pathname === "/positionTransaction" ? "active" : ""}`}
                                        onClick={() => {
                                            router.push('/positionTransaction')
                                            setCurrentPath("/positionTransaction")
                                        }}>
                                        <SidebarIcon3 className="sidebar-wrap__userPanel-wrap__itemsList-item__img"/>
                                        <div className="sidebar-wrap__userPanel-wrap__itemsList-item__label">Positions & Transactions</div>
                                    </div>

                                    <div>

                                        <div
                                            className={`sidebar-wrap__userPanel-wrap__itemsList-item ${router.pathname === "/market" ? "active" : ""}`}
                                            onClick={() => {
                                                router.push('/market')
                                                setCurrentPath("/market")
                                            }}>
                                            <SidebarIcon3 className="sidebar-wrap__userPanel-wrap__itemsList-item__img"/>
                                            <div className="sidebar-wrap__userPanel-wrap__itemsList-item__label">Markets</div>
                                        </div>
                                        {/*<div className="sub-menu">*/}
                                        {/*    <div>Menu1</div>*/}
                                        {/*    <div>Menu1</div>*/}
                                        {/*    <div>Menu1</div>*/}
                                        {/*    <div>Menu1</div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div
                                        className={`sidebar-wrap__userPanel-wrap__itemsList-item ${router.pathname === "/trading" ? "active" : ""}`}
                                        onClick={() => {
                                            router.push('/trading')
                                            setCurrentPath("/trading")
                                        }}>
                                        <SidebarIcon3 className="sidebar-wrap__userPanel-wrap__itemsList-item__img"/>
                                        <div className="sidebar-wrap__userPanel-wrap__itemsList-item__label">Trading</div>
                                    </div>

                                    <div className={`sidebar-wrap__userPanel-wrap__itemsList-item ${router.pathname === "/performance" ? "active" : ""}`}
                                         onClick={() => {
                                             router.push('/performance')
                                             setCurrentPath("/performance")
                                         }}>
                                        <SidebarIcon3 className="sidebar-wrap__userPanel-wrap__itemsList-item__img"/>
                                        <div className="sidebar-wrap__userPanel-wrap__itemsList-item__label">Performance</div>
                                    </div>

                                    <div className={`sidebar-wrap__userPanel-wrap__itemsList-item ${router.pathname === "/pricingHistory" ? "active" : ""}`}
                                         onClick={() => {
                                             router.push('/pricingHistory')
                                             setCurrentPath("/pricingHistory")
                                         }}>
                                        <SidebarIcon3 className="sidebar-wrap__userPanel-wrap__itemsList-item__img"/>
                                        <div className="sidebar-wrap__userPanel-wrap__itemsList-item__label">Pricing History</div>
                                    </div>

                                    <div className={`sidebar-wrap__userPanel-wrap__itemsList-item ${router.pathname === "/wallet" ? "active" : ""}`}
                                         onClick={() => {
                                             router.push('/wallet')
                                             setCurrentPath("/wallet")
                                         }}>
                                        <SidebarIcon6 className="sidebar-wrap__userPanel-wrap__itemsList-item__img"/>
                                        <div className="sidebar-wrap__userPanel-wrap__itemsList-item__label">Wallet</div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-wrap__homepage">
                        <div className="sidebar-wrap__homepage-wrap">
                            <p className="sidebar-wrap__homepage-wrap__label">Homepage</p>
                            <div className="sidebar-wrap__homepage-wrap__itemsList">
                                <div className="sidebar-wrap__homepage-wrap__itemsList-item">
                                    <SidebarIcon10 className="sidebar-wrap__homepage-wrap__itemsList-item__img"
                                                   onClick={handleClickOpen}/>
                                    <div className="sidebar-wrap__homepage-wrap__itemsList-item__label"
                                         onClick={handleClickOpen}>Gnii
                                    </div>
                                </div>
                                <div className="sidebar-wrap__homepage-wrap__itemsList-item">
                                    <SidebarIcon9 className="sidebar-wrap__homepage-wrap__itemsList-item__img"/>
                                    <div className="sidebar-wrap__homepage-wrap__itemsList-item__label">FAQ</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AboutUsPopup
                    open={open}
                    theme={theme}
                    onClose={handleClose}
                />
            </div>
        </>
    )
}

export default Sidebar
