import {useEffect, useMemo, useState} from "react";
import moment from "moment";

export function numberWithCommas(number: any) {
    let convertToNumber: any = number ? parseFloat(number) : 0;
    let removeDecimal: any = convertToNumber?.toFixed(0);
    const numStr = removeDecimal + '';
    const x = numStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

function addZeroes(num: any, decimal?: any) {
    let convertToString: string = num?.toString();
    let numberWithZero: any = parseFloat(Number(num).toFixed(Math.max(convertToString?.split('.')[1]?.length, 4) || 4));
    if (decimal) {
        return numberWithZero?.toFixed(decimal);
    } else {
        return numberWithZero?.toFixed(2);
    }
}

export function numberWithCommasAndDecimal(number: any, decimal?: any) {
    let convertToNumber: any = number ? parseFloat(number) : 0;
    let numberWithZeroes = addZeroes(convertToNumber, decimal);
    const numStr = numberWithZeroes + '';
    const x = numStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export const useOutsideClick = (ref: any, callback: any) => {
    const handleClick = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

export const nFormatter = (num: any) => {
    let number: any = num?.toString()?.replace(/[^0-9.]/g, '');
    if (number < 1000) {
        return number;
    }
    let si = [
        {v: 1E3, s: "K"},
        {v: 1E6, s: "M"},
        {v: 1E9, s: "B"},
        {v: 1E12, s: "T"},
        {v: 1E15, s: "P"},
        {v: 1E18, s: "E"}
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (number >= si[index].v) {
            break;
        }
    }
    return (number / si[index].v)?.toFixed(2)?.replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
}

export function useIsInViewport(ref: any) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useEffect(() => {
        if (observer) {
            observer?.observe(ref.current);

            return () => {
                observer?.disconnect();
            };
        }
    }, [ref, observer]);

    return isIntersecting;
}

function getDays(start: string, end: string) {
    const startDate = moment(start, "YYYY-MM-DD");
    const endDate = moment(end, "YYYY-MM-DD");

    return moment.duration(startDate.diff(endDate)).asDays();
}

function categorizeDays(data: any, type: string) {
    if (type === "Short Term") {
        if (data?.days <= 7) {
            return {
                exposure: "1 - 7 days",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#fc5c6e",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        } else if (data?.days <= 30) {
            return {
                exposure: "8 - 30 days",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#fc5c6e",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        } else if (data?.days <= 90) {
            return {
                exposure: "31 - 90 days",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#fc5c6e",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        } else if (data?.days <= 182) {
            return {
                exposure: "91 - 182 days",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#fc5c6e",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        } else if (data?.days <= 364) {
            return {
                exposure: "183 - 364 days",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#fc5c6e",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        }
    } else if (type === "Intermediate") {
        if (data?.days <= 1092) {
            return {
                exposure: "1 - 3 years",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#5493d7",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        } else if (data?.days <= 1820) {
            return {
                exposure: "3 - 5 years",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#5493d7",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        }
    } else {
        if (data?.days <= 2548) {
            return {
                exposure: "5 - 7 years",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#a9e59d",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        } else if (data?.days <= 3640) {
            return {
                exposure: "7 - 10 years",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#a9e59d",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        } else {
            return {
                exposure: "Above 10 years",
                value: data?.value,
                type: data?.type,
                days: data?.days,
                color: "#a9e59d",
                security_id: data?.security_id,
                date: data?.date,
                faceValue: data?.faceValue,
            }
        }
    }
}

export function getDaysWise(data: any) {
    let arr: any = []
    let temp: any = []
    let temp1: any = []
    let temp2: any = []
    let totalOfShort: any = 0;
    let totalOfMaturityShort: any = 0;
    let totalOfCouponShort: any = 0;

    let totalOfIntermediate: any = 0;
    let totalOfMaturityIntermediate: any = 0;
    let totalOfCouponIntermediate: any = 0;

    let totalOfLong: any = 0;
    let totalOfMaturityLong: any = 0;
    let totalOfCouponLong: any = 0;

    data?.map((d: any) => {
        if (d?.exposure === "Short Term") {
            totalOfShort = parseFloat(totalOfShort) + parseFloat(d?.value);
            if(d?.type==="MATURITY"){
                totalOfMaturityShort = parseFloat(totalOfMaturityShort) + parseFloat(d?.value)
            }else if(d?.type==="COUPON"){
                totalOfCouponShort = parseFloat(totalOfCouponShort) + parseFloat(d?.value)
            }
            temp.push(categorizeDays(d, d?.exposure))
            arr[0] = {
                exposure: "Short Term",
                maturity_value: totalOfMaturityShort,
                coupon_value: totalOfCouponShort,
                children: temp,
                color: "#ee2f44",
                value: totalOfShort,
                days: d?.days,
                security_id: d?.security_id,
                date: d?.date,
                faceValue: d?.faceValue,
            }
        } else if (d?.exposure === "Intermediate") {
            totalOfIntermediate = parseFloat(totalOfIntermediate) + parseFloat(d?.value);
            if(d?.type==="MATURITY"){
                totalOfMaturityIntermediate = parseFloat(totalOfMaturityIntermediate) + parseFloat(d?.value)
            }else if(d?.type==="COUPON"){
                totalOfCouponIntermediate = parseFloat(totalOfCouponIntermediate) + parseFloat(d?.value)
            }
            temp1.push(categorizeDays(d, d?.exposure))
            arr[1] = {
                exposure: "Intermediate",
                children: temp1,
                maturity_value: totalOfMaturityIntermediate,
                coupon_value: totalOfCouponIntermediate,
                color: "#007dff",
                value: totalOfIntermediate,
                days: d?.days,
                security_id: d?.security_id,
                date: d?.date,
                faceValue: d?.faceValue,
            }
        } else {
            totalOfLong = parseFloat(totalOfLong) + parseFloat(d?.value);
            if(d?.type==="MATURITY"){
                totalOfMaturityLong = parseFloat(totalOfMaturityLong) + parseFloat(d?.value)
            }else if(d?.type==="COUPON"){
                totalOfCouponLong = parseFloat(totalOfCouponLong) + parseFloat(d?.value)
            }
            temp2.push(categorizeDays(d, d?.exposure))
            arr[2] = {
                exposure: "Long Term",
                maturity_value: totalOfMaturityLong,
                coupon_value: totalOfCouponLong,
                children: temp2,
                color: "#25af0c",
                value: totalOfLong,
                days: d?.days,
                security_id: d?.security_id,
                date: d?.date,
                faceValue: d?.faceValue,
            }
        }
    })

    return arr;
}

function groupBy(data: any){
    let result: any = [];
    data?.forEach(function (a) {
        if (!this[a.exposure] && !this[a.type]) {
            this[a.exposure] = { exposure: a.exposure,  maturity_value: 0, coupon_value: 0, value: 0, color: a?.color };
            result.push(this[a.exposure]);
        }
        this[a.exposure].value += parseFloat(a.value);
        if(a.type==="COUPON"){
            this[a.exposure].coupon_value += parseFloat(a.value);
        }else{
            this[a.exposure].maturity_value += parseFloat(a.value);
        }
    }, Object.create(null));

    return result;
}

export async function getTermData(data: any, endDate: string) {
    let tempArr: any = [];
    data?.map((d: any) => {
        let splitDate = d?.date?.split("-");
        let date = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
        let days = getDays(date, endDate);
        if (days <= 364) {
            tempArr?.push({
                exposure: "Short Term",
                value: d?.value,
                type: d?.type,
                days: days
            })
        } else if (days <= 1820) {
            tempArr?.push({
                exposure: "Intermediate",
                value: d?.value,
                type: d?.type,
                days: days
            })
        } else {
            tempArr?.push({
                exposure: "Long Term",
                value: d?.value,
                type: d?.type,
                days: days
            })
        }
    })


    let dayWise = await getDaysWise(tempArr);

    let arr: any = []
    dayWise?.map((data: any)=>{
        arr?.push(groupBy(data?.children)?.sort((a, b) => parseInt(a?.exposure.split("-")[0]) - parseInt(b?.exposure.split("-")[0])))
    })

    arr?.map((item: any,i:number)=>{
        dayWise[i]["children"]=item
    })

    return dayWise;
}

export async function getBreakDownData(data: any, endDate: string) {
    let tempArr: any = [];
    data?.map((d: any) => {
        let splitDate = d?.date?.split("-");
        let date = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
        let days = getDays(date, endDate);
        if (days <= 364) {
            tempArr?.push({
                exposure: "Short Term",
                value: d?.value,
                type: d?.type,
                days: days,
                security_id: d?.bsSecuritySmallId,
                date: d?.date,
                faceValue: d?.faceValue,
            })
        } else if (days <= 1820) {
            tempArr?.push({
                exposure: "Intermediate",
                value: d?.value,
                type: d?.type,
                days: days,
                security_id: d?.bsSecuritySmallId,
                date: d?.date,
                faceValue: d?.faceValue,
            })
        } else {
            tempArr?.push({
                exposure: "Long Term",
                value: d?.value,
                type: d?.type,
                days: days,
                security_id: d?.bsSecuritySmallId,
                date: d?.date,
                faceValue: d?.faceValue,
            })
        }
    })

    return await getDaysWise(tempArr);
}
