import createClientContext from "./clientContext";
import {useContext} from "react";

export const reactClientWebContext = createClientContext()

export function useClient() {
  const ctx = useContext(reactClientWebContext)

  if (!ctx) {
    throw new Error(
      'useClient hook must be used inside ReactKeycloakProvider context'
    )
  }

  const {loading, clients, selectedClient, setSelectedClient} = ctx
  return {
    loading,
    clients,
    selectedClient,
    setSelectedClient
  }
}
