import React, {useEffect, useState} from 'react'
import "../styles/_stocksList.scss";
import {fetchTickers} from "../services/portfolioService";
import moment from "moment";
import {numberWithCommasAndDecimal} from "../utils/helper";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
type StockListProps = {
    selectedDate: string,
    refresh: any,
    classname?: any
}

const StocksList = ({classname, selectedDate, refresh}: StockListProps) => {
    const [tickerData, setTickerData] = useState([]);
    useEffect(() => {
        let date = selectedDate ? moment(selectedDate)?.format("DD-MM-YYYY") : moment().format("DD-MM-YYYY");
        fetchTickers(date).then((res) => {
            setTickerData(res);
        }).catch(() => {

        })
    }, [selectedDate, refresh]);

    return (
        <div className={`stocks ${classname ? classname : ""}`}>
            {tickerData?.length > 0 && tickerData?.map((data: any, index: number) => {
                return (
                    <div className="stocks__stock" key={index}>
                        <div className="stocks__stock-wrap">
                            {data?.change < 0 ? <ArrowDownwardIcon style={{color: "#ef4e4e", marginRight: "2px"}}/> : data?.change > 0 ? <ArrowUpwardIcon style={{color: "#25af0c", marginRight: "2px"}}/> : ""}
                            <div style={{width: "100%"}}>
                                <div className="stocks__stock-wrap__category">{data?.name ? data?.name : "-"}</div>
                                <div className="stocks__stock-wrap__title">
                                    <div>{data?.unitPrice ? numberWithCommasAndDecimal(data?.unitPrice) : "-"}</div>&nbsp;&nbsp;
                                    <div>{data?.changeInValue ? numberWithCommasAndDecimal(data?.changeInValue) : "-"}</div>
                                </div>
                                <div
                                    className={`stocks__stock-wrap__growth ${data?.change < 0 ? "decrease" : data?.change > 0 ? "increase" : ""}`}>{data?.change > 0 ? "+" : ""} {data?.change ? numberWithCommasAndDecimal(data?.change)+"%" : "-"}</div>

                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default StocksList
