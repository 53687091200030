import React from "react";
import "../styles/_loader.scss";
const Loader: React.FC = (props) => {
    return (
        <div className="loading">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );
}

export default Loader;
