import { useEffect } from "react";
import { hotjar } from 'react-hotjar';

export function useHotjarAnalytics(HJ_TRACKING_ID: string | null | undefined) {

  useEffect(() => {
    if (HJ_TRACKING_ID) {
      hotjar.initialize(Number(HJ_TRACKING_ID), 6);
    }
  }, [HJ_TRACKING_ID]);

}

