import {requestHeaders} from "./utils";
import {proxyRequest} from "./proxyRequests/proxyRequest";
import {TransactionSearchRequest} from "../types/service";
import {productProxyRequest} from "./proxyRequests/productProxyRequest";
import {performanceChartProxyRequest} from "./proxyRequests/performanceChartProxyRequest";
import {tickerProxyRequest} from "./proxyRequests/tickerProxyRequest";
import {counterPartyProxyRequest} from "./proxyRequests/counterPartySearch";
import {downloadPDFFile} from "../components/utils";
import {riskChartProxyRequest} from "./proxyRequests/riskChartProxyRequest";

export async function fetchTopPositions(
    portfolioId: string | undefined,
    page: number,
    pageSize: number,
    requestBody: any
) {
    const response = await proxyRequest(
        `/portfolio/${portfolioId}/position/ASSET?page=${page}&size=${pageSize}`,
        {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify(requestBody),
        }
    );
    return response.json();
}

export async function fetchTopClientPositions(requestBody: any, date: string, portfolio: string) {
    let url = portfolio ? `/position?portfolioId=${portfolio}&date=${date}` : `/position?date=${date}`
    const response = await proxyRequest(url,
        {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify(requestBody),
        }
    );
    return response.json();
}
// export async function fetchIsDepartmentBsb(client_id: any) {
//     const response = await counterPartyProxyRequest(`/bsb/client/${client_id}/isDepartmentBsb`,
//         {
//             method: "GET",
//         }
//     );
//     return response.json();
// }

export async function fetchBsbPosition(client_id: any, portfolio: string, selectedDate: string) {
    let url = portfolio ? `/bsb/client/${client_id}/position?portfolio=${portfolio}&date=${selectedDate}` : `/bsb/client/${client_id}/position?date=${selectedDate}`
    const response = await counterPartyProxyRequest(url,
        {
            method: "GET",
            headers: requestHeaders,
            // body: JSON.stringify(requestBody),
        }
    );
    return response.json();
}

export async function fetchBsbPositionNew(client_id: any, portfolio: string, selectedDate: string) {
    let url = portfolio ? `/bsb/client/${client_id}/newPosition?portfolio=${portfolio}&date=${selectedDate}` : `/bsb/client/${client_id}/newPosition?date=${selectedDate}`
    const response = await counterPartyProxyRequest(url,
        {
            method: "GET",
            headers: requestHeaders,
            // body: JSON.stringify(requestBody),
        }
    );
    return response.json();
}

export async function downloadClientPortfolioValuationReport(clientId: any, date: any, isProtected: boolean, portfolioId?: any) {
    let url = portfolioId ? `/bsb/client/${clientId}/client-portfolio-valuation/PDF?portfolioId=${portfolioId}&isProtected=${isProtected}&date=${date}` : `/bsb/client/${clientId}/client-portfolio-valuation/PDF?&date=${date}&isProtected=${isProtected}`

    counterPartyProxyRequest(url, {
        method: "POST",
        // @ts-ignore
        headers: requestHeaders, requestType: "blob" }).then((response)=>{
        if (response.status !== 200) return [];
        response.json()?.then(res=>{
            downloadPDFFile(res?.data, res.meta.file_name)
        });
    })
}


export async function fetchNewCouponMaturitySchedule(client_id: any, portfolio: string) {
    let url = portfolio ? `/bsb/client/${client_id}/coupon-maturity-schedule?portfolio=${portfolio}` : `/bsb/client/${client_id}/coupon-maturity-schedule`
    const response = await counterPartyProxyRequest(url,
        {
            method: "GET",
            headers: requestHeaders,
            // body: JSON.stringify(requestBody),
        }
    );
    return response.json();
}

export async function fetchNewCouponMaturityScheduleForNew(client_id: any, portfolio: string) {
    let url = portfolio ? `/bsb/client/${client_id}/new-position-coupon-maturity-schedule?portfolio=${portfolio}` : `/bsb/client/${client_id}/new-position-coupon-maturity-schedule`
    const response = await counterPartyProxyRequest(url,
        {
            method: "GET",
            headers: requestHeaders,
            // body: JSON.stringify(requestBody),
        }
    );
    return response.json();
}

export async function fetchPortfolioSummary(
    portfolioId: string,
    date: string,
) {
    const response = await proxyRequest(
        `/portfolio/${portfolioId}/summary?date=${date}`, {
            method: "GET"
        }
    )
    if (response) {
        return response.json();
    }

    return null;
}

export async function fetchAllPortfoliosForTrading(portfolioId: string, securityId: string, requestBody: any ) {
    const response =await proxyRequest(portfolioId ? `/position?portfolioId=${portfolioId}&securityId=${securityId}` : `/position?securityId=${securityId}`, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    })
    return response?.json();
}

export async function fetchAllPortfolios() {
    const response = await proxyRequest(`/portfolio`, {
        method: "GET"
    })
    return response?.json();
}

export async function fetchPortfolioTransactions(portfolioId: string, requestBody: TransactionSearchRequest, page: number, pageSize: number, sortBy?: string, sortType?: string) {
    let url = portfolioId ? `/transaction/search/?portfolioId=${portfolioId}&page=${page}&size=${pageSize}&sortBy=${sortBy || ''}&sortDir=${sortType || ''}` : `/transaction/search/?page=${page}&size=${pageSize}&sortBy=${sortBy || ''}&sortDir=${sortType || ''}`
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    })
    return response.json();
}

export async function fetchPortfolioOrders(portfolioId: string, requestBody: TransactionSearchRequest, page: number, pageSize: number) {
    let url = portfolioId ? `/order/search/?portfolioId=${portfolioId}&page=${page}&size=${pageSize}` : `/order/search/?page=${page}&size=${pageSize}`
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    })
    return response.json();
}

// export async function fetchClientTransactions(requestBody: TransactionSearchRequest, page: number, pageSize: number, sortBy?: string, sortType?: string) {
//   const response = await proxyRequest(`/transaction/search/?page=${page}&size=${pageSize}&sortBy=${sortBy || ''}&sortDir=${sortType || ''}`, {
//     method: "POST",
//     headers: requestHeaders,
//     body: JSON.stringify(requestBody),
//   })
//   return response.json();
// }

export async function fetchAllPortfolioSummaries(portfolioId: string, startDate: string | null, endDate: string) {
    const response = await proxyRequest(`/portfolio/${portfolioId}/summary/between?startDate=${startDate}&endDate=${endDate}`, {
        method: "GET"
    })
    if (response.status !== 200) return [];
    return response.json();
}

export async function fetchOrders(portfolioId: string, requestBody: object, page: number, pageSize: number) {
    const response = await proxyRequest(`/portfolio/${portfolioId}/transaction/search/?page=${page}&size=${pageSize}`, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    });
    return response.json();
}

export async function fetchAllSummary(date: string) {
    const response = await proxyRequest(`/portfolio/all/summary?date=${date}`, {
        method: "GET"
    })
    if (response.status !== 200) return [];
    return response.json();
}

export async function fetchPortfolioPerformanceMetrics(date: string, id?: string) {
    let url = id ? `/metrics/performance?date=${date}&portfolioId=${id}` : `/metrics/performance?date=${date}`;
    const response = await proxyRequest(url, {
        method: 'GET',
        mode: 'no-cors'
    }, );
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchPortfolioRiskMetrics(date: string, id?: string) {
    let url = id ? `/metrics/risk?date=${date}&portfolioId=${id}` : `/metrics/risk?date=${date}`;
    const response = await proxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchPortfolioBenchmarkMetrics(date: string, id?: string) {
    let url = id ? `/metrics/benchmark?date=${date}&portfolioId=${id}` : `/metrics/benchmark?date=${date}`;
    const response = await proxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchBondAndStockSector(date: string, positionExposureBy: string, id?: string) {
    let url = id ? `/position/exposure?date=${date}&portfolioId=${id}&positionExposureByList=${positionExposureBy}` : `/position/exposure?date=${date}&positionExposureByList=${positionExposureBy}`;
    const response = await proxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchAssetAllocationChart(date: string, positionExposureBy: string, id?: string) {
    let url = id ? `/position/exposure?date=${date}&portfolioId=${id}&${positionExposureBy}` : `/position/exposure?date=${date}&${positionExposureBy}`;
    const response = await proxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchAssetAllocationBarChart(positionExposureBy: string, requestBody: any, id?: string) {
    let url = id ? `/position/exposure?portfolioId=${id}&${positionExposureBy}` : `/position/exposure?${positionExposureBy}`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchSidePortfolioSummary(clientId: any, date: string, portfolioId?: string) {
    let url = portfolioId ? `/portfolioSummary?date=${date}&clientId=${clientId}&portfolioId=${portfolioId}` : `/portfolioSummary?date=${date}&clientId=${clientId}`;
    const response = await proxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}


export async function fetchSidePortfolioSummaryAll(clientId: any, date: string) {
    const response = await proxyRequest(`/portfolioSummary/all?date=${date}&clientId=${clientId}`, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}


export async function fetchSideIncomeProfitLoss(clientId: any, startDate: string, endDate: string, portfolioId?: string) {
    let url = portfolioId ? `/incomeProfitLoss/monthWise?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}&portfolioId=${portfolioId}` : `/incomeProfitLoss/monthWise?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}`;
    const response = await proxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}


export async function fetchPerformanceBenchmarkFilter(page: number, size: number, searchQuery?: string) {
    let url = searchQuery ? `/benchmark/search?page=${page}&size=${size}&searchQuery=${searchQuery}` : `/benchmark/search?page=${page}&size=${size}`;
    const response = await productProxyRequest(url, {
        method: 'POST',
    },);
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchTickers(date: string) {
    let url = `?date=${date}`;
    const response = await tickerProxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchPerformanceChart(body: any, startDate: string, endDate: string) {
    let url = startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : `?endDate=${endDate}`;
    const response = await performanceChartProxyRequest(url, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(body),
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchSecurities(page: number, size: number, requestBody: any) {
    let url = `/search?page=${page}&size=${size}`;
    const response = await productProxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    },);
    if (response.status !== 200) return {};
    return response.json();
}


export async function fetchTopLoser(date: string, records: number, requestBody: any, includeNonNegativeValue: boolean, includeAllChange?: boolean, sortBy?: string) {
    let url = includeAllChange ? `/price/topLoser?date=${date}&records=${records}&includeAllChange=${includeAllChange}&includeNonNegativeValue=${includeNonNegativeValue}&sortBy=${sortBy}&considerOnlyBusinessDay=true` : `/price/topLoser?date=${date}&records=${records}&considerOnlyBusinessDay=true`;
    const response = await productProxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    },);
    if (response.status !== 200) return {};
    return response.json();
}


export async function fetchTopGainer(date: string, records: number, requestBody: any, includeNonPositiveValue: boolean, includeAllChange?: boolean, sortBy?: string) {
    let url = includeAllChange ? `/price/topGainer?date=${date}&records=${records}&includeAllChange=${includeAllChange}&includeNonPositiveValue=${includeNonPositiveValue}&sortBy=${sortBy}&considerOnlyBusinessDay=true` : `/price/topGainer?date=${date}&records=${records}&considerOnlyBusinessDay=true`;
    const response = await productProxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    },);
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchMostTraded(date: string, records: number, requestBody: any, includeAllChange?: boolean, sortBy?: string) {
    let url = includeAllChange ? `/price/mostTraded?date=${date}&records=${records}&includeAllChange=${includeAllChange}&sortBy=${sortBy}&considerOnlyBusinessDay=true` : `/price/mostTraded?date=${date}&records=${records}&considerOnlyBusinessDay=true`;
    const response = await productProxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    },);
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchChangeByMaturity(date: string, requestBody: any) {
    let url = `/asset/bond/price/changeByMaturity?date=${date}`;
    const response = await productProxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    },);
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchCollectionRequest(page: number, size: number, clientId: string, requestBody: any, id?: string) {
    let url = id ? `/collectionRequest/search?page=${page}&clientId=${clientId}&portfolioId=${id}&size=${size}` : `/collectionRequest/search?page=${page}&clientId=${clientId}&size=${size}`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    },);
    if (response.status !== 200) return {};
    return response.json();
}

export async function createCollectionRequest(portfolioId: string, requestBody: any) {
    let url = `/portfolio/${portfolioId}/collectionRequest/create`
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    })
    if (response.status !== 200) return [];
    return response.json();
}

export async function checkCollectionRequest(client_id: string, collectionRequestId: string) {
    let url = `/client/${client_id}/collectionRequest/${collectionRequestId}`
    const response = await counterPartyProxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function checkStatus(client_id: string, collectionRequestId: string) {
    let url = `/client/${client_id}/collectionRequest/${collectionRequestId}/checkStatus`
    const response = await counterPartyProxyRequest(url, {
        method: 'GET',
    });
    if (response.status !== 200) return {};
    return {status: 200};
}

export async function downloadTransactionPdf(client_id: any, transactionId: string) {
    let url = `/report/client/${client_id}/transaction/${transactionId}/tradeConfirmation`
    counterPartyProxyRequest(url, {
        method: "POST",
        // @ts-ignore
        headers: requestHeaders, requestType: "blob" }).then((response)=>{
         if (response.status !== 200) return [];
         response.json()?.then(res=>{
             downloadPDFFile(res?.data, res.meta.file_name)
         });
     })
}

export async function fetchBankDetails() {
    const response = await proxyRequest(`/bankDetails`, {
        method: "GET"
    })
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchPositionWithdrawal(portfolioId: any) {
    const response = await proxyRequest(`/portfolio/${portfolioId}/position/withdrawal`, {
        method: "GET"
    })
    if (response.status !== 200) return {};
    return response.json();
}

export async function createWithdrawRequest(portfolioId: any, requestBody: any) {
    let url = `/portfolio/${portfolioId}/withdrawalRequest/create`
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    })
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchRiskChart(body: any, startDate: string, endDate: string) {
    let url = `?startDate=${startDate}&endDate=${endDate}`;
    const response = await riskChartProxyRequest(url, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(body),
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function downloadValuationReport(clientId: any, startDate: string, endDate: string, portfolioId?: any) {
    let url = portfolioId ? `/report/client/${clientId}/valuationReport?portfolioId=${portfolioId}&startDate=${startDate}&endDate=${endDate}` : `/report/client/${clientId}/valuationReport?startDate=${startDate}&endDate=${endDate}`

    counterPartyProxyRequest(url, {
        method: "POST",
        // @ts-ignore
        headers: requestHeaders, requestType: "blob" }).then((response)=>{
        if (response.status !== 200) return [];
        response.json()?.then(res=>{
            downloadPDFFile(res?.data, res.meta.file_name)
        });
    })
}


export async function fetchAvailableQuantity(securityId: any, portfolioId: number, counterPartyId?: any) {
    let url = counterPartyId ? `/product/${securityId}/position?portfolioId=${portfolioId}&counterPartyId=${counterPartyId}` : `/product/${securityId}/position?portfolioId=${portfolioId}`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
    },);
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchAvailableQuantityRepo( portfolioId: number, currency: string) {
    let url =  `/position?portfolioId=${portfolioId}`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify({
            "assetClassList": [
            "BOND",
            "BILL",
            "FD",
            "STRUCTURED_TRADE",
            "EQUITY",
            "CIS",
            "ETF"
          ],
            "currencyCodes": [
                currency
            ]
          }
          )
    },);
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchValidateQuantity(clientId: any, portfolioId: number, orderOfferId: any, considerationWithCurrency: any, securityId: any) {
    let url = securityId ? `/portfolio/${portfolioId}/balance/validate?orderOfferId=${orderOfferId}&considerationWithCurrency=${considerationWithCurrency}&securityId=${securityId}` : `/portfolio/${portfolioId}/balance/validate?orderOfferId=${orderOfferId}&considerationWithCurrency=${considerationWithCurrency}`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
    },);
    if (response.status === 400) return response.json();
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchPortfolioForDeposit() {
    let url = `/collectionRequest/allowed`;
    const response = await proxyRequest(url, {
        method: "GET"
    })
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchPortfolioForWithdrawal() {
    let url = `/withdrawalRequest/allowed`;
    const response = await proxyRequest(url, {
        method: "GET"
    })
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchCouponMaturitySchedule(date: string, considerAllCoupon = true, portfolioId?: any) {
    let url = portfolioId ? `/coupon-maturity-schedule?portfolioId=${portfolioId}&date=${date}&considerAllCoupon=${considerAllCoupon}` : `/coupon-maturity-schedule?date=${date}&considerAllCoupon=${considerAllCoupon}`;
    const response = await proxyRequest(url, {
        method: "GET"
    })
    if (response.status !== 200) return {};
    return response.json();
}


export async function validateBuyTrade(portfolioId: any, currencyCode: string, considerationWithCurrency: number) {
    let url = `/balance/validate?portfolioId=${portfolioId}&currencyCode=${currencyCode}&considerationWithCurrency=${considerationWithCurrency}`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
    });
    if (response.status !== 200){
        return "";
    }else {
        return response.json();
    }

}


export async function fetchBenchmarkForPerformanceChart(portfolioId: any,) {
    let url = portfolioId ? `/benchmark?portfolioId=${portfolioId}` : `/benchmark`;
    const response = await proxyRequest(url, {
        method: "GET"
    })
    if (response.status !== 200) return {};
    return response.json();
}