import React, {useEffect} from "react";
import MainLayout from "./mainLayout";
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import { ReactClientProvider } from "../components/clientselection/reactClientProvider";
import { LoadingComponent } from "../components/LoadingComponent";

const Layout = (props: any) => {
    const { login, isAuthenticated } = useOidc();
    const { accessToken } = useOidcAccessToken();

    const noClientComponent = (
        <h1>No client have been setup for your user layout. Please contact us to assign your existing portfolios to your
            user.</h1>
    );

    useEffect(() => {
        if (accessToken) {
            sessionStorage.setItem("jwtToken", accessToken);
        } else {
            sessionStorage.removeItem("jwtToken");
            login();
        }
    }, [accessToken]);

    if (isAuthenticated) {
        return <ReactClientProvider LoadingComponent={<LoadingComponent />} NoClientFoundComponent={noClientComponent}>
            <MainLayout {...props} />
        </ReactClientProvider>

    } else {
        return <LoadingComponent />
    }
};
export default Layout;