import {AssetClass} from "../types/service";
import download from "downloadjs";
import nextBase64 from 'next-base64';

export const assetClassFormatter = (assetClass: AssetClass): string => {
    switch (assetClass) {
        case AssetClass.BILL:
            return 'Bill';
        case AssetClass.BOND:
            return 'Bond';
        case AssetClass.CASH:
            return 'Cash';
        case AssetClass.CIS:
            return 'CIS';
        case AssetClass.EQUITY:
            return 'Equity';
        case AssetClass.ETF:
            return 'ETF';
        case AssetClass.STRUCTURED_TRADE:
            return 'Structured Trade';
        case AssetClass.REPO:
            return 'Repo';
        case AssetClass.FD:
            return 'FD';
        default:
            return assetClass;
    }
}

export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export function downloadPDFFile(data: any, file: string) {
    const contentType = 'application/pdf';
    const base64decoded = nextBase64.decode(data);
    download(base64decoded, decodeURIComponent(file), contentType);
}