import { ClientDTO } from "../../types/service";
import React, { useEffect, useState } from "react";
import { IClientContextProps } from "./clientContext";
import { fetchClients } from "../../services/clientService";
import { useOidcUser } from "@axa-fr/react-oidc";

export type ClientProviderProps = {
  LoadingComponent: JSX.Element;
  NoClientFoundComponent: JSX.Element;
}

export function createClientProvider(ClientContext: React.Context<IClientContextProps>) {

  return function ClientProvider(props: React.PropsWithChildren<ClientProviderProps>) {
    const [clients, setClients] = useState<ClientDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedClient, setSelectedClient] = useState<ClientDTO | undefined>(undefined);
    const [contextKey, setContextKey] = useState<number>(Math.random());
    const changeClient = setSelectedClient;
    const {oidcUser} = useOidcUser();
    useEffect(() => {
      init();
    }, [oidcUser?.preferred_username]);

    useEffect(() => {
      if (selectedClient) {
        sessionStorage.setItem("clientId", selectedClient.id);
      } else {
        sessionStorage.setItem("clientId", "");
      }
      setContextKey(Math.random);
    }, [selectedClient]);

    const init = () => {
      let accessToken = sessionStorage.getItem("jwtToken");
      if(accessToken){
        setIsLoading(true);
        fetchClients().then(res => {
          let currentSelectedClient = undefined;
          if (res != null && res.length > 0) {
            currentSelectedClient = res[0];
          }
          setClients(res);
          setSelectedClient(currentSelectedClient);
        }).catch(err => {
          setClients([]);
          setSelectedClient(undefined);
        }).finally(() => setIsLoading(false));
      }
    }

    const { children, LoadingComponent, NoClientFoundComponent } = props

    if (!!LoadingComponent && isLoading) {
      return LoadingComponent;
    }

    if (clients == null || clients.length === 0) {
      return NoClientFoundComponent;
    }

    return (
      <ClientContext.Provider key={contextKey} value={{
        loading: isLoading,
        clients: clients,
        selectedClient: selectedClient,
        setSelectedClient: setSelectedClient
      }}>
        {children}
      </ClientContext.Provider>
    )
  }
}

export default createClientProvider;
