import { useRouter } from "next/router";
import { useEffect } from "react";
import ReactGA from 'react-ga4';


/* Global Site Tag (gtag.js) - Google Analytics */
export function useGoogleAnalytics(GA_TRACKING_ID:  string | null | undefined) {
  const router = useRouter();

  const sendPageView = (url?: string) => {
    if (!url) {
      url = window.location.pathname;
    }
    ReactGA.send("pageview");
  };

  useEffect(() => {
    if (GA_TRACKING_ID) {
      ReactGA.initialize(GA_TRACKING_ID);
      sendPageView();
    }
  }, [GA_TRACKING_ID]);

  useEffect(() => {
    router.events.on("routeChangeComplete", sendPageView);
    return () => {
      router.events.off("routeChangeComplete", sendPageView);
    };
  }, [router.events]);

}
