import {getAPIURL} from "../utils";

  export const productProxyRequest = (input: RequestInfo ,init?: RequestInit, passToken = true): Promise<Response> => {
  let accessToken = sessionStorage.getItem("jwtToken");
  let headers = {}
  if (!!accessToken && passToken) {
    if (!!init) {
      headers = {...init.headers, Authorization: 'Bearer ' + accessToken};
    } else {
      headers = {Authorization: 'Bearer ' + accessToken};
    }
  } else {
    if (!!init) {
      headers = init.headers || {};
    } else {
      headers = {};
    }
  }
  let clientIdUrl = '/product';
  return fetch(getAPIURL() + '/api' + clientIdUrl + input, init ? {...init, headers} : {headers});

}

