import React, {createContext} from 'react'
import {ClientDTO} from "../../types/service";


export type IClientContextProps = {
  loading: boolean;
  clients: ClientDTO[];
  selectedClient?: ClientDTO;
  setSelectedClient?: (client: ClientDTO) => void;
}

export function createClientContext(
  initialContext?: Partial<IClientContextProps>
): React.Context<IClientContextProps> {
  return createContext({
    loading: false,
    clients: [],
    ...initialContext,
  })
}

export default createClientContext
