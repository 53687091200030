import "../styles/publicLayout.scss";
import * as React from 'react';
import { AppProps } from "next/app";
import { useState } from "react";
import MenuButtons from "../components/MenuButtons";
import SidebarIcon10 from "../assets/logo.svg";
import {useRouter} from "next/router";


const PublicLayout = ({ Component, pageProps }: AppProps) => {
    const router = useRouter();
    // @ts-ignore
    const [theme, setTheme] = useState<any>(2);
    const [refresh, setRefresh] = useState(1);

    const changeTheme = (themeCode: number) => {
        setTheme(themeCode);
    }

    const handleRefresh = () => {
        setRefresh(refresh + 1);
    }

    return (
        <div className={`app theme-${theme === 1 ? "light" : theme === 2 ? "black" : "dark"} ${theme ? "blue" : "golden"}`}>
            <div className="app-wrap">
                <div className="app-wrap__content">
                    <div className={`publicLayout ${['/markets']?.includes(router?.pathname) ? 'publicLayoutPadding' : ""}`}>
                        <div className="publicLayout-wrap">
                            {['/markets']?.includes(router?.pathname) && (
                                <div className="publicLayout-wrap__main">
                                    <div className="publicLayout-wrap__header">
                                        <div className="logo">
                                            <div>
                                                <SidebarIcon10 style={{ marginRight: "10px" }} />
                                                Gnii
                                            </div>
                                            <div className="publicLayout-wrap__header-text">
                                                <p>Markets</p>
                                            </div>
                                        </div>
                                        {/*<StocksList selectedDate={selectedDate}/>*/}
                                        <div className="publicLayout-wrap__header-buttons">
                                            <div className="refresh" onClick={handleRefresh}>
                                                <i className="bi bi-arrow-clockwise icon" />
                                                <span>Refresh</span>
                                            </div>
                                            <MenuButtons />
                                            <div className="portfolio-wrap__header-buttons__toggle">
                                            <span onClick={()=> changeTheme(0)} className={`default ${theme?.toString() === '0' && 'active'}`}>
                                               G
                                            </span>
                                             <span onClick={()=> changeTheme(2)} className={`moon ${theme?.toString() === '2' && 'active'}`}>
                                               ☾
                                            </span>
                                                <span onClick={()=> changeTheme(1)} className={`sun ${theme?.toString() === '1' && 'active'}`}>
                                                ☼
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Component {...pageProps} theme={theme}  refresh={refresh} />
                            {['/markets']?.includes(router?.pathname) && (
                                <div className="publicLayout-wrap__important">
                                    <p>Disclaimer:</p>
                                    <p>The contents of this website are believed to be reliable, but the accuracy or
                                        completeness of any information contained herein is not guaranteed and members of
                                        Black Star Group shall not in any way be liable for any delay in keeping such
                                        information current. Members of Black Star Group specifically disclaim at
                                        warranties, express or implied, except for those derived from its executed Customer
                                        Agreements. </p>
                                    <p>Although Black Star believes that the information provided on these pages is
                                        accurate, it provides no warranty with respect thereto. The information may not be
                                        suitable for all persons; it should not be relied upon in connection with a
                                        particular investor's trading; and, is not intended to be, nor should be construed
                                        as, an offer, recommendation, or solicitation. GNII does not provide investment
                                        advice, and only accepts Customers who have read and understand the relevant risk
                                        disclosure documents. The information on this website is not intended for persons
                                        who reside in jurisdictions where providing such information would violate the laws
                                        or regulations of such jurisdiction. </p>
                                    {/*<p>© {new Date().getFullYear()}, Gnii, All Rights Reserved. </p>*/}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicLayout;