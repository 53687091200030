import {proxyRequest} from "./proxyRequests/proxyRequest";
import {requestHeaders} from "./utils";

export async function fetchClients() {
  const response = await proxyRequest(`/clients`, {
    method: "GET",
    headers: requestHeaders,
  }, true, false);
  return response.json();
}
