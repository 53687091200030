import {OidcProvider} from "@axa-fr/react-oidc";
import "bootstrap-icons/font/bootstrap-icons.css";
import {KeycloakConfig} from "keycloak-js";
import type {AppProps} from "next/app";
import Head from "next/head";
import {useRouter} from "next/router";
import React, {useEffect, useState} from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {LoadingComponent} from "../components/LoadingComponent";
import Layout from "../layout/layout";
import {useGoogleAnalytics} from "../lib/useGoogleAnalytics";
import {useHotjarAnalytics} from "../lib/useHotjar";
import {zendDeskChat} from "../lib/zendDeskChat";
import {proxyRequest} from "../services/proxyRequests/proxyRequest";
import "../styles/components/_spinners.scss";
import "../styles/index.scss";
import "../styles/publicLayout.scss";
import PublicLayout from "../layout/publicLayout";

export const useAppConfig = () => {
    const [keycloakConfig, setKeycloakConfig] = useState<KeycloakConfig | null>(null);
    const [googleAnalyticsId, setGoogleAnalyticsId] = useState<string | null>(null);
    const [hotjarId, setHotJarId] = useState<string | null>(null);
    const [zendeskId, setZendeskId] = useState<string | null>(null);
    const [version, setVersion] = useState<string | null>(null);
    useEffect(() => {
        proxyRequest(`/app/config`, {
            method: "GET"
        }, false, false)
            .then((response) => {
                return response.json();
            })
            .then((config) => {
                setGoogleAnalyticsId(config.googleAnalyticsId);
                setHotJarId(config.hotjarId);
                setZendeskId(config.zendeskId);
                setVersion(config.version);
                setKeycloakConfig(config.keycloak);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return {keycloakConfig, googleAnalyticsId, hotjarId, zendeskId, version};
};


function MyApp(props: AppProps) {
    const {keycloakConfig, googleAnalyticsId, hotjarId, zendeskId} = useAppConfig();
    const [oidcConfig, setOidcConfig] = useState<any>();

    useGoogleAnalytics(googleAnalyticsId);
    useHotjarAnalytics(hotjarId);
    const sessionLostComponent = () => {
        window.location.reload();
        return <LoadingComponent/>
    }
    useEffect(() => {
        if (keycloakConfig) {
            setOidcConfig({
                client_id: keycloakConfig?.clientId,
                // client_id: 'gnii-wealth-app',
                redirect_uri: window?.location?.origin + "/#authentication-callback",
                silent_redirect_uri: window?.location?.origin + "/#authentication-silent-callback",
                scope: "openid profile email",
                authority: keycloakConfig.url + '/realms/' + keycloakConfig?.realm,
                // authority: 'http://localhost:8080' + '/realms/' + 'gnii',
                monitor_session: true
            });
        } else {
            setOidcConfig(undefined);
        }
    }, [keycloakConfig])
    const router = useRouter();
    const withCustomHistory = () => {
        return {
            replaceState: (url: any) => {
                router
                    .replace({
                        pathname: url,
                    })
                    .then(() => {
                        window.dispatchEvent(new Event("popstate"));
                    });
            },
        };
    };

    return (
        <>
            <Head>
                <link rel="shortcut icon" href="/logo.png"/>
                <title>Gnii</title>
            </Head>
            {['/markets','/markets-login', '/get-market-access']?.includes(router?.pathname) ?
                (
                    <PublicLayout {...props} />
                    // <h1>No client have been setup for your user layout. Please contact us to assign your existing portfolios to your
                    //     user.</h1>
                ) : (
                    <>
                        {oidcConfig ? (
                            <OidcProvider configuration={oidcConfig} authenticatingComponent={LoadingComponent}
                                          callbackSuccessComponent={LoadingComponent}
                                          loadingComponent={LoadingComponent} withCustomHistory={withCustomHistory}
                                          sessionLostComponent={sessionLostComponent}>
                                <Layout {...props} />
                            </OidcProvider>
                        ) : <LoadingComponent/>}
                    </>

                )
            }
            {zendDeskChat(zendeskId)}
        </>
    );
}

export default MyApp;