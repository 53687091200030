import {getAPIURL} from "../utils";

  export const proxyRequest = (input: RequestInfo ,init?: RequestInit, passToken = true, addClientId = true): Promise<Response> => {
  let accessToken = sessionStorage.getItem("jwtToken");
  let headers = {}
  if (!!accessToken && passToken) {
    if (!!init) {
      headers = {...init.headers, Authorization: 'Bearer ' + accessToken};
    } else {
      headers = {Authorization: 'Bearer ' + accessToken};
    }
  } else {
    if (!!init) {
      headers = init.headers || {};
    } else {
      headers = {};
    }
  }
  let clientIdUrl = '';
  if (addClientId) {
    clientIdUrl = sessionStorage.getItem("clientId") || ''
  }
  if (clientIdUrl != '') {
    clientIdUrl = '/client/' + clientIdUrl;
  }
  return fetch(getAPIURL() + '/api' + clientIdUrl + input, init ? {...init, headers} : {headers});
}

