import {AppProps} from "next/app";
import React, {useEffect, useRef, useState} from "react";
import {useRouter} from 'next/router';
import Logo from "../assets/logo.svg";
import MenuIcon from "../assets/minified-svg/burger.svg";
import MenuButtons from "../components/MenuButtons";
import Sidebar from "../components/Sidebar";
import {PortfolioDTO} from "../types/service";
import {fetchAllPortfolios, fetchSidePortfolioSummary} from "../services/portfolioService";
import {useClient} from "../components/clientselection/useClient";
import SelectComponent from "../components/SelectComponent";
import moment from "moment";
import {numberWithCommasAndDecimal, useOutsideClick} from "../utils/helper";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useOidc, useOidcUser} from "@axa-fr/react-oidc";

const MainLayout = ({Component, pageProps}: AppProps) => {
    const router = useRouter();

    const wrapperRef = useRef(null);
    const [loading2, setLoading2] = useState(true);
    const [portfolio, setPortfolio] = React.useState<PortfolioDTO | undefined>(undefined);
    const [selectedDate, setSelectedDate] = React.useState<any>(moment().format("YYYY-MM-DD"));
    // @ts-ignore
    const [theme, setTheme] = useState<any>(sessionStorage?.getItem("theme")?.toString() ? parseInt(sessionStorage?.getItem("theme")) : 2);
    const [activeSidebar, setActiveSidebar] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [availablePortfolios, setAvailablePortfolios] = React.useState<PortfolioDTO[]>([]);
    const [selectedPortfolio, setSelectedPortfolio] = React.useState<PortfolioDTO | undefined>(undefined);
    const [summaryData, setData] = React.useState<any>([]);
    const [dropdownPortfolioList, setDropdownPortfolioList] = React.useState<any>([]);
    const [currencySymbol, setCurrencySymbol] = React.useState<any>("");

    const {selectedClient} = useClient();

    const {logout} = useOidc();
    const {oidcUser} = useOidcUser();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActiveSidebar = () => {
        setActiveSidebar(!activeSidebar);
    }

    const changeTheme = (themeCode: number) => {
        setTheme(themeCode);
        sessionStorage.setItem("theme", String(themeCode));
    }

    useEffect(() => {
        fetchAllPortfolios().then((res: PortfolioDTO[]) => {
            setAvailablePortfolios(res);
            if (selectedPortfolio) {
                setSelectedPortfolio(res.find((p: PortfolioDTO) => p.id === selectedPortfolio?.id));
            }
        })
    }, [selectedClient]);

    const handlePortfolioSelect = (pid?: string) => {
        if (pid && availablePortfolios) {
            setSelectedPortfolio(availablePortfolios?.find(p => p.id === pid));
        } else {
            setSelectedPortfolio(undefined);
        }
    }

    useEffect(() => {
        setPortfolio(selectedPortfolio);
    }, [selectedPortfolio]);

    useEffect(() => {
        const portfolioOverViewkey = {key: 'Portfolio Overview', value: 'Portfolio Overview'};
        const list = availablePortfolios?.length === 1 ? [] : [portfolioOverViewkey];
        availablePortfolios?.length > 0 && availablePortfolios?.forEach(e => {
            list.push({key: e.id, value: e.name});
        });
        setDropdownPortfolioList(list);
    }, [availablePortfolios])

    useOutsideClick(wrapperRef, () => {
        if (showUserMenu) setShowUserMenu(!showUserMenu);
    });

    const handleRefresh = () => {
        setRefresh(refresh + 1);
    }

    useEffect(() => {
        if (portfolio) {
            setCurrencySymbol(portfolio?.currencySymbol);
        } else if (selectedClient) {
            setCurrencySymbol(selectedClient?.currencySymbol);
        }
    }, [selectedClient, portfolio])

    useEffect(() => {
        let clientId: any = sessionStorage.getItem("clientId");
        if (portfolio && portfolio?.id) {
            handleAPICalls(selectedDate, clientId, portfolio?.id);
        } else if (clientId) {
            handleAPICalls(selectedDate, clientId, '');
        }
    }, [selectedDate, portfolio?.id, refresh]);

    const handleAPICalls = (date: string, clientId: string, id?: string) => {
        fetchSidePortfolioSummary(clientId, date ? moment(date).format("DD-MM-yyyy") : moment().format("DD-MM-yyyy"), id)
            .then(res => {
                setData(res);
            }).catch((e) => {
            console.log(e);
        });
    }

    const getPageName = () => {
        if (router.pathname === "/performance") {
            return "Performance"
        } else if (router.pathname === "/portfolio") {
            return "Portfolio"
        } else if (router.pathname === "/positionTransaction") {
            return "Positions"
        } else if (router.pathname === "/market") {
            return "Market"
        } else if (router.pathname === "/trading") {
            return "Trading"
        } else if (router.pathname === "/pricingHistory") {
            return "Pricing History"
        } else if (router.pathname === "/wallet") {
            return "Wallet"
        } else if (router.pathname === "/") {
            return "Dashboard"
        } else if (router.pathname === "/markets") {
            return "Markets"
        }
    }

    // @ts-ignore

    return (
        <div
            className={`app theme-${theme.toString() === '1' ? "light" : theme.toString() === '2' ? "black" : "dark"} ${theme.toString() === '1' ? "blue" : "golden"}`}>
            <div className="app-wrap">
                <Sidebar summaryData={summaryData} setPortfolio={setPortfolio} refresh={refresh} portfolio={portfolio}
                         selectedDate={selectedDate}
                         setSelectedDate={setSelectedDate} activeSidebar={activeSidebar}
                         handleActiveSidebar={handleActiveSidebar} theme={theme} selectedPortfolio={selectedPortfolio}/>
                <div className="app-wrap__content">
                    <div className="portfolio">
                        <div className="portfolio-wrap">
                            <div className="portfolio-wrap__main">
                                <div className="portfolio-wrap__header">
                                    <div className="portfolio-wrap__header-text">
                                        <p>{getPageName()}</p>
                                        <span>{selectedClient?.name}</span>
                                    </div>
                                    <div className="portfolio-wrap__header-hidden">
                                        <MenuIcon className="menuIcon" onClick={handleActiveSidebar}/>
                                        <div className="logo">
                                            <Logo/>
                                            Gnii
                                        </div>
                                    </div>
                                    {/*<StocksList selectedDate={selectedDate}/>*/}
                                    <div className="portfolio-wrap__info">
                                        <div className="info">
                                            <div
                                                className="info__value">{summaryData?.nav ?
                                                <>
                                                    {currencySymbol} {numberWithCommasAndDecimal(summaryData?.nav)}
                                                </>
                                                : "━━"
                                            }
                                            </div>
                                            <div
                                                className="info__label">{"Net Asset Value"}</div>
                                        </div>
                                        <div className="info">
                                            <div
                                                className={`info__value ${parseFloat(summaryData?.navChange) > 0 ? "increase" : parseFloat(summaryData?.navChange) < 0 ? "decrease" : ""}`}>
                                                {summaryData?.navChange ?
                                                    <>
                                                        {currencySymbol} {parseFloat(summaryData?.navChange) > 0 ? "+" : ""}{numberWithCommasAndDecimal(summaryData?.navChange)} ({parseFloat(summaryData?.navChangePercentage) > 0 ? "+" : ""}{numberWithCommasAndDecimal(summaryData?.navChangePercentage)}%)
                                                    </>
                                                    : "━━"
                                                }
                                            </div>
                                            <div className="info__label">Day Change</div>
                                        </div>
                                        <div className="info">
                                            <div
                                                className="info__value">
                                                {summaryData?.totalUnrealisedIncome ?
                                                    <>
                                                        {currencySymbol} {parseFloat(summaryData?.totalUnrealisedIncome) > 0 ? "+" : ""}
                                                        {numberWithCommasAndDecimal(summaryData?.totalUnrealisedIncome)}
                                                    </>
                                                    : "━━"
                                                }
                                            </div>
                                            <div className="info__label">Total Income</div>
                                        </div>
                                    </div>
                                    <div className="portfolio-wrap__header-buttons">
                                        <div className="refresh" onClick={handleRefresh}>
                                            <i className="bi bi-arrow-clockwise icon"/>
                                            <span>Refresh</span>
                                        </div>
                                        <MenuButtons/>
                                        <div className="portfolio-wrap__header-buttons__menu">
                                            <div style={{display: "flex"}} onClick={handleClick}>
                                                <Button
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                >
                                                    {oidcUser?.preferred_username}
                                                </Button>
                                                <i className="bi bi-chevron-compact-down down"/>
                                            </div>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem onClick={() => {
                                                    sessionStorage.removeItem("theme");
                                                    logout(window.location.origin);
                                                    handleClose()
                                                }}>Logout</MenuItem>
                                            </Menu>
                                        </div>
                                        <div className="portfolio-wrap__header-buttons__toggle">
                                            <span onClick={() => changeTheme(0)}
                                                  className={`default ${theme?.toString() === '0' && 'active'}`}>
                                               G
                                            </span>
                                            <span onClick={() => changeTheme(2)}
                                                  className={`moon ${theme?.toString() === '2' && 'active'}`}>
                                                ☾
                                            </span>
                                            <span onClick={() => changeTheme(1)}
                                                  className={`sun ${theme?.toString() === '1' && 'active'}`}>
                                                ☼
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-wrap__dropdown">
                                    <div className="sidebar-wrap__sidebar-main">
                                        <div className="sidebar-wrap__sidebar-label">Portfolio</div>
                                        <SelectComponent emptyText='No portfolio found' onChange={handlePortfolioSelect}
                                                         options={dropdownPortfolioList} allowClear={false}
                                                         defaultSelected={selectedPortfolio?.id || dropdownPortfolioList[0]?.key}/>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-wrap__componentwrapper">
                                <Component {...pageProps} changeTheme={changeTheme}
                                           summaryData={summaryData}
                                           handleActiveSidebar={handleActiveSidebar}
                                           theme={theme} portfolio={portfolio}
                                           availablePortfolios={availablePortfolios}
                                           refresh={refresh} setPortfolio={setPortfolio}
                                           selectedDate={selectedDate}
                                           setSelectedDate={setSelectedDate}/>
                            </div>
                            <div className="portfolio-wrap__important">
                                <p>Disclaimer:</p>
                                <p>The contents of this website are believed to be reliable, but the accuracy or
                                    completeness of any information contained herein is not guaranteed and members of
                                    Black Star Group shall not in any way be liable for any delay in keeping such
                                    information current. Members of Black Star Group specifically disclaim at
                                    warranties, express or implied, except for those derived from its executed Customer
                                    Agreements. </p>
                                <p>Although Black Star believes that the information provided on these pages is
                                    accurate, it provides no warranty with respect thereto. The information may not be
                                    suitable for all persons; it should not be relied upon in connection with a
                                    particular investor's trading; and, is not intended to be, nor should be construed
                                    as, an offer, recommendation, or solicitation. GNII does not provide investment
                                    advice, and only accepts Customers who have read and understand the relevant risk
                                    disclosure documents. The information on this website is not intended for persons
                                    who reside in jurisdictions where providing such information would violate the laws
                                    or regulations of such jurisdiction. </p>
                                {/*<p>© {new Date().getFullYear()}, Gnii, All Rights Reserved. </p>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default MainLayout;